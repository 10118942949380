@import "variables";

@mixin mat-radio-custom-theme() {
  .mat-mdc-radio-button {

    .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
      border-color: $color-theme-primary !important;
    }

    .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
      border-color: $color-theme-primary !important;
    }

    .mat-radio-ripple {
      display: none!important;
    }

    &.mat-accent {
      --mat-radio-checked-ripple-color: unset !important;
    }


    &.mat-accent {
      .mat-radio-outer-circle {
        border-width: 3px;
      }
      &.mat-radio-checked {
        .mat-radio-outer-circle {
          border-color: #000000;
        }
        .mat-radio-inner-circle {
          background-color: #876486;
        }
      }
    }

    .mdc-radio__native-control:checked+.mdc-radio__background .mdc-radio__inner-circle {
      transform: scale(0.6) !important;
    }

    .mdc-form-field--align-end>label {
      margin-left: 0;
      margin-right: auto;
    }

    .mat-radio-button .mat-radio-ripple {
      display: none !important;
    } 
  }

  .mat-mdc-radio-group.selected{
    .mat-mdc-radio-button {
      pointer-events: none;
      
      .mdc-radio .mdc-radio__native-control:enabled:not(:checked) {
        + .mdc-radio__background {
            background: #F5F5F5;
            border-radius: 100%;

          .mdc-radio__outer-circle {
            border-color: #D6D3D7 !important;
          }
        }
      }
     
    }
  }
}

.mat-mdc-radio-group.stretch {
  width: 100%;
  justify-content: space-between;
  
  .mdc-form-field,
  .mdc-label{
    width: 100%;
  }
}

// Custom Style
.radio-chip {
  display: flex;

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    z-index: 10;
    position: relative;
    padding: 6px 16px;
    border-radius: 99px;
    border: 1px solid #BEBEBE;
    cursor: pointer;
    color: #949494;
    font-size: 12px;
    font-weight: 500;
  }

  input[type="radio"]:checked + label {
    color: #91466A;
    border-width: 2px;
    border-color: #91466A;
  }
}

:host ::ng-deep .mat-radio-outer-circle{
  border-radius:2px;
}
:host ::ng-deep .mat-radio-inner-circle{
  border-radius:2px;
  background-color: transparent!important;
  border-bottom: 4px solid white;
  border-right:4px solid white;
  height:30px;
  width:15px;
  margin-top: -8px;
  margin-left: 3px;
}
:host ::ng-deep .mat-radio-checked .mat-radio-outer-circle{
  background:#ff4081!important;
}
:host ::ng-deep .mat-radio-checked .mat-radio-inner-circle{
  transform: rotate(45deg) scale(0.5);
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  background-color: unset!important;
}

.radio-input{

  .mat-mdc-radio-button {
    padding: 8px 0;
  }

  .mat-mdc-radio-button .mdc-form-field {
    display: flex;
    align-items: flex-start;
  }

  .mat-mdc-radio-button .mdc-radio {
    padding-left: 0!important;
    padding-top: 0!important;
    padding-bottom: 0!important;
  }
}

.radio-align-start {
  .mdc-form-field {
    align-items: start!important;

    .mdc-radio {
      top: -3px!important;
    }
  }
}

