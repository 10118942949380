.roam-toastr{
  margin-top: 1rem!important;
  width: 80% !important;
  max-width: 640px;
  border-radius: 0.5rem!important;
  padding-left: 1.5rem!important;
  box-shadow: unset!important;
  background-size: 0!important;
  font-size: 14px;

  .toast-close-button {
    top: 0;
    left: 0;
  }

  &:hover {
    box-shadow: unset!important;
  }

  &.toast-success {
    background: #2CB216;
  }

  &.toast-remove {
    background-color: #949494;
  }
}
