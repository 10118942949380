@mixin mat-calendar-custom-theme() {
  mat-datepicker-content.mat-datepicker-content {
    border-radius: 22px;
  }

  .mat-calendar-body-selected {
    background: #91466A!important;
    color: #ffffff;
    border: 1px solid #91466A;
    box-shadow: none !important;
  }

  .mat-calendar-body-cell-content {
    padding-top: 3px!important;
  }

  .mat-calendar-body-label {
    visibility: hidden;
  }
  
  .cdk-focused {
    background: transparent;
  }

  .cdk-program-focused {
    background: transparent;
  }

  .new-reservation-sidenav-calendar {

    .mat-calendar-body-cell-container {
      border: 1px solid #CCCCCC;
      padding: 0 !important;
      height: 32px;
    }

    .mat-calendar-body-selected {
      background: black;
      color: #fff;
      border: none;
    }

    .mat-calendar-body-in-range::before {
      background: rgba(145, 70, 106, 0.2);
    }

    .mat-calendar-body-cell-content {
      width: 68%;
      left: 18%;
    }
  }

  .roamstay-calendar {

    .mat-calendar-body-label {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 32px;
      padding-left: 3.7% !important;
    }

    .mat-calendar-body-cell-content {
      width: 56%;
      left: 25%;
    }

    .mat-calendar-body-cell {
      margin-top: 1px;
      width: 67%;
      margin-left: 1px;
      height: 75%
    }

    .mat-calendar-body-cell-container {
      border: 1px solid #CCCCCC;
      padding: 0 !important;
      height: 32px;
    }

    .mat-calendar-body-selected {
      background: black;
      color: #fff;
      border: none;
    }

    .mat-calendar-body-in-range::before {
      background: rgba(145, 70, 106, 0.2);
    }

    .busy-date {
      background: rgba(145, 70, 106, 0.2);
      border: 1px solid #874B69;

      div {
        opacity: 0.38;
      }
    }
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: transparent!important;
  }

  .mat-calendar-controls {
    margin: 0!important;
  }

  .mat-datepicker-content .mat-calendar {
    height: initial!important;
  }
}
