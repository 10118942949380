.all-filters-drawer {
  height: 100%;
  width: 470px;
  display: flex;
  flex-direction: column;

  .header,
  .content,
  .footer {
    padding: 24px;
  }

  .header {
    flex-shrink: 0;
    height: 72px;
    width: 100%;
    display: flex;
    background-color: #f7f7f7;
    align-items: center;
    position: relative;
    font-size: 24px;
    font-weight: 500;

    .close-btn {
      font-size: 30px;
      color: #949494;
    }
  }

  .content {
    flex-grow: 1;
    min-height: 0;
    overflow: auto;

    .form-item {
      + .form-item {
        border-top: 1px solid #d6d3d7;
        margin-top: 12px;
        padding-top: 24px;
      }

      align-items: center;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .footer {
    flex-shrink: 0;
    height: 77px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e8e4e9;

    > * {
      width: 100%;
    }
  }
}
