@mixin mat-menu-custom-theme() {
  .mat-mdc-menu-panel {
    padding: 19px 12px 11px 10px !important;
    box-shadow: 0 3px 6px #00000029 !important;
    border-radius: 6px;
    top: 20px;

    .clear-btn {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 32px;

      &.mat-mdc-menu-item {
        padding: 0;
      }

      button {
        text-decoration: underline;
      }

      .mat-mdc-button {
        font-size: 12px;
        line-height: 21px;
        font-weight: 300;
      }
    }

    .apply-btn {
      padding-top: 31px;

      .mat-mdc-button {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        width: 100%;
        background-color: #94949480;
        border-radius: 21px;
        padding: 6px;
        color: #ffffff;
      }
    }

    .task-details-apply-btn {
      padding-top: 31px;

      .mat-mdc-button {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        width: 100%;
        background: #94949480;
        border-radius: 21px;
        padding: 6px;
        color: #ffffff;
      }
    }

    .task-details-teams-btn.active {
      .mat-mdc-button {
        background-color: #94949480;
      }
    }

    .task-details-attach-btn {
      .mat-mdc-button {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        width: 100%;
        background: #8f86934d;
        border-radius: 9px;
        padding: 7px 10px 6px 11px;
        color: #172b4d;
      }
    }

    .task-details-checklist-add-btn {
      width: 100%;
      text-align: center;
      margin-top: 13px;

      .mat-mdc-button {
        background-color: #91466a;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        width: 116px;
        border-radius: 19px;
        padding: 10px;
        color: #fff;
      }
    }

    .task-details-checklist-btn {
      width: 100%;
      margin-top: 13px;
      gap: 4px;

      .rate-sign {
        margin-left: auto;
        color: #94949480;
      }

      .mat-mdc-button.add {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        width: 80px;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
          ),
          rgba(148, 148, 148, 0.5);
        border-radius: 39px;
        padding: 10px;
        color: #fff;
      }

      .mat-mdc-button.cancel {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        width: 56px;
        background: transparent;
        border-radius: 9px;
        padding: 7px 10px 6px 11px;
        color: #94949480;
      }
    }

    .task-details-teams-btn {
      width: 100%;

      .mat-mdc-button {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        width: 100%;
        background: #8f86934d;
        border-radius: 9px;
        padding: 7px 10px 6px 11px;
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-wrapper {
          justify-content: space-between;
        }
      }
    }

    .menu-item-counter {
      display: flex;
      align-items: center;

      .menu-item-counter-title {
        width: 170px;
        font-size: 12px;
        line-height: 21px;
        font-weight: 300;
      }

      .menu-item-counter-actions {
        display: flex;
        align-items: center;
        gap: 9px;

        button {
          border: 0.25px solid #c4c4c4;
          border-radius: 50%;
          width: 21px;
          height: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
          padding: 0;

          mat-icon {
            margin-right: 0;
            height: 19px;
            font-size: 19px;
          }

          &:disabled {
            mat-icon {
              color: #c4c4c4;
            }
          }
        }
      }
    }

    .mat-mdc-menu-item {
      line-height: 30px;
      height: auto;
      padding: 0 28px 0 6px;

      &:hover {
        background: transparent;
      }

      .mat-icon-no-color {
        color: inherit;
        font-size: 18px;
        width: auto;
        height: auto;
      }

      .mat-icon {
        margin-right: 8px;
      }
    }

    &.radius-22 {
      border-radius: 22px;
    }

    &.calendar-menu {
      max-width: none !important;
    }

    &.add-menu {
      top: -5px;
    }

    &.ask-menu {
      top: 0;
      padding: 0 !important;
      min-width: fit-content;
      min-height: 10px;
      border-radius: 8px;
      .mat-mdc-menu-content {
        padding: 0;
        .mat-mdc-menu-item {
          padding: 0 5px;
        }
      }
    }

    &.tell-menu {
      top: 0;
      min-width: 240px;
      border-radius: 8px;
      .mat-mdc-menu-content {
        padding: 0;
        .mat-mdc-menu-item {
          padding: 10px 5px;
        }
      }
    }

    &.tell-sub-menu {
      top: 0;
      min-width: 175px;
      padding: 10px !important;
      border-radius: 8px;
      .mat-mdc-menu-content {
        padding: 0;
        .mat-mdc-menu-item {
          padding: 5px 2px;
        }
      }
    }

    &.replace-menu {
      top: 0;
      min-width: 47.5vw;
      padding: 0 !important;
      border-radius: 8px;
      .mat-mdc-menu-content {
        padding: 0;
        .p-16px {
          padding: 16px;
        }
        footer {
          padding: 10px 16px;
          background-color: #f5f5f5;
          .copy-btn {
            margin-right: 10px;
            width: auto;
            color: #000000;
            background-color: #ffffff;
            border: 1px solid black;
            border-radius: 8px;
          }
          .replace-btn {
            width: auto;
            color: #ffffff;
            background-color: #91466a;
            border-radius: 8px;
          }
        }
      }
    }

    &.more-menu {
      width: 320px;
      padding: 12px !important;
      .mat-mdc-menu-item {
        padding: 5px 0;
      }
    }

    &.menu__documents {
      padding: 10px !important;
      box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
      border: 1px solid #d6d3d7;
      border-radius: 8px;
      .mat-mdc-menu-item {
        padding: 10px 10px;
      }
    }
  }

  .mat-menu-trigger {
    &.mat-select-menu {
      border: 1px solid #cccccc;
      border-radius: 20px;
      width: 201px;
      height: 39px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 300;

      &.cdk-focused {
        background: transparent;
      }

      .menu-opened {
        transform: rotate(180deg);
      }

      .mat-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .task-list-menu {
    .mat-mdc-menu-content {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }

    .task-list-menu-title {
      color: #876486;
    }

    .task-list-menu-button {
      color: #876486;
      background: #f7f7f7 !important;
      border-radius: 9px;
    }
  }

  .task-details-action-menu {
    &.mat-mdc-menu-panel .mat-mdc-menu-item {
      padding: 0 28px 0 11px;
    }

    .mat-mdc-menu-content {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }

    .task-list-menu-title {
      color: #000;
    }

    .task-list-menu-button {
      color: #000;
      background: #cac0ce4d !important;
      border-radius: 9px;
    }
  }

  .filter-row {
    .mat-menu-trigger.mat-select-menu.filter-more {
      width: 37px;
      min-width: unset !important;
      padding: 0 16px;
    }

    .mat-menu-trigger.mat-select-menu {
      width: auto;
      height: 34px;
      padding: 6px 20px 6px 9px;
      background: rgba(202, 192, 206, 0.3);
      box-shadow: inset 0.5px 0.5px 1px 0 rgb(23 73 77 / 8%);
      border: none;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      .mat-mdc-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        color: #333333;
      }
    }
  }

  .filter-menu-header {
    border-radius: 9px !important;

    .filter-list {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .label-title {
        display: block;
        margin: 14px 0 12px 0;
      }

      .attachment-title {
        display: block;
        margin: 0;
      }

      .label {
        .label-name {
          width: 130px;
          height: 34px;
          border-radius: 9px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .design {
          background-color: #3c5f0b;
        }

        .cleaning {
          background-color: #08c7e0;
        }

        .maketing {
          background-color: #ffd27f;
        }

        .managing {
          background-color: #ff9286;
        }
      }

      .label-checkbox {
        width: 19px;
        height: 19px;
      }
    }

    .mat-mdc-menu-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    .language-menu,
    .custom-backdrop {
      + .cdk-overlay-connected-position-bounding-box {
        .cdk-overlay-pane {
          width: auto;
        }
      }
    }

    .mat-mdc-menu-panel {
      &.replace-menu {
        footer {
          padding: 10px 16px;
          background-color: #f5f5f5;
          .flex-basis-80 {
            flex-basis: 80%;
          }
          .copy-btn {
            margin-bottom: 10px;
            margin-right: 0 !important;
            width: 100% !important;
          }
          .replace-btn {
            width: 100% !important;
          }
        }
      }
    }
  }
}
