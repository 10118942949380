@mixin mat-button-custom-theme() {
  .mat-mdc-button {

    position: relative;
    height: auto!important;
    line-height: 20px;
    padding: 10px 1rem !important;

    .mdc-button__label {
      display: flex;
      align-items: center;
    }

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .mat-icon {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-base .mat-icon {
      width: auto !important;
      height: auto !important;
    }

    &.roam-button {
      width: auto;
      height: 39px;
      border: 1px solid #cccccc;
      border-radius: 22px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 300;
      margin-right: 17px;

      &.active {
        border: 1px solid #874b69;
        background: #91466a26;
      }

      .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 28px;
      }
    }

    &.view-more {
      width: 151px;
      height: 39px;
      background: #91466a;
      color: #ffffff;
      border-radius: 22px;
    }

    &.cancel {
      width: 206px;
      height: 39px;
      background: #efefef;
      color: #000000;
      border-radius: 22px;
    }

    &.add {
      background: #949494;
      color: #ffffff;
      border-radius: 39px;
    }

    &.grey-text {
      color: #949494;
    }

    &.save {
      width: 206px;
      height: 39px;
      background: #91466a;
      color: #ffffff;
      border-radius: 22px;
    }

    &.add-user-button {
      .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
      }
    }

    &.add-button {
      width: 150px;
      height: 38px;
      border: 0.5px solid #000000;
      font-weight: 500;
      border-radius: 22px;

      &.blue-border {
        border: 1px solid #4a67b4;
      }

      .mat-button-wrapper {
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    &.add-task-btn {
      .mat-button-wrapper {
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        gap: 9px;
      }
    }

    &.add-item-btn {
      border: 0.5px solid;
      border-radius: 8px;
      min-width: 64px;
      padding: 0 16px;
    }

    &.continue-btn {
      background: #91466a;
      color: #ffffff !important;
    }

    &.back-btn {
      background: #cccccc;
      color: #ffffff !important;
      width: 140px;
    }

    &.blue-btn {
      background-color: #4166ba;
      color: #fff;
      border-color: #4166ba;
    }

    &.home-btn {
      background: #91466A;
      border: 1px solid #91466a;
      color: #ffffff !important;
      border-radius: 8px;
      padding: 4px;
      height: 46px;
      width: 100%;
    }
  }

  .mat-mdc-button-base {
    &.border-btn {
      border: 1px solid;
      border-radius: 8px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 300;
      padding: 7px 20px;
      background: inherit;
      width: auto;
    }

    &.create-new {
      background: #91466a;
      color: #ffffff;
      width: auto;
      border-radius: 29px;
      padding: 0 10px;

      @include surface {
        border-radius: 50% !important;
      }
    }
  }

  .darkMode {
    .mat-mdc-button {
      &.view-more {
        background: #91466a;
      }

      &.cancel {
        background: #efefef;
        color: #000000;
      }

      &.continue-btn {
        background: #91466a;
      }

      &.back-btn {
        background: #cccccc;
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-focus-overlay {
    background: transparent;
  }

  .mat-mdc-icon-button {
    --mat-mdc-button-persistent-ripple-color: unset;
    --mat-mdc-button-ripple-color: unset;
  }
}
