@mixin mat-select-custom-theme() {
  .mat-mdc-select-panel {
    --roam-mdc-select-panel-min-height: 44px;
    --roam-mdc-select-panel-max-height: 100%;

    padding: 0!important;
    box-shadow: 0 3px 6px #00000029 !important;
    border-radius: 12px !important;
    max-height: var(--roam-mdc-select-panel-max-height) !important;
    position: relative;
    top: 60px;

    .mat-mdc-option {
      min-height: var(--roam-mdc-select-panel-min-height) !important;
      padding: 10px 22px;

      &:only-child {
        border-bottom-color: transparent;
      }

      .mdc-list-item__primary-text {
        width: 100%;

        .roam-text {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          width: 100%;
        }
      }

      .mat-mdc-radio-button {
        .mdc-radio {
          padding-right: 0!important;
  
          .mdc-radio__background {
            width: 24px;
            height: 24px;
          }
  
          .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
            background-color: #91466A !important;
          }
        }

        .mdc-form-field--align-end {
          width: 100%;
          
          .mdc-label{
            width: 100%;
          }
        }
      }
    }

    .mat-mdc-option-pseudo-checkbox {
      height: 22px;
      width: 22px;
      margin-right: -8px!important;
      border: 2px solid #cccccc;
      border-radius: 99%;
      order: 1;
      margin: 0 10px;

      &.mat-pseudo-checkbox-checked {
        background: #91466A;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
    .mat-option-text {
      font-size: 12px;
      line-height: 21px;
      font-weight: 300;
    }

    .clear-btn {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 32px;

      button {
        text-decoration: underline;
      }

      .mat-mdc-button {
        font-size: 12px;
        line-height: 21px;
        font-weight: 300;
      }
    }

    .apply-btn {
      padding-top: 31px;

      .mat-mdc-button {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        width: 100%;
        background: #91466a;
        border-radius: 21px;
        padding: 6px;
        color: #ffffff;
      }
    }

    .grid-options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .search-wrapper {
      width: 100%;
      padding: 12px;
      position: relative;

      input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 15px;
        font-weight: 400;
        line-height: normal;
        border: 1px solid #D6D3D7;
        padding: 12px 12px 12px 32px;
        border-radius: 12px;

        &::placeholder {
          font-size: 15px;
          font-weight: 400;
          line-height: normal;
          color: #949494;
        }
      }

      mat-icon {
        position: absolute;
        left: 22px;
        top: 50%;
        color: #949494;
        font-size: 20px;
        transform: translateY(calc(50% - 21px));
      }
    }
  }

  .association-select {
    max-height: 50vh!important;
  }

  .contact-sidenav-menu {
    padding: 0 !important; /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-placeholder {
      font: 400 14px / 20px Poppins !important;
      letter-spacing: normal !important;
    }
  }

  .select-transparent {
    .mat-mdc-text-field-wrapper {
      background-color: transparent !important;
      height: 30px;
      border-radius: 0;
      padding-left: 0;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
      padding-top: 5px;
    }

    .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: unset;
    }

    .mat-mdc-select-panel {
      min-width: 400px;
    }

    &.black {
      .mat-mdc-select,
      .mat-mdc-select-arrow{
        color: #ffffff !important;
      }
    }
  }

  .select-cdk-pane:only-child {
    min-width: 15rem;
  }

  .mat-paginator-page-number {
    cursor: pointer;
    margin: 0 5px;
    user-select: none;
  }

  .mat-paginator-page-number:hover {
    text-decoration: underline;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: #D6D3D7!important;
  }

  .config-dropdown-content {
    max-height: 45vh!important;
    display: block;
  }
}

