@use '@angular/material' as mat;

@mixin mat-progress-bar-custom-theme($theme) {
    $primary: map-get($theme, primary);

    .mat-mdc-progress-bar {
    }

    .mat-mdc-progress-spinner, .mat-mdc-progress-spinner {
        circle {
            stroke: mat.get-color-from-palette($primary, 800);
        }
    }
}