.fx-spacer {
  flex: 1 0 auto;
}

.mat-mdc-menu-item {
  text-decoration: none !important;
}

.roam-link,
.roam-text-link {
  cursor: pointer;
  color: var(--roam-color-primary);

  &:hover {
    text-decoration: underline;
  }
}

.roam-button-stroked,
.roam-mdc-button-stroked {
  // TODO: customize token
  border-radius: 0.85rem !important;
  padding-inline: 1.5rem !important;
  padding-block: 1.2rem !important;

  &.roam-button-primary {
    border: 1px solid var(--roam-color-primary) !important;
    color: var(--roam-color-primary) !important;
  }
}

.roam-button-toggle-group {
  --mat-standard-button-toggle-selected-state-text-color: var(
    --roam-color-primary
  );
  --mat-standard-button-toggle-selected-state-background-color: var(
    --roam-color-white
  );

  display: flex;
  border: none !important;
  gap: var(--roam-gap-sm);

  mat-button-toggle {
    border-left: unset !important;
    border-radius: 2rem;
    font-size: 0.85rem;

    .mat-button-toggle-button {
      border-radius: 2rem;
      border-width: 1px;
      border-color: #808080;
      border-style: solid;
      color: #808080;
    }

    &.mat-button-toggle.mat-button-toggle-checked {
      .mat-button-toggle-button {
        background-color: #fcf5fc;
        border-color: var(--roam-color-primary);
        color: var(--roam-color-primary);
      }
    }
  }
}

.roam-table-filter-input-section {
  // TODO: Allow custom variable !;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;

  .input-wrapper {
    border-radius: 0.25rem;
    border: 1px solid #d6d3d7;
    display: flex;
    gap: 16px;
    padding: 8px 16px;
    width: 22rem;
    background: #fff;

    input {
      border: 0;
      width: inherit;
      background: transparent;
      border-radius: inherit;
    }
  }

  .sidepanel-trigger {
    background-color: white;
    border: 1px solid #d6d3d7;
    border-radius: 0.5rem;
    font-size: 0.85em;
    font-weight: 500;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: #f1f1f1;
    }

    &.has-filters {
      &::before {
        content: "";
        position: absolute;
        z-index: 10;
        border-radius: 100%;
        width: 0.5rem;
        height: 0.5rem;
        background-color: #d40101;
        top: 6px;
        left: 13px;
      }
    }
  }
}

.roam-mdc-table-wrapper,
.roam-table-host {
  --roam-table-wrapper-gap: 0;
  --roam-table-row-selected-bg: rgba(145, 70, 106, 0.05);
  --roam-table-header-bg: #ffffff;

  display: grid;
  position: relative;
  gap: var(--roam-table-wrapper-gap);

  table {
    height: fit-content;
    border-collapse: separate;

    thead {
      --roam-table-header-cell-bg: transparent;

      .mat-mdc-header-row {
        background-color: var(--roam-table-header-bg);
      }
    }

    .cell-index,
    .cell-header-index,
    // NOTE: backward compatibilities
    .index-cell,
    .index-header-cell {
      padding-inline: 1.5rem;
      max-width: 50px;
    }

    th:first-child {
      .mat-sort-header-content {
        font-weight: bold;
      }
    }

    .cell-action {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .cell-selection {
      width: 4rem;
    }
  }

  .empty-state {
    padding: 3rem;
  }

  .table-row {
    --roam-table-row-height: 5rem;

    cursor: pointer;

    td {
      max-width: 400px;
      border-bottom: 0.5px solid rgba(143, 134, 147, 0.3);
    }

    &:first-child td {
      border-top: 1px solid rgba(143, 134, 147, 0.3);
    }

    &:hover {
      background: #f1f1f1;
    }

    .table-row-cell {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-weight: 400;
      color: #555555;
    }

    // TODO: these belongs hoverVisible directive specifics
    .hover-visible {
      visibility: hidden;
    }

    .visible {
      visibility: visible !important;
    }
  }

  .table-row-selected {
    background-color: var(--roam-table-row-selected-bg) !important;

    td {
      &:not(:first-child):not(:last-child) {
        border-top: 1px solid var(--roam-color-primary);
        border-bottom: 1px solid var(--roam-color-primary);
      }

      &:first-child,
      &:last-child {
        border: 1px solid var(--roam-color-primary);
      }

      &:first-child {
        border-right: unset;
        border-top-left-radius: var(--roam-rounded);
        border-bottom-left-radius: var(--roam-rounded);
      }

      &:last-child {
        border-left: unset;
        border-top-right-radius: var(--roam-rounded);
        border-bottom-right-radius: var(--roam-rounded);
      }
    }
  }

  // TODO: refine!
  .roam-table-cell-list {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .item {
      display: flex;
      gap: 4px;
    }
  }

  .spinner-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: var(--roam-gap-2xl);
  }
}

.roam-host-backdrop-layer {
  position: absolute;
  z-index: 9999;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000036;
}

.mdc-floating-label--required:not(
    .mdc-floating-label--hide-required-marker
  )::after {
  color: red;
}

.roam-form-row {
  --roam-form-gap: var(--roam-gap-lg);

  display: flex;
  align-items: center;
  gap: var(--roam-form-gap);

  &:not(last-child) {
    margin-bottom: var(--roam-form-gap);
  }
}

.roam-mdc-form-field {
  --roam-mdc-form-field-min-width: 120px;
  --roam-mdc-floating-label-top-offset: 20px;
  --roam-mdc-form-field-infix-padding-block: 8px;
  --roam-mdc-select-value-text-size: 14px;

  min-width: var(--roam-mdc-form-field-min-width) !important;
  width: max-content;

  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-50%) !important; // no floating
  }

  .mdc-floating-label {
    --mat-mdc-form-field-label-offset-x: 0px !important;
  }

  .mat-mdc-select-trigger {
    font-size: 0.85rem;
  }

  .mat-mdc-text-field-wrapper {
    flex: unset;

    .mat-mdc-form-field-infix {
      padding-top: var(--roam-mdc-form-field-infix-padding-block) !important;
      padding-bottom: var(--roam-mdc-form-field-infix-padding-block) !important;
    }

    .mat-mdc-select-value-text {
      font-size: var(--roam-mdc-select-value-text-size);
    }

    .mat-mdc-floating-label {
      --roam-mdc-floating-label-top-offset: 20px;
    }
  }
}

.roam-mdc-select-panel {
  --roam-mdc-select-panel-max-height: 50svh !important;
  --roam-mdc-select-panel-min-width: 200px;
  --roam-mdc-select-panel-width: max-content;
  --roam-mdc-select-panel-border-color: #dfdfdf;
  --roam-mdc-option-gap: 1rem;
  --roam-mdc-option-text-size: 14px;
  --roam-mdc-select-panel-min-height: 3rem !important;
  --roam-mdc-select-panel-outline: 3rem !important;
  --roam-mdc-option-pseudo-checkbox-color: #876486;
  --roam-mdc-option-list-item-selected-bg: #f4e3eb;

  margin-top: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--roam-mdc-select-panel-outline);
  min-width: var(--roam-mdc-select-panel-min-width) !important;
  width: var(--roam-mdc-select-panel-width) !important;

  .mat-mdc-select-placeholder {
    font-size: 14px;
  }

  .roam-mdc-option {
    gap: var(--roam-mdc-option-gap);
    font-size: var(--roam-mdc-option-text-size);

    &.mdc-list-item--selected {
      background-color: var(--roam-mdc-option-list-item-selected-bg) !important;
    }

    .mat-mdc-option-pseudo-checkbox {
      order: 2;
      height: 18px !important;
      width: 18px !important;
      margin-inline: 0 !important;
      border: 2px solid var(--roam-mdc-option-pseudo-checkbox-color) !important;

      &::after {
        color: white !important;
      }
    }

    &.mat-mdc-option-multiple .mat-mdc-option-pseudo-checkbox {
      border-radius: 3px !important;
    }

    // mat-mdc-option mdc-list-item roam-mdc-option mat-mdc-option-multiple mat-mdc-option-active
    &:not(.mat-mdc-option-multiple)::after {
      content: " ";
      display: inline-block;
      background: url("../assets/svg/radio_button_unchecked.svg") 0 0 no-repeat;
      height: 22px;
      width: 27px;
      margin-right: -10px;
    }

    &.mat-mdc-option-active::after {
      background: url("../assets/svg/radio_button_checked.svg") 0 0 no-repeat;
    }

    &:not(.mat-mdc-option-multiple) .mat-mdc-option-pseudo-checkbox {
      display: none;
    }
  }

  .roam-mdc-select-panel-header {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid var(--roam-mdc-select-panel-border-color);

    .search-item {
      width: 100%;
      padding-block: 0.5rem;
      padding-inline: 1rem;
    }

    .add-item {
      width: 100%;
      display: flex;
      gap: 0.75rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 1rem;
      line-height: normal;
      font-family: Roboto;
      font-weight: 500;
      padding: 1rem;
      border-block: 1px solid var(--roam-mdc-select-panel-border-color);

      mat-icon {
        color: white;
        background-color: var(--roam-color-primary);
        border-radius: 999px;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        background-color: var(--roam-mdc-select-panel-border-color);
      }
    }
  }

  .roam-mdc-select-panel-body {
    max-height: 18rem;
    overflow-y: auto;
  }

  .roam-mdc-select-panel-footer {
    border-top: 1px solid var(--roam-mdc-select-panel-border-color);
  }
}

.roam-mdc-tab-group {
  --roam-mdc-tab-px: 0.5rem;

  .mdc-tab {
    padding-left: var(--roam-mdc-tab-px);
    padding-right: var(--roam-mdc-tab-px);
  }

  .mat-mdc-tab-header {
    --mdc-tab-indicator-active-indicator-height: 2rem;
    --mdc-secondary-navigation-tab-container-height: 2rem;
  }

  .mdc-tab--active .mdc-tab__text-label {
    color: white !important;
  }

  .mdc-tab-indicator,
  .mat-mdc-tab-label-container:after {
    display: none;
  }

  .mat-mdc-tab.mdc-tab {
    border-radius: 0.5rem;
    margin-inline: 0.25rem;
    margin-block: 0.5rem;
  }

  .mdc-tab-indicator--active {
    background-color: var(--roam-color-primary);
  }
}

app-customers-table {
  .table-row {
    --roam-table-row-height: 4.5rem !important;
  }
}

// TODO: Remove `task` prefix and adjust affected components
// This is only for backward compatibilities
.task-dialog-backdrop,
.roam-dialog-backdrop {
  background-color: var(--roam-color-backdrop);
}

@mixin action-button {
  font-size: 14px;
  font-weight: var(--roam-font-base);
  padding: var(--roam-gap-xs) var(--roam-gap-md);
  border-radius: var(--roam-rounded-lg);
}

.task-dialog-panel,
.roam-dialog-panel {
  // first of all, we need to override global hardcoded values :(
  .mat-mdc-dialog-container {
    padding: unset !important;
    border-radius: unset !important;

    .mat-mdc-dialog-title {
      padding-bottom: unset;
      padding-right: unset;
      border-bottom: unset;
      margin-bottom: unset;
    }

    --mdc-dialog-container-shape: var(--roam-rounded);
    --mdc-dialog-container-elevation: var(--roam-shadow);
    --mdc-dialog-subhead-size: var(--roam-text-md);
  }

  &.rounded-sm {
    .mat-mdc-dialog-container .mdc-dialog__surface {
      --mdc-dialog-container-shape: var(--roam-rounded-sm);
    }
  }

  .mat-mdc-select-panel .mat-mdc-option-pseudo-checkbox {
    background-color: var(--roam-color-primary) !important;
    border: none !important;

    &::after {
      color: white !important;
    }
  }

  a {
    cursor: pointer;
  }

  .dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--roam-gap) var(--roam-gap-lg);
    // border-bottom: 1px solid #dadada; // TODO: variables

    button {
      @include action-button();
    }
  }

  [mat-dialog-title] {
    padding: var(--roam-gap-none);

    &::before {
      display: none;
    }
  }

  .close-button {
    cursor: pointer;
    position: absolute;
    right: var(--roam-gap-xs);
    top: var(--roam-gap-xs);
  }

  mat-dialog-content {
    padding: var(--roam-gap) var(--roam-gap-lg) !important;
  }

  mat-dialog-actions {
    padding: var(--roam-gap) var(--roam-gap-lg);
    justify-content: space-between;
    background-color: #f4f4f4;
    border-top: 1px solid #dadada;

    a {
      cursor: pointer;
    }

    .confirm-button,
    .roam-dialog-button-confirm {
      @include action-button();
      height: inherit;
      padding-block: 0.5rem;
      padding-inline: 1.75rem;
      background-color: var(--roam-color-primary);
      color: var(--roam-color-white);
    }

    .cancel-button {
      font-weight: var(--roam-font-base);
      font-size: 14px; // TODO: override base size on the container;

      .label {
        text-decoration: underline;
      }
    }
  }
}

.roam-mdc-menu-panel {
  border-radius: 0.5rem !important;
  --mat-menu-item-label-text-size: 14px;

  &.mat-mdc-menu-panel {
    padding: 0 !important;
  }

  .mat-mdc-menu-content {
    padding: 0 !important;
  }

  .menu-item {
    padding-inline: 0.75rem !important;
  }
}

.roam-form {
  .roam-form-row {
    display: flex;
    gap: 1.5rem;

    &.balance > * {
      flex: 1;

      .select-trigger {
        width: 100%;
      }
    }
  }

  .roam-form-row:not(:last-child) {
    margin-bottom: 1rem;
  }
}

app-roam-card {
  --roam-card-title-text-size: 20px;
  --roam-card-title-padding: 0 0 12px 0;
  --roam-card-title-margin: 20px 30px 32px 30px;
  --roam-card-content-padding: 0 24px 16px 30px;

  .roam-card-compact {
    --roam-card-title-margin: 0;
    --roam-card-title-padding: 1.5rem;
    --roam-card-title-text-size: 18px;

    .mat-mdc-card-title {
      padding-bottom: 0;
      border: none;
    }

    .mat-mdc-card-content {
      --roam-card-content-padding: 0 1.5rem 1.5rem 1.5rem;
      padding-bottom: 1.5rem !important;
    }
  }
}

.roam-quick-action-item {
  color: var(--roam-color-primary);
  display: flex;
  align-items: center;
  gap: var(--roam-gap-xs);
  margin-bottom: var(--roam-gap-xs);
  cursor: pointer;

  .text {
    text-decoration: underline;
    font-size: 0.85rem;
    font-weight: 500;
  }
}

.roam-mdc-progress-bar-wrapper {
  --roam-mdc-progress-bar-height: 5px;
  --roam-mdc-progress-bar-color: var(--roam-color-primary);
  height: var(--roam-mdc-progress-bar-height);

  .mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: var(
      --roam-mdc-progress-bar-color
    );
    --mdc-linear-progress-active-indicator-height: var(
      --roam-mdc-progress-bar-height
    );
    --mdc-linear-progress-track-height: var(--roam-mdc-progress-bar-height);
    --mdc-linear-progress-track-shape: 0;
    --mdc-linear-progress-track-color: #eeeeee;
    border-radius: 1rem;
  }
}

// TODO: Allow variable replacement in the runtime!
.spinner-wrapper,
.roam-mdc-progress-spinner-wrapper {
  --roam-progress-spinner-bg: 201 201 201;
  --roam-progress-spinner-bg-alpha: 27%;

  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: rgb(
    var(--roam-progress-spinner-bg) / var(--roam-progress-spinner-bg-alpha)
  );
}

section.roam-page-section,
.roam-page-wrapper {
  padding: 2rem;
}

.roam-section-headline-text {
  font-weight: 500;
  margin-bottom: 1rem;
  letter-spacing: normal;
  font-size: 1.35rem;
  margin-bottom: 1rem !important;
}

.roam-mdc-card {
  --roam-mdc-card-border-color: #ebebeb;
  --roam-mdc-card-border-width: 0;

  &.mat-mdc-card {
    --mdc-elevated-card-container-elevation: var(--roam-shadow);
    --mdc-elevated-card-container-shape: 10px;
    --mdc-outlined-card-container-shape: 10px;
    border-width: var(--roam-mdc-card-border-width);
    border-color: var(--roam-mdc-card-border-color);
  }

  &.roam-card-table {
    padding-block: 0;
    padding-inline: 5px;
  }
}

.roam-button-select {
  display: block;
  position: relative;
  width: max-content;

  .roam-mdc-form-field {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    opacity: 0;
    width: 100%;
  }

  .roam-button-select-trigger {
    z-index: 99;
  }
}

.roam-mdc-option {
  .item-option-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding-block: 0.5rem;

    img {
      width: 25px;
      height: 25px;
      object-fit: cover;
      border-radius: 999px;
    }
  }

  &.roam-mdc-option-placeholder {
    .mat-mdc-option-pseudo-checkbox {
      display: none !important;
    }
  }
}

.roam-form-field {
  &.roam-form-field-error {
    .mat-mdc-text-field-wrapper {
      border: 1px solid red;
    }

    .roam-form-error-message {
      display: block;
    }

    .mat-mdc-floating-label.mdc-floating-label mat-label {
      color: red !important;
      background-color: white !important;
    }
  }

  .roam-form-error-message {
    display: none;
    color: red;
    font-size: 0.75rem;
  }
}

.roam-popover-button {
  cursor: pointer;
  color: var(--roam-color-primary);

  mat-icon,
  label {
    font-weight: 600;
    color: var(--roam-color-primary);
  }
}

.roam-popover-panel {
  --roam-popover-panel-width: 360px;
  --roam-popover-panel-max-width: 480px;

  display: block;
  border: 1px solid #dadada;
  background-color: white !important;
  border-radius: 0.75rem !important;
  margin: 0.25rem;
  max-width: var(--roam-popover-panel-max-width) !important;
  width: var(--roam-popover-panel-width) !important;

  &.mat-mdc-menu-panel {
    padding: 0 !important;
  }

  .mat-mdc-menu-content,
  .mat-mdc-menu-item {
    padding: 0 !important;
  }

  .mat-mdc-menu-item-text {
    width: 100%;
  }

  .roam-popover-inner {
    cursor: default;
    cursor: auto !important;
    user-select: auto !important;

    &:hover {
      background-color: unset !important;
    }
  }

  .popover-content {
    display: block;
    padding: 0;
  }

  .popover-footer {
    display: block;
    padding: 1rem;
    border-top: 1px solid #dadada;
  }

  .checkbox-wrapper {
    user-select: none;

    mat-checkbox {
      padding: 0.75rem 1rem;
      width: 100%;

      &.mat-mdc-checkbox-checked {
        background-color: #f8e4ef !important;
      }

      .mdc-form-field {
        width: inherit;
        font-size: 1rem;
        font-weight: 500;
      }

      .mdc-checkbox {
        order: 2;
      }
    }
  }

  .actions-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cancel-btn {
      cursor: pointer;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}

.roam-checkbox-panel {
  &.mat-mdc-select-panel {
    .mat-mdc-option-pseudo-checkbox {
      border-radius: 2px !important;
    }

    .mat-mdc-option.mdc-list-item--selected {
      background-color: #f8e4ef !important;
    }
  }
}

/**
* buttons
*/
.roam-btn-primary {
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
  outline: none;
  border: none;
  font-weight: 100;
  gap: 0.5rem;
  height: 42px;
  color: var(--roam-color-primary);
  font-weight: 600;
  background-color: #f5f5f5;
  cursor: pointer;

  &:hover {
    background-color: #f5ebf0;
  }

  [inlineSVG] svg {
    width: 16px;
    height: 16px;
  }
}

.roam-btn-primary-outline {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: #ffffff;
  color: var(--roam-color-primary);
  border: 1px solid var(--roam-color-primary);
  cursor: pointer;
  padding-block: 10px;
  padding-inline: 20px;

  &:hover {
    background-color: #f9f6f8;
  }

  [inlineSVG] svg {
    width: 16px;
    height: 16px;
  }

  .mat-icon {
    font-size: 14px;
    height: unset !important;
    width: unset !important;
  }
}

body:has(app-user-comments-panel.expanded) app-chat {
  transition: all 0.3s;
  transform: translateY(100px);
}

.mat-mdc-autocomplete-panel {
  &.roam-autocomplete {
    margin: 0.5rem 0;
    padding: 0 !important;
    border: 1px solid #dadada;
    border-radius: 0.5rem !important;
    box-shadow: 2px 3px 8px 4px #77777724 !important;

    .add-icon-button-wrapper {
      cursor: pointer;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      padding-block: 0.75rem;

      .add-icon-button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #91466a;
        border-radius: 50% !important;
        border: none;
        padding: 5px;
        cursor: pointer;
      }

      mat-icon {
        margin: 0 !important;
      }
    }

    mat-option {
      padding: 0.75rem 1rem;
    }

    .roam-option {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;

      svg {
        height: 42px;
        width: 42px;
      }
    }
  }
}
