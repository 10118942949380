.roam-title {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.roam-text {
  font-size: 14px;
  line-height: normal;
  font-weight: 300;
}

.details-header {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.details-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.details-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

.details-description {
  font-size: 14px;
  line-height: 21px;
  font-weight: 300;
}

.page-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.roam-description {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.roam-error-message {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.text-underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.red-text {
  color: #ba4141;
}

.green-text {
  color: #53a71e;
}

.text-2ab51e {
  color: #2ab51e;
}

.grey-text {
  color: #949494;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700;
}

.v-align-middle {
  vertical-align: middle;
}

.mat-name {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 500;
}

h1.title {
  font-size: 24px;
  font-weight: 500;
}

h6.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px !important;
  color: #222222;
}

.uppercase {
  text-transform: uppercase;
}

.capitalized {
  text-transform: capitalize !important;
}

.justify {
  text-align: justify;
}

.highlight {
  background: yellow;
}

.text-nowrap {
  text-wrap: nowrap !important;
}

a.link {
  cursor: pointer;
  color: var(--roam-color-primary);
  text-decoration: underline;
  font-weight: 500;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.text-justify {
  text-align: justify;
}
