$colors: (
  "white": $color-theme-white,
  "primary": $color-theme-primary,
  "black": $color-accent-black,
  "base": $color-grey-base,
  "glight": $color-accent-light,
  "gray": $color-grey-brown,
  "grey": $color-grey-border,
  "warning": $color-theme-red,
  "slate": $color-grey-steel,
  "new": $color-blue-main,
  "resolve": $color-theme-green,
  "escalate": $color-theme-red,
  "todo": $color-grey-steel,
  "ignore": $color-grey-steel,
  "disabled": $color-accent-light,
  "neutral": $color-grey-text
);

// Generate Button
@each $name, $color in $colors {
  .svg-#{$name} {
    fill: $color;
  }
}

// Generate background
@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color !important;
  }
}
