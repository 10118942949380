@use "sass:math";

$card-show : 4;
$card-gap : 1rem;
$selectedBackground: #F3EFF3;

.card-std {
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #E9E9E9;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(34, 34, 34, 0.12);
  overflow: hidden;

  &.unread {
    background-color: #F3EFF3;
  }

  &:hover,
  &.selected {
    background-color: $selectedBackground;
    border-color: #876486;
  }

  .card-square {
    position: relative;
    cursor: pointer;

    .checked {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      visibility: hidden;

      &.show {
        visibility: visible;
      }
    }

    &:hover .checked:not(.show) {
      visibility: visible;
    }
  }

  .card-info-list {
    margin-bottom: 1rem;
    display: flex;

    & > * {
      flex-basis: calc(100%/2);
    }
  }
}

.header-std-filter {
  padding-bottom: 1rem;
  margin-bottom: 1.75rem;
  border-bottom: 1px solid  rgba(202, 192, 206, 0.50);
  color: #5E5A5A;
  text-transform: uppercase;

  .arrow {
    margin-top: 2px;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  .icon-layout {
    position: relative;
    top: 8px;
    cursor: pointer;
  }
}

.card-border {
  padding: 1.25rem;
  border-radius: 8px;
  border: 1px solid rgba(232, 228, 233, 0.89);
  margin-bottom: 1.5rem;
}


.type-category {
  display: flex;
  flex-wrap: wrap;

  card-category,
  card-edit-category,
  card-category-view {
    margin-right: $card-gap;
    margin-bottom: $card-gap;
    max-width: calc((100% / $card-show) - #{$card-gap} - #{math.div($card-gap, $card-show)});
    flex: 1 0 calc((100% / $card-show) - #{$card-gap} - #{math.div($card-gap, $card-show)});

    &:nth-child(#{$card-show}n) {
      margin-right: 0;
      flex: 1 0 calc((100% / $card-show) - #{$card-gap});
    }
  }
}

.card-container {
  padding: 1.5rem 2rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(34, 34, 34, 0.05);
  border-radius: 12px;
}