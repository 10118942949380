@import "variables";

$font-sizes: (
  2px: 2px,
  3px: 3px,
  4px: 4px,
  5px: 5px,
  6px: 6px,
  7px: 7px,
  8px: 8px,
  9px: 9px,
  10px: 10px,
  11px: 11px,
  12px: 12px,
  13px: 13px,
  14px: 14px,
  16px: 16px,
  18px: 18px,
  20px: 20px,
  22px: 22px,
  24px: 24px,
  26px: 26px,
  28px: 28px,
  30px: 30px,
  32px: 32px,
  34px: 34px,
  36px: 36px,
  60px: 60px,
);

$colorText : (
  "primary": $color-theme-primary,
  "dust" : $color-grey-dust,
  "label": $color-grey-dark,
  "black": $color-text-black,
  "green": $color-theme-green,
  "warning": $color-theme-red,
  "grey": $color-grey-base,
  "grey-dark": $color-grey-brown,
  "bclound": $color-blue-cloud,
  "iron": $color-text-dark,
  "blue": $color-blue-main
);

@each $name, $color in $colorText {
  .tc-#{$name} {
    color: $color !important;
  }
}

@each $size, $value in $font-sizes {
  .font-#{$size} {
    font-size: $value !important;
  }
}

.bold {
  font-weight: 600;
}

.semi {
  font-weight: 500;
}

.text-label {
  color: $color-text-black;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
}

.text-btn-underline {
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.tc-point {
  width: 5px;
  height: 5px;
  background:#949494;
  display: block;
  border-radius: 100%;
}

.text-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 1rem;
  text-align: justify;
}

.text-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 1rem;
}

.text-clamp-inline {
  display: flex;
  flex-direction: column;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  max-height: 3em;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}