.mat-tab-chip {
  .mat-mdc-tab-label-container {
    margin-bottom: -6px;
  }

  &>.mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 1;
    height: 30px;
    min-width: auto;
    padding: 0 10px;
    border: 0.5px solid transparent;
    border-radius: 12px;
    opacity: 1;
    display: flex;
    align-items: center;

    .mdc-tab__text-label {
      color: #5d4e4e;
      transition: none;
    }

    &.mdc-tab--active {
      background-color: rgba(145, 70, 106, 1);

      .mdc-tab__text-label {
        color: #ffffff!important;
        font-weight: 400!important;
      }
    }

    .mdc-tab-indicator__content--underline {
      display: none;
    }

    .mdc-tab__ripple {
      display: none;
    }
  }

  .mat-mdc{
    &-tab-labels {
      gap: 10px;
      padding-bottom: 24px;

      .mat-mdc-tab-label {
        height: 29px;
        min-width: auto;
        padding: 0 10px;
        color: #5d4e4e;
        border: 0.5px solid rgba(214, 211, 215, 1);
        border-radius: 25px;
        opacity: 1;
      }
    }
  }
}

.sub-mat-group {

  .mat-mdc-tab-header {
    // height: 53px;
  }

  &>.mat-mdc-tab-header .mat-mdc-tab {
    height: 53px!important;
  }

  .mat-mdc-tab-label-container:after {
    content: '';
    border-bottom: 1px solid #ccc;
    width: 100%;
    position: absolute;
  }

  .mat-mdc-tab-labels {
    display: inline-flex;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0;
  }

  .mdc-tab-indicator--active .mdc-tab-indicator__content {
    opacity: 1;
    height: 3px;
    background-color: #91466a;
    border-color: transparent !important;
  }

  .mat-mdc-tab-body-content {
    padding: 20px 5px 10px 5px;
  }
}

::ng-deep .mat-mdc-tab-body.mat-mdc-tab-body-active {
  animation: slideDown 0.5s ease-out;
}
