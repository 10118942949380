@mixin customizeWidgetTable() {
  .table-widget app-table thead th:first-child {
    width: 15%
  }

  .table-widget app-table thead th:nth-child(2),
  .table-widget app-table thead th:nth-child(3) {
    width: 25%;
  }
}

@include customizeWidgetTable();

.table-empty {
  .mat-column-selection .mat-mdc-checkbox {
    visibility: hidden;
  }
}

table {
  .mat-column-selection .mat-mdc-checkbox{
    @include surface {
      visibility: hidden;
    }
  }

  &.table--selected {
    .mat-column-selection .mat-mdc-checkbox{
      @include surface {
        visibility: visible;
      }
    }
  }

  .mat-column-action {
    .more-button {
      visibility: hidden;

      @include surface {
        visibility: visible;
      }
    }

    .show--action {
      .more-button {
        visibility: visible;
      }
    }
  }
}