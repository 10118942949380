@import "responsive";

$portal-collapse-width : calc(68px - 0.5rem);

.page-wrapper {
  padding: 3rem 3rem 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100vh;

  .header {
    min-height: 45px;
  }
  
  @include md {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.dashboard-homepage {
  max-width: 1300px;
  margin: 0 auto;
}

.sidebar-second-menu {
  width: calc(calc(68px - 0.5rem) + 217px);
  padding-left: calc(68px - 0.5rem);
  background-color: #DDD6DB ;

  @include surface {
    width: 0;
    max-width: 0;
  }

  @include large-screen {
    max-width: 1200px;
  }

  .menu-container {
    padding: 4rem 0.5rem 0.5rem 1rem;
  }

  .nav-item-navigation {
    width: inherit;
    margin: 0 -1rem;
    font-size: 14px;
    font-weight: 300;
    display: block;

    .nav-item-name {
      position: relative;
      height: 48px;
      width: 100%;
      padding: 0.25rem 0.5rem;
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }


    &.mat-mdc-menu-item:hover {
      background-color: unset!important;
    }

    &.active .nav-item-name {
      color: #91466a;
    }

    &.active .nav-item-name,
    .nav-item-name:hover {
      background-color: #c6bec5!important;
      border-radius: 0.5rem;
    }

    &.active::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0.5rem;
      width: 4px;
      height: 100%;
      background: #91466a;
    }
  }
}
