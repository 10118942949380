/* custom scrollbar*/
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.c-pointer {
  cursor: pointer;
}

.c-no-event {
  pointer-events: none;
}

.border {
  border: 1px solid #d9d9d9;
  border-radius: 11px;
}

.grey-sidebar {
  background: #fcfcfc;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.separator {
  border: 1px solid #efefef;
  width: 100%;
  margin: 30px 0;
}

.circle-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #e8e4e9;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  background-color: #e8e4e9;
  color: #91466a;

  &.disabled {
    color: #cccccc;
    cursor: auto;
  }
}

.roam-logo {
  width: 21px;
  height: 21px;
  background: #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 12px;
  }
}

.mat-drawer-container {
  height: 100%;
  background: #f8f9fd !important;
}

.left-bar-navigation {
  background: #886486;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 23px;
  padding: 60px 22px;

  .mat-icon {
    color: #ffffff;
  }
}

.unread-status {
  width: 13px;
  height: 13px;
  background: #ba4141;
  border-radius: 50%;
}

.online-status {
  width: 13px;
  height: 13px;
  background: #48be2c;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .left-bar-navigation {
    background: transparent;
    padding: 0;
    flex-direction: row;
    padding-bottom: 20px;

    img {
      width: 21px;
    }
  }

  .closed-menu {
    .mat-drawer-inner-container {
      width: 40px;
      height: 100%;
    }
  }
}

.dashed {
  border: 1px dashed #aaa;
  border-radius: 8px;
}

.no-gutter {
  margin-right: 0;
  margin-left: 0;
}

.gutter {
  margin-left: -16px;
  margin-right: -16px;
}

.gutter-left {
  margin-left: -15px;
}

.gutter-right {
  margin-right: -15px;
}

.gutter-container {
  margin-top: -52px;
}

.mat-loading mat-tab-group .mat-tab-body-content {
  display: none;
}

.img-center {
  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHAW7VtIk_8C544nvEEjO8ViOwmoGa6zv4kA_ObFh3BmM87bf0wQhQANM1GO3scr7zdt8&usqp=CAU);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.mat-mdc-paginator-container {
  font-size: 0.875rem;
}

.hover-underline:hover {
  text-decoration: underline;
}

hr {
  margin: 1rem 0 !important;
  border: 0;
  width: 100%;
  height: 1px;
  background: rgba(143, 134, 147, 0.30);
}

.footer-action {
  position: absolute;
  width: 100%;
  bottom: 0;
  margin: 0 -32px;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;

  a {
    font-size: 0.875rem;
    text-decoration: underline;
    cursor: pointer;
  }
}

.content-list-accordion {
  max-height: calc(100vh - 315px);
  overflow-y: auto;
  padding-right: 10px;
}

.swiper-pagination-bullet-active {
  background-color: #91466a;
}

.cdk-overlay-container .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7) !important;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  transition-property: transform, top; /* Add top to animate vertically */
}

ul.disc {
  list-style: disc;
  padding: 0 1rem;
}

.mat-mdc-tab,
.mdc-button {
  letter-spacing: normal !important;
}

.spinner-loader {
  position: relative;
  top: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Input {
  height: 45px!important;
  border-radius: 12px;
}

.stripe-form {
  display: none;
}

.content-body-template p{
  margin-bottom: 1rem!important;
}

.pointer-event-none {
  pointer-events: none;
}