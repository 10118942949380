.chat-conversation {
  position: relative;
  width: 560px;
  height: 100dvh;
  max-height: 100%;
  border-radius: 8px 8px 0 0;
  background: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 999;

  header {
    padding: 12px 1rem;
    border-bottom: 1px solid #E9E9E9;
  }

  .chat-message {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    z-index: 0;
  }

  .chat-input {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 8px 8px;
    border-bottom: 1px solid #D6D3D7;
    background: #ffffff;
    box-shadow: 0px -2px 4px 0px rgba(34, 34, 34, 0.10);
    padding: 1rem;
  }

  .chat-input-form {
    color: #949494;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    padding: 10px 12px;
    border-radius: 12px;
    font-size: 14px;
    border: 1px solid #D6D3D7;
    background: #F5F6FA;
    flex-grow: 1;
  }

  .chat-input-text {
    width: calc(100% - 46px);
    font-size: 14px;
    color: #222222;
  }

  input {
    width: calc(100% - 46px);
    height: 30px;
    background: transparent;
    border: none;
    font-family: "Poppins", sans-serif;
  }
}

.chat-group {
  display: flex;
  gap: 8px;
}

.chat-bubble {
  margin-bottom: 4px;
  border-radius: 4px;
  border: 1px solid rgba(202, 192, 206, 0.50);
  background: #F1F1F1;
  box-shadow: 0 2px 8px 0 rgba(34, 34, 34, 0.05);
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  max-width: 80%;
  word-wrap: break-word;

  &.assistance {
    padding: 6px 16px;
    margin-bottom: 12px;
    border-color: transparent;
    border-radius: 0 12px 12px 12px;
    background: #F1F1F1;
  }
}

.chat-list {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 4px;

  &.user {
    justify-content: flex-end;
    text-align: right;
  }
}

.chat-bubble {
  border-radius: 4px;
  border: 1px solid rgba(202, 192, 206, 0.50);
  background: #F1F1F1;
  box-shadow: 0 2px 8px 0 rgba(34, 34, 34, 0.05);
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
}

.user .chat-bubble,
.assistance .chat-bubble {
  width: 100%;
  max-width: 280px;
  padding: 6px 12px;
  border-color: transparent;
}

.recipient .chat-bubble,
.assistance .chat-bubble {
  border-radius: 0 12px 12px 12px;
  background: #F1F1F1;
}

.user .chat-bubble{
  border-radius: 12px 12px 0 12px;
  background: #876486;
  color: #ffffff;
  text-align: left;
}

.recipient .chat-bubble {
  border-radius: 0 12px 12px 12px;
  background-color: #ffffff!important;

  .chat-text,
  .chat-date {
    color: #222222;
  }
}

.chat-date {
  padding-left: 8px;
  color:#777777;
  font-size: 10px;
  text-align: right;
}

.user .chat-date {
  color: #ffffff;
}

.user .like {
  display: none;
}

.like .icon {
  fill: #999999;
  cursor: pointer;
}

.like .icon:hover {
  background: #EEEEEE;
}

.scrolled-chat-box {
  margin-right: -1px;
  padding-right: 2px;
  flex-direction: column;
  flex: 1 0 0;
  overflow-y: scroll;
  z-index: 0;
}
