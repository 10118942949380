@import "variables";


@mixin mat-table-custom-theme() {
  .mat-mdc-table {
    width: 100%;
    background-color: initial !important;
    font-family: "Poppins", sans-serif;

    --roam-table-row-height: 64px;
    --roam-table-header-cell-bg: #ffffff;

    .mat-mdc-row {
      height: var(--roam-table-row-height);
      padding: 0 5px;
      background-color: #ffffff;
    }

    .mat-sort-header {
      background-color: #ffffff;
    }

    .mat-mdc-header-cell {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400 !important;
      padding: 12px;
      border: none;
      text-transform: uppercase;
      color: #666666;
      background-color: var(--roam-table-header-cell-bg) !important;

      .arrow-down {
        position: absolute;
        right: -18px;
        top: 13px;
        cursor: pointer;
      }

      .arrow-up {
        position: absolute;
        right: -18px;
        top: 4px;
        cursor: pointer;
      }
    }

    .mat-mdc-cell {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      padding: 12px;
      border: none;
      color: #474747;

      &.element-name {
        font-weight: 500;
      }
    }

    &.shadow-table {
      .mat-mdc-row {
        &:nth-child(even) {
          background: #fcfcfc;
        }

        &:nth-child(odd) {
          background: #f9f9f9;
        }

        .mat-mdc-cell {
          color: #000000;
        }
      }
    }

    &.bordered-table {
      .mat-mdc-header-row {
        border-bottom: 1px solid rgba(143, 134, 147, 0.3);
      }

      .mat-mdc-row {
        border-bottom: 0.5px solid rgba(143, 134, 147, 0.3);

        &:hover {
          background: #f1f1f1;
        }
      }
    }

    &.unit-charges-table {
      .mat-mdc-row {
        height: 57px;
      }
    }

    &__grey {
      thead {
        background-color: #f5f5f5 !important;
        .mat-mdc-header-row {
          height: 41px;
        }
        th {
          padding: 0 20px !important;
          font-size: 11px !important;
          color: #767676;
        }
        [aria-sort="ascending"],
        [aria-sort="descending"] {
          color: #5e5a5a;
          font-weight: 600;
        }
      }
      tbody {
        .mat-mdc-row {
          height: 0;
          border-bottom: 0.5px solid rgba(143, 134, 147, 0.3);
          td {
            padding: 20px !important;
            font-size: 12px !important;
            font-weight: 500;
          }
        }
      }
    }
    &__grey-border {
      thead {
        .mat-mdc-header-row {
          height: 41px;
        }
        th {
          padding: 0 20px !important;
          font-size: 14px !important;
          border-bottom: 1px solid #000000 !important;
          color: #767676;
          text-transform: uppercase !important;
        }
        [aria-sort="ascending"],
        [aria-sort="descending"] {
          color: #5e5a5a;
          font-weight: 600;
        }
      }
      tbody {
        .mat-mdc-row {
          height: 0;
          border-bottom: 0.5px solid rgba(143, 134, 147, 0.3);
          td {
            padding: 20px !important;
            color: #767676;
            //font-size: 12px !important;
            //font-weight: 500;
            .status__chip {
              display: block;
              width: fit-content;
              border-radius: 4px;
              padding: 1px 6px;
              font-weight: 600;
              &.bg-progress {
                background-color: #d9e3fe;
                color: #295ad8;
              }
              &.bg-hold,
              &.bg-pending {
                background-color: #f1ece0;
                color: #b5781e;
              }
              &.bg-resolved,
              &.bg-approved {
                background-color: #e1f1e0;
                color: #2ab51e;
              }
              &.bg-draft {
                background-color: #e8e4e9;
              }
            }
          }
        }

        .selected {
          td {
            background-color: #91466a0d;
            border-top: 1px solid #91466a;
            border-bottom: 1px solid #91466a;
            border-radius: 8px;

            &:first-child {
              border-left: 1px solid #91466a;
            }

            &:last-child {
              border-right: 1px solid #91466a;
            }

            td:first-child {
              border-left: 0.5px solid #91466a;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }
    }
  }

  .mat-table-dark {
    .mat-mdc-header-cell {
      background-color: #f5f5f5 !important;
      color: #666666;
      font-size: 12px;
    }

    .mat-mdc-row {
      height: unset;
    }

    .mat-mdc-cell {
      padding: 1rem 10px;
      border-bottom: 1px solid #d6d3d7;
      color: #666666;
    }

    &.dark {
      .mat-mdc-header-cell:first-child,
      .mat-mdc-row .mat-mdc-cell:first-child .table-row-cell{
        color: #222222 !important;
        font-weight: 500!important;
      }
    }
  }

  table.default{
    .mat-mdc-header-row {
      background-color: #f5f5f5;
    }

    .mat-mdc-header-cell {
      &:first-child {
        padding-left: 1rem !important;
      }
    }

    .mat-mdc-cell {
      color: #767676;
    }

    thead {
      th {
        font-size: 0.75rem !important;
      }
    }

    tbody {
      tr {
        &:last-child {
          border-bottom-color: transparent;
        }
      }
    }

    .mat-mdc-checkbox {
      margin-bottom: 0 !important;
    }

    .text-th-right {
      &.mat-mdc-header-cell {
        text-align: right;
      }

      &.mat-mdc-cell {
        text-align: right;
      }
    }
  }

  .table-bg {
    .mat-mdc-row {
      height: 48px;
      border-bottom: 1px solid $color-grey-border;

      &:last-child {
        border-bottom-color: transparent;
      }
    }

    .mat-mdc-cell {
      padding: 1rem 0.5rem;
      background: $color-grey-shadow;
    }

    .mat-mdc-header-row {
      height: 42px;
      color: $color-accent-grey;

      .mat-mdc-header-cell {
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
  }

  table.radius {
    width: 100%;
    border-collapse: collapse;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .roam-table-default {
    .mat-mdc-header-cell {
      color: #767676;
      font-weight: 400;
      font-size: 12px;

      &:first-child {
        padding-left: 1rem;
      }
    }

    .mat-mdc-header-row {
      border-bottom: 1px solid #e9e9e9;
    }

    .mat-mdc-row {
      border-bottom: 1px solid #e9e9e9;

      &.unread {
        background-color: #f5f5f5;
        color: #777777;
      }
    }

    .mat-mdc-cell {
      padding: 1rem 0.5rem;
      font-weight: 400;
    }

    .mat-mdc-row {
      &.checked {
        background-color: rgba(145, 70, 106, 0.05);

        td {
          border-top: 1px solid #876486 !important;
          border-bottom: 1px solid #876486;

          &:first-child {
            border-left: 1px solid #876486;
          }

          &:last-child {
            border-right: 1px solid #876486;
          }
        }

        .check-box {
          visibility: visible;
        }

        .set-checkbox {
          visibility: visible;
        }
      }
    }
  }

  .mat-mdc-paginator {
    margin-top: 10px;
    background-color: initial !important;
  }

  .show-checkbox {
    .check-box {
      visibility: visible;
    }
  }

  .check-box {
    display: flex;
    justify-content: flex-end;
    visibility: hidden;
  }

  .roam-table-background {
    .mat-mdc-header-row {
      .mat-mdc-header-cell {
        color: #222222;
        font-weight: 500 !important;
        background-color: #f5f5f5 !important;

        &:first-child {
          padding-left: 6px;
        }
      }
    }

    .text-cell-table {
      color: #6f6f6f;
      font-weight: 400 !important;
    }

    .loader {
      padding-top: 0 !important;
    }
  }

  .borderless {
    .mat-mdc-row.selected {
      background: transparent !important;
      border: none !important;
      border-bottom: 1px solid rgba(143, 134, 147, 0.3) !important;
    }
  }

  .col-editable {
    max-width: 100%;
    min-width: 100%;
    border-radius: 5px;
    border: 0.5px solid rgba(0, 0, 0, 0.32);
    background: #ffffff;
    padding: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
  }

  .table-action-right {
    th:last-child,
    td:last-child,
    .mat-column-selection {
      .mat-sort-header-container {
        justify-content: flex-end;
      }

      .table-row-cell {
        text-align: right;
      }
    }

    .mat-column-action {
      width: 100px;
      
      & > * {
        text-align: center;
        display: flex!important;
        justify-content: center;
      }
    }
  
  }

  .table-shadow .table-container {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    padding: 0 1rem 1rem 1rem;
  }

  .mat-sort-header-sorted {
    font-weight: bold;
  }

  .mat-column-selection {
    width: 60px;
  }
}
