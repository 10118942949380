.dialog-40 {
  padding: 40px 40px 30px 40px;
}

.dialog-detail {
  header {
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .status {
    min-height: 48px;
    padding: 0 24px;
    border-radius: 8px;
    background-color: #E1F1E0;
    display: flex;
    align-items: center;
    gap:0.5rem;
    color: #2AB51E;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;

    .icon {
      padding-top: 6px;
    }
  }

  .bill-type {
    padding-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    & app-text-label-desc {
      flex: 1 0 calc(100%/4);
    }
  }

  .attachment {
    padding: 24px 32px;
    border-radius: 10px;
    border: 1px dashed #D6D3D7;
    background: #F9F7FB;
    display: flex;
    align-items: center;
    align-self: stretch;
    margin-top: 0.5rem;
  }
}

.mat-dialog-action {
  padding: 12px 26px!important;
  background-color: #F5F5F5;
  display: flex;
  justify-content: space-between!important;
}
