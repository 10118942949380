@import "common";
@import "spinner";
@import "size";
@import "scrollbar";
@import "card";
@import "dialog";
@import "toastr";
@import "table-template";
@import "table-custom";
@import "tab";
@import "form";
@import "editor";
@import "radio";
@import "toggle-switch";
@import "buttons";
@import "select";
@import "message";
@import "collapse";
@import "chat";
@import "calendar";
@import "checkbox";
@import "document";
@import "drawer";
@import "filter";
@import "dropdown";
@import "page-add";

::ng-deep .table-widget {
  @include customizeWidgetTable();
  display: none;
}
