.unstyled-select-form-field {
  font-size: inherit !important;
  line-height: inherit !important;
  width: auto;

  .mat-mdc-form-field-focus-overlay {
    background: none !important;
  }

  .mat-mdc-text-field-wrapper {
    border-radius: 0 !important;
    padding: 0;
  }

  .mat-mdc-form-field-infix {
    min-height: auto !important;
    width: auto;
    padding: 0 !important;
  }

  .mat-mdc-select {
    font-size: inherit;
    line-height: inherit;
  }

  .mdc-line-ripple {
    display: none;
  }
}

.select-with-radio-panel {
  min-width: 280px;
  padding: 0 !important;
  border-radius: 12px;

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background: #91466A1A;
  }

  mat-pseudo-checkbox {
    display: none;
  }

  mat-option > span {
    display: block;
    width: 100%;
    font-size: 14px !important;
  }
}


.card-select-radio app-roam-radio-card .radio-label {
  width: calc(25% - 8px);
  min-width: unset;
}

.card-select-radio app-roam-radio-card .radio-button {
  min-width: unset;
}