@import "variables";

.dropdown-content {
  border-radius: 12px !important;
  border: 1px solid #e8e4e9;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05)!important;
  padding: 4px 0 !important;
  margin-top: 5px;
  min-width: max-content !important;
  min-width: 150px!important;
  max-height: 380px!important;

  .mat-mdc-menu-content {
    padding-top: 0!important;
    padding-bottom: 0!important;
    max-width: inherit!important;
    min-width: 115px;
  }

  .mat-mdc-menu-item {
    padding: 12px 16px!important;
  }
}


.dropdown-content-wrapper {
  border-radius: 12px !important;
  border: 1px solid #e8e4e9;
  box-shadow:
    0px 0px 10px 0px #0000000f,
    0px 5px 12px 0px #0000000f;
  padding: 16px 0 !important;
  margin-top: 5px;
  min-width: max-content !important;

  .mat-mdc-menu-content {
    padding: 0;
  }
}

.sm-content{
  width: 250px;
}

.roam-dropdown-item {
  min-width: 280px;
}

.dropdown-content-group {
  max-height: 35vh;
  min-width: 220px;
  overflow-x: hidden;
  overflow-y: auto;
}