@mixin mat-card-custom-theme() {
  .card-category {
    .mat-mdc-card-title {
      padding: var(--roam-card-title-padding);
      margin: var(--roam-card-title-margin) !important;
      font-size: var(--roam-card-title-text-size);
      border-bottom: 1px solid #DDD;
      font-weight: 600;
    }
  }
}

