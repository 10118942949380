@mixin mat-slide-toggle-custom-theme() {
  .mat-mdc-slide-toggle {
    &.small-toggle {
      .mat-mdc-slide-toggle-bar {
        border: 2px solid #cccccc;
        background-color: transparent;
        width: 32px;
        height: 16px;
      }

      .mat-mdc-slide-toggle-thumb-container {
        border-radius: 9px;
        width: 8px;
        height: 8px;
        top: 2px;
        left: 2px;

        .mat-mdc-slide-toggle-thumb {
          background-color: #cccccc;
          width: 8px;
          height: 8px;
        }
      }

      &.mat-checked {
        .mat-mdc-slide-toggle-bar {
          border: 2px solid #53a71e;
          background-color: transparent !important;
        }

        .mat-mdc-slide-toggle-thumb-container {
          right: 2px;

          .mat-mdc-slide-toggle-thumb {
            background-color: #53a71e;
          }
        }
      }
    }

    &.great-toggle {
      .mat-mdc-slide-toggle-thumb-container {
        border-radius: 9px;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
        .mat-mdc-slide-toggle-thumb {
          background-color: #cccccc;
          width: 20px;
          height: 20px;
        }
      }

      .mat-mdc-slide-toggle-bar {
        border: 2px solid #cccccc;
        background-color: transparent;
        width: 36px;
        height: 24px;
        border-radius: 199px;
      }

      .mat-mdc-slide-toggle-thumb {
        background-color: #ffffff;
        width: 8px;
        height: 8px;
      }

      &.mat-checked {
        .mat-mdc-slide-toggle-bar {
          background-color: #91466a !important;
          border: 0;
        }

        .mat-mdc-slide-toggle-thumb-container {
          top: 2px;
          right: 2px;

          .mat-mdc-slide-toggle-thumb {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}
