@mixin mat-expansion-panel-custom-theme() {
    .mat-expansion-indicator {
        margin: 0 16px 9px 0 !important;

        &::after {
            padding: 3px !important;
            border-width: 0 3px 3px 0 !important;
            color: #91466A;
        }
    }
}