.link-button {
  background: transparent;
  border: 0;
  cursor: pointer;

  color: #222;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-decoration: underline;
}
