@import "responsive";

.report-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include standard {
    max-width: 500px;
  }
}

.table-raw {
  margin-bottom: 1rem;
  display: table!important;
  width: 100%;
  color: #222222;
  font-size: 14px;
}

.table-raw thead {
  height: 45.5px;
  background-color: #F9F9F9;
  border-bottom: 1px solid #E8E4E9;
  text-transform: uppercase;
}

.table-raw th{
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 400;
  text-align: left;
}

.table-raw th:first-child {
  padding-left: 1.5rem;
  text-align: left;
}

.table-raw th:last-child {
  padding-right: 1.5rem;
}

.caret-icon {
  position: relative;
}

.caret-icon::before {
  content: "▲";
  position: relative;
  top: -1px;
  cursor: pointer;
}

.caret-icon.active::before {
  content: "▼"
}

.t-col-td{
  padding-top: 1rem;
  //padding-bottom: 1rem;
}

.td-col-td-title {
  color: #222222;
  font-weight: 500;
}

.t-col-td:last-child {
  padding-right: 1.5rem;
}

.col-td td,
.t-col-td-nd {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
}

.t-col-td-nd:last-child {
  padding-right: 1.5rem;
}

.text-ellipsis {
  @include medium-sm {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  @include md {
    max-width: 100px;
  }
}

tbody tr td:first-child {
  padding-left: 1.5rem;
}

.t-row-master {
  text-transform: uppercase;
}

.t-row-master.bdr{
  border-top: 1px solid #CCCCCC;
}

.t-row-sub {
  color: #222222;
  font-size: 14px;
  font-weight: 600;
}

.t-row-border{
  border-bottom: 1px solid #CCCCCC;
}

.t-row-border td {
  padding-bottom: 1rem!important;
}

.t-row-border:last-child{
  border-color: transparent;
}

.t-row-master > * {
  font-size: 1rem;
  font-weight: 600;
}

.t-row-master-title {
  color: #222222;
  font-size: 1rem;
  font-weight: 600;
  //padding-bottom: 0.5rem;
}

.t-row-sub-title {
  padding-bottom: 0.5rem;
  color: #222222;
  font-size: 14px;
  font-weight: 600;
}

.t-row-total {
  color: #222222;
}

.t-row-grand-title td{
  font-size: 18px;
  font-weight: 600;
}

.t-row-grand td,
.t-row-total-main td{
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  color: #222222;
  text-transform: uppercase;
}

.t-row-grand td {
  font-size: 1rem!important;
  font-weight: 600;
}

.t-row-main-one td {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  color: #222222;
}

.t-row-black td{
  color: #222222;
}

.t-row-total-title {
  color: #222222;
  font-size: 14px;
  font-weight: 500;
}

.t-row-section td{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #222222;
  font-weight: 500;
}

.t-row-section.pt-1 {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.t-row-total td:last-child,
.t-row-total-main td:last-child,
.t-row-grand td:last-child,
.t-row-section td:last-child,
.col-td td:last-child{
  padding-right: 1.5rem;
}

.text-left td {
  text-align: left;
}

.text-right th,
.text-right td{
  text-align: right;
}

th.text-right{
  text-align: right!important;
}

.text-right td {
  &:last-child {
    padding-right: 1.5rem!important;
  }
}

.text-number-right {
  padding-left: 2%;
}

.set-td-1 td {
  padding-top: 1rem;
}

.cell-red {
  color: #D40101;
}

.table-raw.thead-dark thead{
  color: #666666;
  background-color: #EEEEEE;
}

.table-raw.table-odd-even td{
  padding-top: 12px;
  padding-bottom: 12px;
}

.table-odd-even tbody td {
  border-bottom: 1px solid #D6D3D7;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-odd-even tbody tr:nth-child(even) {
  background-color: #F9F6F8;
}

td a.link {
  color: #91466A;
  text-decoration: underline;
  cursor: pointer;
}

.indent-2 td:first-child {
  padding-left: 2.8rem;
}

.indent-3 td:first-child {
  padding-left: 4.5rem;
}

.bdr-transparent {
  border-color: transparent;
  display: none;
}

.table-base {
  width: 100%;
  tbody tr td:first-child {
    padding-left: 0;
  }
}