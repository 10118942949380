.d-flex {
  display: flex !important;
}

.d-grid {
  display: grid !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block;
}

.d-inherit {
  display: inherit;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none !important;
}

.position-relative {
  position: relative;
}

.position-sticky {
  overflow: auto;
  position: sticky;
  top: 0.5rem;
}

.f-wrap {
  flex-wrap: wrap;
}

.justify-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.justify-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align-start-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.justify-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.justify-end {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
}

.align-baseline {
  display: flex;
  align-items: baseline;
}

.align-start {
  display: flex;
  align-items: flex-start;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-stretch {
  display: flex;
  align-items: stretch;
}

.align-end {
  display: flex;
  align-items: flex-end;
}


.space-between {
  display: flex;
  justify-content: space-between;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.f-column {
  flex-direction: column;
}

.basis-10 {
  flex-basis: 10%;
}

.basis-31 {
  flex-basis: 31%;
}

.text-right {
  text-align: right;
}

.d-gap {
  display: flex;
  gap: 1rem;
}

.d-column {
  display: flex;
  flex-direction: column;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-column-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.gap-1rem {
  gap: 1rem;
}

.gap-3 {
  gap: 3px;
}

.gap-4px {
  gap: 4px;
}

.gap-5 {
  gap: 5px;
}

.gap-6px {
  gap: 6px;
}

.gap-7 {
  gap: 7px;
}

.gap-8 {
  gap: 8px !important;
}

.gap-8px {
  gap: 8px;
}

.gap-9 {
  gap: 9px;
}

.gap-10 {
  gap: 10px !important;
}

.gap-12 {
  gap: 12px !important;
}

.gap-12px {
  gap: 8px;
}

.gap-13 {
  gap: 13px;
}

.gap-15 {
  gap: 15px;
}

.gap-16 {
  gap: 16px !important;
}

.gap-18 {
  gap: 18px;
}

.gap-19 {
  gap: 19px;
}

.gap-20 {
  gap: 20px;
}

.gap-22 {
  gap: 22px;
}

.gap-26 {
  gap: 26px;
}

.gap-28 {
  gap: 28px;
}

.gap-30 {
  gap: 30px;
}

.gap-32 {
  gap: 32px;
}

.gap-35 {
  gap: 35px;
}

.gap-48 {
  gap: 48px;
}

.gap-51 {
  gap: 51px;
}

.gap-66 {
  gap: 66px;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto;
}

@media (max-width: 768px) {
  .sm-w-100 {
    width: 100%;
  }

  .pl-sm-20 {
    padding-left: 20px !important;
  }
}

.h-100 {
  height: 100%;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-grow: 1;
  max-width: 100%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3%;
  max-width: 33%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (max-width: 480px) {

  .col,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.shrink-0 {
  flex-shrink: 0 !important;
}

.grow-1 {
  flex-grow: 1 !important;
}

.grid-two-columns {
  grid-template-columns: 1fr 1fr;
}

.grid-detail {

  display: flex;
  gap: 32px;

  &-main {
    flex-basis: calc(70% - 32px);
    max-width: calc(70% - 32px);
    flex-grow: 0;
  }

  &-section {
    flex-basis: calc(40% - 32px);
    max-width: calc(40% - 32px);
    flex-grow: 0;
  }
}