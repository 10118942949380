@import "variables";

.ql-editor {
  color: $color-black-theme;
  font-family: Poppins, serif;
  line-height: 1.5;
}

.roam-editor {
  width: 100%;
  .ql-toolbar {
    border-radius: 6px 6px 0 0;
  }

  .ql-editor {
    min-height: 150px;
    border-radius: 0 0 6px 6px;
  }
}

.quill-editor-compose {
  height: 200px;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid $color-accent-light;
  background: $color-theme-white;

  .ql-editor {
    padding-left: 0.25rem;
    padding-right: 025rem;
  }
}

.ql-editor {
  padding-left: 0!important;
}

.ql-container {
  border-radius: 0 0 8px 8px;
}

.ql-toolbar {
  &.ql-snow {
    border: 1px solid #D6D3D7;
    border-radius: 8px 8px 0 0;
  }
}

