/**
* ====- Just a tailwind alike custom class for vast development ===========
* NOTE: if you want to add new entries here please look into tailwind specs
* why not just install tailwind? because we have many tw similar classes that will results
* in conflict and those have completely diferrent value.
*/

/*
* ================= Display ==============================================
*/

.roam-block {
  display: block;
}

/*
* ================= FLEXBOX & GRID ==============================================
*/

.roam-flex {
  display: flex;
}

// https://tailwindcss.com/docs/flex-basis
.roam-basis-0 {
  flex-basis: 0px;
}
.roam-basis-1 {
  flex-basis: 0.25rem; /* 4px */
}
.roam-basis-2 {
  flex-basis: 0.5rem; /* 8px */
}
.roam-basis-3 {
  flex-basis: 0.75rem; /* 12px */
}
.roam-basis-4 {
  flex-basis: 1rem; /* 16px */
}
.roam-basis-5 {
  flex-basis: 1.25rem; /* 20px */
}
.roam-basis-6 {
  flex-basis: 1.5rem; /* 24px */
}
.roam-basis-7 {
  flex-basis: 1.75rem; /* 28px */
}
.roam-basis-8 {
  flex-basis: 2rem; /* 32px */
}
.roam-basis-9 {
  flex-basis: 2.25rem; /* 36px */
}
.roam-basis-10 {
  flex-basis: 2.5rem; /* 40px */
}
.roam-basis-11 {
  flex-basis: 2.75rem; /* 44px */
}
.roam-basis-12 {
  flex-basis: 3rem; /* 48px */
}
.roam-basis-14 {
  flex-basis: 3.5rem; /* 56px */
}
.roam-basis-16 {
  flex-basis: 4rem; /* 64px */
}
.roam-basis-20 {
  flex-basis: 5rem; /* 80px */
}
.roam-basis-24 {
  flex-basis: 6rem; /* 96px */
}
.roam-basis-28 {
  flex-basis: 7rem; /* 112px */
}
.roam-basis-32 {
  flex-basis: 8rem; /* 128px */
}
.roam-basis-36 {
  flex-basis: 9rem; /* 144px */
}
.roam-basis-40 {
  flex-basis: 10rem; /* 160px */
}
.roam-basis-44 {
  flex-basis: 11rem; /* 176px */
}
.roam-basis-48 {
  flex-basis: 12rem; /* 192px */
}
.roam-basis-52 {
  flex-basis: 13rem; /* 208px */
}
.roam-basis-56 {
  flex-basis: 14rem; /* 224px */
}
.roam-basis-60 {
  flex-basis: 15rem; /* 240px */
}
.roam-basis-64 {
  flex-basis: 16rem; /* 256px */
}
.roam-basis-72 {
  flex-basis: 18rem; /* 288px */
}
.roam-basis-80 {
  flex-basis: 20rem; /* 320px */
}
.roam-basis-96 {
  flex-basis: 24rem; /* 384px */
}
.roam-basis-auto {
  flex-basis: auto;
}
.roam-basis-px {
  flex-basis: 1px;
}
.roam-basis-0\.5 {
  flex-basis: 0.125rem; /* 2px */
}
.roam-basis-1\.5 {
  flex-basis: 0.375rem; /* 6px */
}
.roam-basis-2\.5 {
  flex-basis: 0.625rem; /* 10px */
}
.roam-basis-3\.5 {
  flex-basis: 0.875rem; /* 14px */
}
.roam-basis-1\/2 {
  flex-basis: 50%;
}
.roam-basis-1\/3 {
  flex-basis: 33.333333%;
}
.roam-basis-2\/3 {
  flex-basis: 66.666667%;
}
.roam-basis-1\/4 {
  flex-basis: 25%;
}
.roam-basis-2\/4 {
  flex-basis: 50%;
}
.roam-basis-3\/4 {
  flex-basis: 75%;
}
.roam-basis-1\/5 {
  flex-basis: 20%;
}
.roam-basis-2\/5 {
  flex-basis: 40%;
}
.roam-basis-3\/5 {
  flex-basis: 60%;
}
.roam-basis-4\/5 {
  flex-basis: 80%;
}
.roam-basis-1\/6 {
  flex-basis: 16.666667%;
}
.roam-basis-2\/6 {
  flex-basis: 33.333333%;
}
.roam-basis-3\/6 {
  flex-basis: 50%;
}
.roam-basis-4\/6 {
  flex-basis: 66.666667%;
}
.roam-basis-5\/6 {
  flex-basis: 83.333333%;
}
.roam-basis-1\/12 {
  flex-basis: 8.333333%;
}
.roam-basis-2\/12 {
  flex-basis: 16.666667%;
}
.roam-basis-3\/12 {
  flex-basis: 25%;
}
.roam-basis-4\/12 {
  flex-basis: 33.333333%;
}
.roam-basis-5\/12 {
  flex-basis: 41.666667%;
}
.roam-basis-6\/12 {
  flex-basis: 50%;
}
.roam-basis-7\/12 {
  flex-basis: 58.333333%;
}
.roam-basis-8\/12 {
  flex-basis: 66.666667%;
}
.roam-basis-9\/12 {
  flex-basis: 75%;
}
.roam-basis-10\/12 {
  flex-basis: 83.333333%;
}
.roam-basis-11\/12 {
  flex-basis: 91.666667%;
}
.roam-basis-full {
  flex-basis: 100%;
}

// https://tailwindcss.com/docs/flex-direction
.roam-flex-row {
  flex-direction: row;
}
.roam-flex-row-reverse {
  flex-direction: row-reverse;
}
.roam-flex-col {
  flex-direction: column;
}
.roam-flex-col-reverse {
  flex-direction: column-reverse;
}

// https://tailwindcss.com/docs/flex-wrap
.roam-flex-wrap {
  flex-wrap: wrap;
}
.roam-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.roam-flex-nowrap {
  flex-wrap: nowrap;
}

// https://tailwindcss.com/docs/flex
.roam-flex-1 {
  flex: 1 1 0%;
}
.roam-flex-auto {
  flex: 1 1 auto;
}
.roam-flex-initial {
  flex: 0 1 auto;
}
.roam-flex-none {
  flex: none;
}

// https://tailwindcss.com/docs/flex-grow
.roam-grow {
  flex-grow: 1;
}
.roam-grow-0 {
  flex-grow: 0;
}

// https://tailwindcss.com/docs/flex-shrink
.roam-shrink {
  flex-shrink: 1;
}
.roam-shrink-0 {
  flex-shrink: 0;
}

// https://tailwindcss.com/docs/order
.roam-order-1 {
  order: 1;
}
.roam-order-2 {
  order: 2;
}
.roam-order-3 {
  order: 3;
}
.roam-order-4 {
  order: 4;
}
.roam-order-5 {
  order: 5;
}
.roam-order-6 {
  order: 6;
}
.roam-order-7 {
  order: 7;
}
.roam-order-8 {
  order: 8;
}
.roam-order-9 {
  order: 9;
}
.roam-order-10 {
  order: 10;
}
.roam-order-11 {
  order: 11;
}
.roam-order-12 {
  order: 12;
}
.roam-order-first {
  order: -9999;
}
.roam-order-last {
  order: 9999;
}
.roam-order-none {
  order: 0;
}

// https://tailwindcss.com/docs/grid-template-columns
.roam-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.roam-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.roam-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.roam-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.roam-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.roam-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.roam-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.roam-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.roam-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.roam-grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}
.roam-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}
.roam-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.roam-grid-cols-none {
  grid-template-columns: none;
}
.roam-grid-cols-subgrid {
  grid-template-columns: subgrid;
}

// https://tailwindcss.com/docs/grid-column
.roam-col-auto {
  grid-column: auto;
}
.roam-col-span-1 {
  grid-column: span 1 / span 1;
}
.roam-col-span-2 {
  grid-column: span 2 / span 2;
}
.roam-col-span-3 {
  grid-column: span 3 / span 3;
}
.roam-col-span-4 {
  grid-column: span 4 / span 4;
}
.roam-col-span-5 {
  grid-column: span 5 / span 5;
}
.roam-col-span-6 {
  grid-column: span 6 / span 6;
}
.roam-col-span-7 {
  grid-column: span 7 / span 7;
}
.roam-col-span-8 {
  grid-column: span 8 / span 8;
}
.roam-col-span-9 {
  grid-column: span 9 / span 9;
}
.roam-col-span-10 {
  grid-column: span 10 / span 10;
}
.roam-col-span-11 {
  grid-column: span 11 / span 11;
}
.roam-col-span-12 {
  grid-column: span 12 / span 12;
}
.roam-col-span-full {
  grid-column: 1 / -1;
}
.roam-col-start-1 {
  grid-column-start: 1;
}
.roam-col-start-2 {
  grid-column-start: 2;
}
.roam-col-start-3 {
  grid-column-start: 3;
}
.roam-col-start-4 {
  grid-column-start: 4;
}
.roam-col-start-5 {
  grid-column-start: 5;
}
.roam-col-start-6 {
  grid-column-start: 6;
}
.roam-col-start-7 {
  grid-column-start: 7;
}
.roam-col-start-8 {
  grid-column-start: 8;
}
.roam-col-start-9 {
  grid-column-start: 9;
}
.roam-col-start-10 {
  grid-column-start: 10;
}
.roam-col-start-11 {
  grid-column-start: 11;
}
.roam-col-start-12 {
  grid-column-start: 12;
}
.roam-col-start-13 {
  grid-column-start: 13;
}
.roam-col-start-auto {
  grid-column-start: auto;
}
.roam-col-end-1 {
  grid-column-end: 1;
}
.roam-col-end-2 {
  grid-column-end: 2;
}
.roam-col-end-3 {
  grid-column-end: 3;
}
.roam-col-end-4 {
  grid-column-end: 4;
}
.roam-col-end-5 {
  grid-column-end: 5;
}
.roam-col-end-6 {
  grid-column-end: 6;
}
.roam-col-end-7 {
  grid-column-end: 7;
}
.roam-col-end-8 {
  grid-column-end: 8;
}
.roam-col-end-9 {
  grid-column-end: 9;
}
.roam-col-end-10 {
  grid-column-end: 10;
}
.roam-col-end-11 {
  grid-column-end: 11;
}
.roam-col-end-12 {
  grid-column-end: 12;
}
.roam-col-end-13 {
  grid-column-end: 13;
}
.roam-col-end-auto {
  grid-column-end: auto;
}

// https://tailwindcss.com/docs/grid-template-rows
.roam-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}
.roam-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
.roam-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}
.roam-grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}
.roam-grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}
.roam-grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}
.roam-grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}
.roam-grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}
.roam-grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}
.roam-grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr));
}
.roam-grid-rows-11 {
  grid-template-rows: repeat(11, minmax(0, 1fr));
}
.roam-grid-rows-12 {
  grid-template-rows: repeat(12, minmax(0, 1fr));
}
.roam-grid-rows-none {
  grid-template-rows: none;
}
.roam-grid-rows-subgrid {
  grid-template-rows: subgrid;
}

// https://tailwindcss.com/docs/grid-row
.roam-row-auto {
  grid-row: auto;
}
.roam-row-span-1 {
  grid-row: span 1 / span 1;
}
.roam-row-span-2 {
  grid-row: span 2 / span 2;
}
.roam-row-span-3 {
  grid-row: span 3 / span 3;
}
.roam-row-span-4 {
  grid-row: span 4 / span 4;
}
.roam-row-span-5 {
  grid-row: span 5 / span 5;
}
.roam-row-span-6 {
  grid-row: span 6 / span 6;
}
.roam-row-span-7 {
  grid-row: span 7 / span 7;
}
.roam-row-span-8 {
  grid-row: span 8 / span 8;
}
.roam-row-span-9 {
  grid-row: span 9 / span 9;
}
.roam-row-span-10 {
  grid-row: span 10 / span 10;
}
.roam-row-span-11 {
  grid-row: span 11 / span 11;
}
.roam-row-span-12 {
  grid-row: span 12 / span 12;
}
.roam-row-span-full {
  grid-row: 1 / -1;
}
.roam-row-start-1 {
  grid-row-start: 1;
}
.roam-row-start-2 {
  grid-row-start: 2;
}
.roam-row-start-3 {
  grid-row-start: 3;
}
.roam-row-start-4 {
  grid-row-start: 4;
}
.roam-row-start-5 {
  grid-row-start: 5;
}
.roam-row-start-6 {
  grid-row-start: 6;
}
.roam-row-start-7 {
  grid-row-start: 7;
}
.roam-row-start-8 {
  grid-row-start: 8;
}
.roam-row-start-9 {
  grid-row-start: 9;
}
.roam-row-start-10 {
  grid-row-start: 10;
}
.roam-row-start-11 {
  grid-row-start: 11;
}
.roam-row-start-12 {
  grid-row-start: 12;
}
.roam-row-start-13 {
  grid-row-start: 13;
}
.roam-row-start-auto {
  grid-row-start: auto;
}
.roam-row-end-1 {
  grid-row-end: 1;
}
.roam-row-end-2 {
  grid-row-end: 2;
}
.roam-row-end-3 {
  grid-row-end: 3;
}
.roam-row-end-4 {
  grid-row-end: 4;
}
.roam-row-end-5 {
  grid-row-end: 5;
}
.roam-row-end-6 {
  grid-row-end: 6;
}
.roam-row-end-7 {
  grid-row-end: 7;
}
.roam-row-end-8 {
  grid-row-end: 8;
}
.roam-row-end-9 {
  grid-row-end: 9;
}
.roam-row-end-10 {
  grid-row-end: 10;
}
.roam-row-end-11 {
  grid-row-end: 11;
}
.roam-row-end-12 {
  grid-row-end: 12;
}
.roam-row-end-13 {
  grid-row-end: 13;
}
.roam-row-end-auto {
  grid-row-end: auto;
}

// https://tailwindcss.com/docs/grid-auto-flow
.roam-grid-flow-row {
  grid-auto-flow: row;
}
.roam-grid-flow-col {
  grid-auto-flow: column;
}
.roam-grid-flow-dense {
  grid-auto-flow: dense;
}
.roam-grid-flow-row-dense {
  grid-auto-flow: row dense;
}
.roam-grid-flow-col-dense {
  grid-auto-flow: column dense;
}

// https://tailwindcss.com/docs/grid-auto-columns
.roam-auto-cols-auto {
  grid-auto-columns: auto;
}
.roam-auto-cols-min {
  grid-auto-columns: min-content;
}
.roam-auto-cols-max {
  grid-auto-columns: max-content;
}
.roam-auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

// https://tailwindcss.com/docs/grid-auto-rows
.roam-auto-rows-auto {
  grid-auto-rows: auto;
}
.roam-auto-rows-min {
  grid-auto-rows: min-content;
}
.roam-auto-rows-max {
  grid-auto-rows: max-content;
}
.roam-auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.roam-items-center {
  align-items: center;
}

// https://tailwindcss.com/docs/gap
.roam-gap-0 {
  gap: 0px;
}
.roam-gap-x-0 {
  column-gap: 0px;
}
.roam-gap-y-0 {
  row-gap: 0px;
}
.roam-gap-px {
  gap: 1px;
}
.roam-gap-x-px {
  column-gap: 1px;
}
.roam-gap-y-px {
  row-gap: 1px;
}
.roam-gap-0\.5 {
  gap: 0.125rem; /* 2px */
}
.roam-gap-x-0\.5 {
  column-gap: 0.125rem; /* 2px */
}
.roam-gap-y-0\.5 {
  row-gap: 0.125rem; /* 2px */
}
.roam-gap-1 {
  gap: 0.25rem; /* 4px */
}
.roam-gap-x-1 {
  column-gap: 0.25rem; /* 4px */
}
.roam-gap-y-1 {
  row-gap: 0.25rem; /* 4px */
}
.roam-gap-1\.5 {
  gap: 0.375rem; /* 6px */
}
.roam-gap-x-1\.5 {
  column-gap: 0.375rem; /* 6px */
}
.roam-gap-y-1\.5 {
  row-gap: 0.375rem; /* 6px */
}
.roam-gap-2 {
  gap: 0.5rem; /* 8px */
}
.roam-gap-x-2 {
  column-gap: 0.5rem; /* 8px */
}
.roam-gap-y-2 {
  row-gap: 0.5rem; /* 8px */
}
.roam-gap-2\.5 {
  gap: 0.625rem; /* 10px */
}
.roam-gap-x-2\.5 {
  column-gap: 0.625rem; /* 10px */
}
.roam-gap-y-2\.5 {
  row-gap: 0.625rem; /* 10px */
}
.roam-gap-3 {
  gap: 0.75rem; /* 12px */
}
.roam-gap-x-3 {
  column-gap: 0.75rem; /* 12px */
}
.roam-gap-y-3 {
  row-gap: 0.75rem; /* 12px */
}
.roam-gap-3\.5 {
  gap: 0.875rem; /* 14px */
}
.roam-gap-x-3\.5 {
  column-gap: 0.875rem; /* 14px */
}
.roam-gap-y-3\.5 {
  row-gap: 0.875rem; /* 14px */
}
.roam-gap-4 {
  gap: 1rem; /* 16px */
}
.roam-gap-x-4 {
  column-gap: 1rem; /* 16px */
}
.roam-gap-y-4 {
  row-gap: 1rem; /* 16px */
}
.roam-gap-5 {
  gap: 1.25rem; /* 20px */
}
.roam-gap-x-5 {
  column-gap: 1.25rem; /* 20px */
}
.roam-gap-y-5 {
  row-gap: 1.25rem; /* 20px */
}
.roam-gap-6 {
  gap: 1.5rem; /* 24px */
}
.roam-gap-x-6 {
  column-gap: 1.5rem; /* 24px */
}
.roam-gap-y-6 {
  row-gap: 1.5rem; /* 24px */
}
.roam-gap-7 {
  gap: 1.75rem; /* 28px */
}
.roam-gap-x-7 {
  column-gap: 1.75rem; /* 28px */
}
.roam-gap-y-7 {
  row-gap: 1.75rem; /* 28px */
}
.roam-gap-8 {
  gap: 2rem; /* 32px */
}
.roam-gap-x-8 {
  column-gap: 2rem; /* 32px */
}
.roam-gap-y-8 {
  row-gap: 2rem; /* 32px */
}
.roam-gap-9 {
  gap: 2.25rem; /* 36px */
}
.roam-gap-x-9 {
  column-gap: 2.25rem; /* 36px */
}
.roam-gap-y-9 {
  row-gap: 2.25rem; /* 36px */
}
.roam-gap-10 {
  gap: 2.5rem; /* 40px */
}
.roam-gap-x-10 {
  column-gap: 2.5rem; /* 40px */
}
.roam-gap-y-10 {
  row-gap: 2.5rem; /* 40px */
}
.roam-gap-11 {
  gap: 2.75rem; /* 44px */
}
.roam-gap-x-11 {
  column-gap: 2.75rem; /* 44px */
}
.roam-gap-y-11 {
  row-gap: 2.75rem; /* 44px */
}
.roam-gap-12 {
  gap: 3rem; /* 48px */
}
.roam-gap-x-12 {
  column-gap: 3rem; /* 48px */
}
.roam-gap-y-12 {
  row-gap: 3rem; /* 48px */
}
.roam-gap-14 {
  gap: 3.5rem; /* 56px */
}
.roam-gap-x-14 {
  column-gap: 3.5rem; /* 56px */
}
.roam-gap-y-14 {
  row-gap: 3.5rem; /* 56px */
}
.roam-gap-16 {
  gap: 4rem; /* 64px */
}
.roam-gap-x-16 {
  column-gap: 4rem; /* 64px */
}
.roam-gap-y-16 {
  row-gap: 4rem; /* 64px */
}
.roam-gap-20 {
  gap: 5rem; /* 80px */
}
.roam-gap-x-20 {
  column-gap: 5rem; /* 80px */
}
.roam-gap-y-20 {
  row-gap: 5rem; /* 80px */
}
.roam-gap-24 {
  gap: 6rem; /* 96px */
}
.roam-gap-x-24 {
  column-gap: 6rem; /* 96px */
}
.roam-gap-y-24 {
  row-gap: 6rem; /* 96px */
}
.roam-gap-28 {
  gap: 7rem; /* 112px */
}
.roam-gap-x-28 {
  column-gap: 7rem; /* 112px */
}
.roam-gap-y-28 {
  row-gap: 7rem; /* 112px */
}
.roam-gap-32 {
  gap: 8rem; /* 128px */
}
.roam-gap-x-32 {
  column-gap: 8rem; /* 128px */
}
.roam-gap-y-32 {
  row-gap: 8rem; /* 128px */
}
.roam-gap-36 {
  gap: 9rem; /* 144px */
}
.roam-gap-x-36 {
  column-gap: 9rem; /* 144px */
}
.roam-gap-y-36 {
  row-gap: 9rem; /* 144px */
}
.roam-gap-40 {
  gap: 10rem; /* 160px */
}
.roam-gap-x-40 {
  column-gap: 10rem; /* 160px */
}
.roam-gap-y-40 {
  row-gap: 10rem; /* 160px */
}
.roam-gap-44 {
  gap: 11rem; /* 176px */
}
.roam-gap-x-44 {
  column-gap: 11rem; /* 176px */
}
.roam-gap-y-44 {
  row-gap: 11rem; /* 176px */
}
.roam-gap-48 {
  gap: 12rem; /* 192px */
}
.roam-gap-x-48 {
  column-gap: 12rem; /* 192px */
}
.roam-gap-y-48 {
  row-gap: 12rem; /* 192px */
}
.roam-gap-52 {
  gap: 13rem; /* 208px */
}
.roam-gap-x-52 {
  column-gap: 13rem; /* 208px */
}
.roam-gap-y-52 {
  row-gap: 13rem; /* 208px */
}
.roam-gap-56 {
  gap: 14rem; /* 224px */
}
.roam-gap-x-56 {
  column-gap: 14rem; /* 224px */
}
.roam-gap-y-56 {
  row-gap: 14rem; /* 224px */
}
.roam-gap-60 {
  gap: 15rem; /* 240px */
}
.roam-gap-x-60 {
  column-gap: 15rem; /* 240px */
}
.roam-gap-y-60 {
  row-gap: 15rem; /* 240px */
}
.roam-gap-64 {
  gap: 16rem; /* 256px */
}
.roam-gap-x-64 {
  column-gap: 16rem; /* 256px */
}
.roam-gap-y-64 {
  row-gap: 16rem; /* 256px */
}
.roam-gap-72 {
  gap: 18rem; /* 288px */
}
.roam-gap-x-72 {
  column-gap: 18rem; /* 288px */
}
.roam-gap-y-72 {
  row-gap: 18rem; /* 288px */
}
.roam-gap-80 {
  gap: 20rem; /* 320px */
}
.roam-gap-x-80 {
  column-gap: 20rem; /* 320px */
}
.roam-gap-y-80 {
  row-gap: 20rem; /* 320px */
}
.roam-gap-96 {
  gap: 24rem; /* 384px */
}
.roam-gap-x-96 {
  column-gap: 24rem; /* 384px */
}
.roam-gap-y-96 {
  row-gap: 24rem; /* 384px */
}

// https://tailwindcss.com/docs/justify-content
.roam-justify-normal {
  justify-content: normal;
}
.roam-justify-start {
  justify-content: flex-start;
}
.roam-justify-end {
  justify-content: flex-end;
}
.roam-justify-center {
  justify-content: center;
}
.roam-justify-between {
  justify-content: space-between;
}
.roam-justify-around {
  justify-content: space-around;
}
.roam-justify-evenly {
  justify-content: space-evenly;
}
.roam-justify-stretch {
  justify-content: stretch;
}

// https://tailwindcss.com/docs/justify-items
.roam-justify-items-start {
  justify-items: start;
}
.roam-justify-items-end {
  justify-items: end;
}
.roam-justify-items-center {
  justify-items: center;
}
.roam-justify-items-stretch {
  justify-items: stretch;
}

// https://tailwindcss.com/docs/justify-self
.roam-justify-self-auto {
  justify-self: auto;
}
.roam-justify-self-start {
  justify-self: start;
}
.roam-justify-self-end {
  justify-self: end;
}
.roam-justify-self-center {
  justify-self: center;
}
.roam-justify-self-stretch {
  justify-self: stretch;
}

// https://tailwindcss.com/docs/align-content
.roam-content-normal {
  align-content: normal;
}
.roam-content-center {
  align-content: center;
}
.roam-content-start {
  align-content: flex-start;
}
.roam-content-end {
  align-content: flex-end;
}
.roam-content-between {
  align-content: space-between;
}
.roam-content-around {
  align-content: space-around;
}
.roam-content-evenly {
  align-content: space-evenly;
}
.roam-content-baseline {
  align-content: baseline;
}
.roam-content-stretch {
  align-content: stretch;
}

// https://tailwindcss.com/docs/align-items
.roam-items-start {
  align-items: flex-start;
}
.roam-items-end {
  align-items: flex-end;
}
.roam-items-center {
  align-items: center;
}
.roam-items-baseline {
  align-items: baseline;
}
.roam-items-stretch {
  align-items: stretch;
}

// https://tailwindcss.com/docs/align-self
.roam-self-auto {
  align-self: auto;
}
.roam-self-start {
  align-self: flex-start;
}
.roam-self-end {
  align-self: flex-end;
}
.roam-self-center {
  align-self: center;
}
.roam-self-stretch {
  align-self: stretch;
}
.roam-self-baseline {
  align-self: baseline;
}

// https://tailwindcss.com/docs/place-content
.roam-place-content-center {
  place-content: center;
}
.roam-place-content-start {
  place-content: start;
}
.roam-place-content-end {
  place-content: end;
}
.roam-place-content-between {
  place-content: space-between;
}
.roam-place-content-around {
  place-content: space-around;
}
.roam-place-content-evenly {
  place-content: space-evenly;
}
.roam-place-content-baseline {
  place-content: baseline;
}
.roam-place-content-stretch {
  place-content: stretch;
}

// https://tailwindcss.com/docs/place-items
.roam-place-items-start {
  place-items: start;
}
.roam-place-items-end {
  place-items: end;
}
.roam-place-items-center {
  place-items: center;
}
.roam-place-items-baseline {
  place-items: baseline;
}
.roam-place-items-stretch {
  place-items: stretch;
}

// https://tailwindcss.com/docs/place-self
.roam-place-self-auto {
  place-self: auto;
}
.roam-place-self-start {
  place-self: start;
}
.roam-place-self-end {
  place-self: end;
}
.roam-place-self-center {
  place-self: center;
}
.roam-place-self-stretch {
  place-self: stretch;
}

/*
* ================= SPACING ==============================================
*/

// https://tailwindcss.com/docs/padding
.roam-p-0 {
  padding: 0px;
}
.roam-px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.roam-py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.roam-ps-0 {
  padding-inline-start: 0px;
}
.roam-pe-0 {
  padding-inline-end: 0px;
}
.roam-pt-0 {
  padding-top: 0px;
}
.roam-pr-0 {
  padding-right: 0px;
}
.roam-pb-0 {
  padding-bottom: 0px;
}
.roam-pl-0 {
  padding-left: 0px;
}
.roam-p-px {
  padding: 1px;
}
.roam-px-px {
  padding-left: 1px;
  padding-right: 1px;
}
.roam-py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}
.roam-ps-px {
  padding-inline-start: 1px;
}
.roam-pe-px {
  padding-inline-end: 1px;
}
.roam-pt-px {
  padding-top: 1px;
}
.roam-pr-px {
  padding-right: 1px;
}
.roam-pb-px {
  padding-bottom: 1px;
}
.roam-pl-px {
  padding-left: 1px;
}
.roam-p-0\.5 {
  padding: 0.125rem; /* 2px */
}
.roam-px-0\.5 {
  padding-left: 0.125rem; /* 2px */
  padding-right: 0.125rem; /* 2px */
}
.roam-py-0\.5 {
  padding-top: 0.125rem; /* 2px */
  padding-bottom: 0.125rem; /* 2px */
}
.roam-ps-0\.5 {
  padding-inline-start: 0.125rem; /* 2px */
}
.roam-pe-0\.5 {
  padding-inline-end: 0.125rem; /* 2px */
}
.roam-pt-0\.5 {
  padding-top: 0.125rem; /* 2px */
}
.roam-pr-0\.5 {
  padding-right: 0.125rem; /* 2px */
}
.roam-pb-0\.5 {
  padding-bottom: 0.125rem; /* 2px */
}
.roam-pl-0\.5 {
  padding-left: 0.125rem; /* 2px */
}
.roam-p-1 {
  padding: 0.25rem; /* 4px */
}
.roam-px-1 {
  padding-left: 0.25rem; /* 4px */
  padding-right: 0.25rem; /* 4px */
}
.roam-py-1 {
  padding-top: 0.25rem; /* 4px */
  padding-bottom: 0.25rem; /* 4px */
}
.roam-ps-1 {
  padding-inline-start: 0.25rem; /* 4px */
}
.roam-pe-1 {
  padding-inline-end: 0.25rem; /* 4px */
}
.roam-pt-1 {
  padding-top: 0.25rem; /* 4px */
}
.roam-pr-1 {
  padding-right: 0.25rem; /* 4px */
}
.roam-pb-1 {
  padding-bottom: 0.25rem; /* 4px */
}
.roam-pl-1 {
  padding-left: 0.25rem; /* 4px */
}
.roam-p-1\.5 {
  padding: 0.375rem; /* 6px */
}
.roam-px-1\.5 {
  padding-left: 0.375rem; /* 6px */
  padding-right: 0.375rem; /* 6px */
}
.roam-py-1\.5 {
  padding-top: 0.375rem; /* 6px */
  padding-bottom: 0.375rem; /* 6px */
}
.roam-ps-1\.5 {
  padding-inline-start: 0.375rem; /* 6px */
}
.roam-pe-1\.5 {
  padding-inline-end: 0.375rem; /* 6px */
}
.roam-pt-1\.5 {
  padding-top: 0.375rem; /* 6px */
}
.roam-pr-1\.5 {
  padding-right: 0.375rem; /* 6px */
}
.roam-pb-1\.5 {
  padding-bottom: 0.375rem; /* 6px */
}
.roam-pl-1\.5 {
  padding-left: 0.375rem; /* 6px */
}
.roam-p-2 {
  padding: 0.5rem; /* 8px */
}
.roam-px-2 {
  padding-left: 0.5rem; /* 8px */
  padding-right: 0.5rem; /* 8px */
}
.roam-py-2 {
  padding-top: 0.5rem; /* 8px */
  padding-bottom: 0.5rem; /* 8px */
}
.roam-ps-2 {
  padding-inline-start: 0.5rem; /* 8px */
}
.roam-pe-2 {
  padding-inline-end: 0.5rem; /* 8px */
}
.roam-pt-2 {
  padding-top: 0.5rem; /* 8px */
}
.roam-pr-2 {
  padding-right: 0.5rem; /* 8px */
}
.roam-pb-2 {
  padding-bottom: 0.5rem; /* 8px */
}
.roam-pl-2 {
  padding-left: 0.5rem; /* 8px */
}
.roam-p-2\.5 {
  padding: 0.625rem; /* 10px */
}
.roam-px-2\.5 {
  padding-left: 0.625rem; /* 10px */
  padding-right: 0.625rem; /* 10px */
}
.roam-py-2\.5 {
  padding-top: 0.625rem; /* 10px */
  padding-bottom: 0.625rem; /* 10px */
}
.roam-ps-2\.5 {
  padding-inline-start: 0.625rem; /* 10px */
}
.roam-pe-2\.5 {
  padding-inline-end: 0.625rem; /* 10px */
}
.roam-pt-2\.5 {
  padding-top: 0.625rem; /* 10px */
}
.roam-pr-2\.5 {
  padding-right: 0.625rem; /* 10px */
}
.roam-pb-2\.5 {
  padding-bottom: 0.625rem; /* 10px */
}
.roam-pl-2\.5 {
  padding-left: 0.625rem; /* 10px */
}
.roam-p-3 {
  padding: 0.75rem; /* 12px */
}
.roam-px-3 {
  padding-left: 0.75rem; /* 12px */
  padding-right: 0.75rem; /* 12px */
}
.roam-py-3 {
  padding-top: 0.75rem; /* 12px */
  padding-bottom: 0.75rem; /* 12px */
}
.roam-ps-3 {
  padding-inline-start: 0.75rem; /* 12px */
}
.roam-pe-3 {
  padding-inline-end: 0.75rem; /* 12px */
}
.roam-pt-3 {
  padding-top: 0.75rem; /* 12px */
}
.roam-pr-3 {
  padding-right: 0.75rem; /* 12px */
}
.roam-pb-3 {
  padding-bottom: 0.75rem; /* 12px */
}
.roam-pl-3 {
  padding-left: 0.75rem; /* 12px */
}
.roam-p-3\.5 {
  padding: 0.875rem; /* 14px */
}
.roam-px-3\.5 {
  padding-left: 0.875rem; /* 14px */
  padding-right: 0.875rem; /* 14px */
}
.roam-py-3\.5 {
  padding-top: 0.875rem; /* 14px */
  padding-bottom: 0.875rem; /* 14px */
}
.roam-ps-3\.5 {
  padding-inline-start: 0.875rem; /* 14px */
}
.roam-pe-3\.5 {
  padding-inline-end: 0.875rem; /* 14px */
}
.roam-pt-3\.5 {
  padding-top: 0.875rem; /* 14px */
}
.roam-pr-3\.5 {
  padding-right: 0.875rem; /* 14px */
}
.roam-pb-3\.5 {
  padding-bottom: 0.875rem; /* 14px */
}
.roam-pl-3\.5 {
  padding-left: 0.875rem; /* 14px */
}
.roam-p-4 {
  padding: 1rem; /* 16px */
}
.roam-px-4 {
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
}
.roam-py-4 {
  padding-top: 1rem; /* 16px */
  padding-bottom: 1rem; /* 16px */
}
.roam-ps-4 {
  padding-inline-start: 1rem; /* 16px */
}
.roam-pe-4 {
  padding-inline-end: 1rem; /* 16px */
}
.roam-pt-4 {
  padding-top: 1rem; /* 16px */
}
.roam-pr-4 {
  padding-right: 1rem; /* 16px */
}
.roam-pb-4 {
  padding-bottom: 1rem; /* 16px */
}
.roam-pl-4 {
  padding-left: 1rem; /* 16px */
}
.roam-p-5 {
  padding: 1.25rem; /* 20px */
}
.roam-px-5 {
  padding-left: 1.25rem; /* 20px */
  padding-right: 1.25rem; /* 20px */
}
.roam-py-5 {
  padding-top: 1.25rem; /* 20px */
  padding-bottom: 1.25rem; /* 20px */
}
.roam-ps-5 {
  padding-inline-start: 1.25rem; /* 20px */
}
.roam-pe-5 {
  padding-inline-end: 1.25rem; /* 20px */
}
.roam-pt-5 {
  padding-top: 1.25rem; /* 20px */
}
.roam-pr-5 {
  padding-right: 1.25rem; /* 20px */
}
.roam-pb-5 {
  padding-bottom: 1.25rem; /* 20px */
}
.roam-pl-5 {
  padding-left: 1.25rem; /* 20px */
}
.roam-p-6 {
  padding: 1.5rem; /* 24px */
}
.roam-px-6 {
  padding-left: 1.5rem; /* 24px */
  padding-right: 1.5rem; /* 24px */
}
.roam-py-6 {
  padding-top: 1.5rem; /* 24px */
  padding-bottom: 1.5rem; /* 24px */
}
.roam-ps-6 {
  padding-inline-start: 1.5rem; /* 24px */
}
.roam-pe-6 {
  padding-inline-end: 1.5rem; /* 24px */
}
.roam-pt-6 {
  padding-top: 1.5rem; /* 24px */
}
.roam-pr-6 {
  padding-right: 1.5rem; /* 24px */
}
.roam-pb-6 {
  padding-bottom: 1.5rem; /* 24px */
}
.roam-pl-6 {
  padding-left: 1.5rem; /* 24px */
}
.roam-p-7 {
  padding: 1.75rem; /* 28px */
}
.roam-px-7 {
  padding-left: 1.75rem; /* 28px */
  padding-right: 1.75rem; /* 28px */
}
.roam-py-7 {
  padding-top: 1.75rem; /* 28px */
  padding-bottom: 1.75rem; /* 28px */
}
.roam-ps-7 {
  padding-inline-start: 1.75rem; /* 28px */
}
.roam-pe-7 {
  padding-inline-end: 1.75rem; /* 28px */
}
.roam-pt-7 {
  padding-top: 1.75rem; /* 28px */
}
.roam-pr-7 {
  padding-right: 1.75rem; /* 28px */
}
.roam-pb-7 {
  padding-bottom: 1.75rem; /* 28px */
}
.roam-pl-7 {
  padding-left: 1.75rem; /* 28px */
}
.roam-p-8 {
  padding: 2rem; /* 32px */
}
.roam-px-8 {
  padding-left: 2rem; /* 32px */
  padding-right: 2rem; /* 32px */
}
.roam-py-8 {
  padding-top: 2rem; /* 32px */
  padding-bottom: 2rem; /* 32px */
}
.roam-ps-8 {
  padding-inline-start: 2rem; /* 32px */
}
.roam-pe-8 {
  padding-inline-end: 2rem; /* 32px */
}
.roam-pt-8 {
  padding-top: 2rem; /* 32px */
}
.roam-pr-8 {
  padding-right: 2rem; /* 32px */
}
.roam-pb-8 {
  padding-bottom: 2rem; /* 32px */
}
.roam-pl-8 {
  padding-left: 2rem; /* 32px */
}
.roam-p-9 {
  padding: 2.25rem; /* 36px */
}
.roam-px-9 {
  padding-left: 2.25rem; /* 36px */
  padding-right: 2.25rem; /* 36px */
}
.roam-py-9 {
  padding-top: 2.25rem; /* 36px */
  padding-bottom: 2.25rem; /* 36px */
}
.roam-ps-9 {
  padding-inline-start: 2.25rem; /* 36px */
}
.roam-pe-9 {
  padding-inline-end: 2.25rem; /* 36px */
}
.roam-pt-9 {
  padding-top: 2.25rem; /* 36px */
}
.roam-pr-9 {
  padding-right: 2.25rem; /* 36px */
}
.roam-pb-9 {
  padding-bottom: 2.25rem; /* 36px */
}
.roam-pl-9 {
  padding-left: 2.25rem; /* 36px */
}
.roam-p-10 {
  padding: 2.5rem; /* 40px */
}
.roam-px-10 {
  padding-left: 2.5rem; /* 40px */
  padding-right: 2.5rem; /* 40px */
}
.roam-py-10 {
  padding-top: 2.5rem; /* 40px */
  padding-bottom: 2.5rem; /* 40px */
}
.roam-ps-10 {
  padding-inline-start: 2.5rem; /* 40px */
}
.roam-pe-10 {
  padding-inline-end: 2.5rem; /* 40px */
}
.roam-pt-10 {
  padding-top: 2.5rem; /* 40px */
}
.roam-pr-10 {
  padding-right: 2.5rem; /* 40px */
}
.roam-pb-10 {
  padding-bottom: 2.5rem; /* 40px */
}
.roam-pl-10 {
  padding-left: 2.5rem; /* 40px */
}
.roam-p-11 {
  padding: 2.75rem; /* 44px */
}
.roam-px-11 {
  padding-left: 2.75rem; /* 44px */
  padding-right: 2.75rem; /* 44px */
}
.roam-py-11 {
  padding-top: 2.75rem; /* 44px */
  padding-bottom: 2.75rem; /* 44px */
}
.roam-ps-11 {
  padding-inline-start: 2.75rem; /* 44px */
}
.roam-pe-11 {
  padding-inline-end: 2.75rem; /* 44px */
}
.roam-pt-11 {
  padding-top: 2.75rem; /* 44px */
}
.roam-pr-11 {
  padding-right: 2.75rem; /* 44px */
}
.roam-pb-11 {
  padding-bottom: 2.75rem; /* 44px */
}
.roam-pl-11 {
  padding-left: 2.75rem; /* 44px */
}
.roam-p-12 {
  padding: 3rem; /* 48px */
}
.roam-px-12 {
  padding-left: 3rem; /* 48px */
  padding-right: 3rem; /* 48px */
}
.roam-py-12 {
  padding-top: 3rem; /* 48px */
  padding-bottom: 3rem; /* 48px */
}
.roam-ps-12 {
  padding-inline-start: 3rem; /* 48px */
}
.roam-pe-12 {
  padding-inline-end: 3rem; /* 48px */
}
.roam-pt-12 {
  padding-top: 3rem; /* 48px */
}
.roam-pr-12 {
  padding-right: 3rem; /* 48px */
}
.roam-pb-12 {
  padding-bottom: 3rem; /* 48px */
}
.roam-pl-12 {
  padding-left: 3rem; /* 48px */
}
.roam-p-14 {
  padding: 3.5rem; /* 56px */
}
.roam-px-14 {
  padding-left: 3.5rem; /* 56px */
  padding-right: 3.5rem; /* 56px */
}
.roam-py-14 {
  padding-top: 3.5rem; /* 56px */
  padding-bottom: 3.5rem; /* 56px */
}
.roam-ps-14 {
  padding-inline-start: 3.5rem; /* 56px */
}
.roam-pe-14 {
  padding-inline-end: 3.5rem; /* 56px */
}
.roam-pt-14 {
  padding-top: 3.5rem; /* 56px */
}
.roam-pr-14 {
  padding-right: 3.5rem; /* 56px */
}
.roam-pb-14 {
  padding-bottom: 3.5rem; /* 56px */
}
.roam-pl-14 {
  padding-left: 3.5rem; /* 56px */
}
.roam-p-16 {
  padding: 4rem; /* 64px */
}
.roam-px-16 {
  padding-left: 4rem; /* 64px */
  padding-right: 4rem; /* 64px */
}
.roam-py-16 {
  padding-top: 4rem; /* 64px */
  padding-bottom: 4rem; /* 64px */
}
.roam-ps-16 {
  padding-inline-start: 4rem; /* 64px */
}
.roam-pe-16 {
  padding-inline-end: 4rem; /* 64px */
}
.roam-pt-16 {
  padding-top: 4rem; /* 64px */
}
.roam-pr-16 {
  padding-right: 4rem; /* 64px */
}
.roam-pb-16 {
  padding-bottom: 4rem; /* 64px */
}
.roam-pl-16 {
  padding-left: 4rem; /* 64px */
}
.roam-p-20 {
  padding: 5rem; /* 80px */
}
.roam-px-20 {
  padding-left: 5rem; /* 80px */
  padding-right: 5rem; /* 80px */
}
.roam-py-20 {
  padding-top: 5rem; /* 80px */
  padding-bottom: 5rem; /* 80px */
}
.roam-ps-20 {
  padding-inline-start: 5rem; /* 80px */
}
.roam-pe-20 {
  padding-inline-end: 5rem; /* 80px */
}
.roam-pt-20 {
  padding-top: 5rem; /* 80px */
}
.roam-pr-20 {
  padding-right: 5rem; /* 80px */
}
.roam-pb-20 {
  padding-bottom: 5rem; /* 80px */
}
.roam-pl-20 {
  padding-left: 5rem; /* 80px */
}
.roam-p-24 {
  padding: 6rem; /* 96px */
}
.roam-px-24 {
  padding-left: 6rem; /* 96px */
  padding-right: 6rem; /* 96px */
}
.roam-py-24 {
  padding-top: 6rem; /* 96px */
  padding-bottom: 6rem; /* 96px */
}
.roam-ps-24 {
  padding-inline-start: 6rem; /* 96px */
}
.roam-pe-24 {
  padding-inline-end: 6rem; /* 96px */
}
.roam-pt-24 {
  padding-top: 6rem; /* 96px */
}
.roam-pr-24 {
  padding-right: 6rem; /* 96px */
}
.roam-pb-24 {
  padding-bottom: 6rem; /* 96px */
}
.roam-pl-24 {
  padding-left: 6rem; /* 96px */
}
.roam-p-28 {
  padding: 7rem; /* 112px */
}
.roam-px-28 {
  padding-left: 7rem; /* 112px */
  padding-right: 7rem; /* 112px */
}
.roam-py-28 {
  padding-top: 7rem; /* 112px */
  padding-bottom: 7rem; /* 112px */
}
.roam-ps-28 {
  padding-inline-start: 7rem; /* 112px */
}
.roam-pe-28 {
  padding-inline-end: 7rem; /* 112px */
}
.roam-pt-28 {
  padding-top: 7rem; /* 112px */
}
.roam-pr-28 {
  padding-right: 7rem; /* 112px */
}
.roam-pb-28 {
  padding-bottom: 7rem; /* 112px */
}
.roam-pl-28 {
  padding-left: 7rem; /* 112px */
}
.roam-p-32 {
  padding: 8rem; /* 128px */
}
.roam-px-32 {
  padding-left: 8rem; /* 128px */
  padding-right: 8rem; /* 128px */
}
.roam-py-32 {
  padding-top: 8rem; /* 128px */
  padding-bottom: 8rem; /* 128px */
}
.roam-ps-32 {
  padding-inline-start: 8rem; /* 128px */
}
.roam-pe-32 {
  padding-inline-end: 8rem; /* 128px */
}
.roam-pt-32 {
  padding-top: 8rem; /* 128px */
}
.roam-pr-32 {
  padding-right: 8rem; /* 128px */
}
.roam-pb-32 {
  padding-bottom: 8rem; /* 128px */
}
.roam-pl-32 {
  padding-left: 8rem; /* 128px */
}
.roam-p-36 {
  padding: 9rem; /* 144px */
}
.roam-px-36 {
  padding-left: 9rem; /* 144px */
  padding-right: 9rem; /* 144px */
}
.roam-py-36 {
  padding-top: 9rem; /* 144px */
  padding-bottom: 9rem; /* 144px */
}
.roam-ps-36 {
  padding-inline-start: 9rem; /* 144px */
}
.roam-pe-36 {
  padding-inline-end: 9rem; /* 144px */
}
.roam-pt-36 {
  padding-top: 9rem; /* 144px */
}
.roam-pr-36 {
  padding-right: 9rem; /* 144px */
}
.roam-pb-36 {
  padding-bottom: 9rem; /* 144px */
}
.roam-pl-36 {
  padding-left: 9rem; /* 144px */
}
.roam-p-40 {
  padding: 10rem; /* 160px */
}
.roam-px-40 {
  padding-left: 10rem; /* 160px */
  padding-right: 10rem; /* 160px */
}
.roam-py-40 {
  padding-top: 10rem; /* 160px */
  padding-bottom: 10rem; /* 160px */
}
.roam-ps-40 {
  padding-inline-start: 10rem; /* 160px */
}
.roam-pe-40 {
  padding-inline-end: 10rem; /* 160px */
}
.roam-pt-40 {
  padding-top: 10rem; /* 160px */
}
.roam-pr-40 {
  padding-right: 10rem; /* 160px */
}
.roam-pb-40 {
  padding-bottom: 10rem; /* 160px */
}
.roam-pl-40 {
  padding-left: 10rem; /* 160px */
}
.roam-p-44 {
  padding: 11rem; /* 176px */
}
.roam-px-44 {
  padding-left: 11rem; /* 176px */
  padding-right: 11rem; /* 176px */
}
.roam-py-44 {
  padding-top: 11rem; /* 176px */
  padding-bottom: 11rem; /* 176px */
}
.roam-ps-44 {
  padding-inline-start: 11rem; /* 176px */
}
.roam-pe-44 {
  padding-inline-end: 11rem; /* 176px */
}
.roam-pt-44 {
  padding-top: 11rem; /* 176px */
}
.roam-pr-44 {
  padding-right: 11rem; /* 176px */
}
.roam-pb-44 {
  padding-bottom: 11rem; /* 176px */
}
.roam-pl-44 {
  padding-left: 11rem; /* 176px */
}
.roam-p-48 {
  padding: 12rem; /* 192px */
}
.roam-px-48 {
  padding-left: 12rem; /* 192px */
  padding-right: 12rem; /* 192px */
}
.roam-py-48 {
  padding-top: 12rem; /* 192px */
  padding-bottom: 12rem; /* 192px */
}
.roam-ps-48 {
  padding-inline-start: 12rem; /* 192px */
}
.roam-pe-48 {
  padding-inline-end: 12rem; /* 192px */
}
.roam-pt-48 {
  padding-top: 12rem; /* 192px */
}
.roam-pr-48 {
  padding-right: 12rem; /* 192px */
}
.roam-pb-48 {
  padding-bottom: 12rem; /* 192px */
}
.roam-pl-48 {
  padding-left: 12rem; /* 192px */
}
.roam-p-52 {
  padding: 13rem; /* 208px */
}
.roam-px-52 {
  padding-left: 13rem; /* 208px */
  padding-right: 13rem; /* 208px */
}
.roam-py-52 {
  padding-top: 13rem; /* 208px */
  padding-bottom: 13rem; /* 208px */
}
.roam-ps-52 {
  padding-inline-start: 13rem; /* 208px */
}
.roam-pe-52 {
  padding-inline-end: 13rem; /* 208px */
}
.roam-pt-52 {
  padding-top: 13rem; /* 208px */
}
.roam-pr-52 {
  padding-right: 13rem; /* 208px */
}
.roam-pb-52 {
  padding-bottom: 13rem; /* 208px */
}
.roam-pl-52 {
  padding-left: 13rem; /* 208px */
}
.roam-p-56 {
  padding: 14rem; /* 224px */
}
.roam-px-56 {
  padding-left: 14rem; /* 224px */
  padding-right: 14rem; /* 224px */
}
.roam-py-56 {
  padding-top: 14rem; /* 224px */
  padding-bottom: 14rem; /* 224px */
}
.roam-ps-56 {
  padding-inline-start: 14rem; /* 224px */
}
.roam-pe-56 {
  padding-inline-end: 14rem; /* 224px */
}
.roam-pt-56 {
  padding-top: 14rem; /* 224px */
}
.roam-pr-56 {
  padding-right: 14rem; /* 224px */
}
.roam-pb-56 {
  padding-bottom: 14rem; /* 224px */
}
.roam-pl-56 {
  padding-left: 14rem; /* 224px */
}
.roam-p-60 {
  padding: 15rem; /* 240px */
}
.roam-px-60 {
  padding-left: 15rem; /* 240px */
  padding-right: 15rem; /* 240px */
}
.roam-py-60 {
  padding-top: 15rem; /* 240px */
  padding-bottom: 15rem; /* 240px */
}
.roam-ps-60 {
  padding-inline-start: 15rem; /* 240px */
}
.roam-pe-60 {
  padding-inline-end: 15rem; /* 240px */
}
.roam-pt-60 {
  padding-top: 15rem; /* 240px */
}
.roam-pr-60 {
  padding-right: 15rem; /* 240px */
}
.roam-pb-60 {
  padding-bottom: 15rem; /* 240px */
}
.roam-pl-60 {
  padding-left: 15rem; /* 240px */
}
.roam-p-64 {
  padding: 16rem; /* 256px */
}
.roam-px-64 {
  padding-left: 16rem; /* 256px */
  padding-right: 16rem; /* 256px */
}
.roam-py-64 {
  padding-top: 16rem; /* 256px */
  padding-bottom: 16rem; /* 256px */
}
.roam-ps-64 {
  padding-inline-start: 16rem; /* 256px */
}
.roam-pe-64 {
  padding-inline-end: 16rem; /* 256px */
}
.roam-pt-64 {
  padding-top: 16rem; /* 256px */
}
.roam-pr-64 {
  padding-right: 16rem; /* 256px */
}
.roam-pb-64 {
  padding-bottom: 16rem; /* 256px */
}
.roam-pl-64 {
  padding-left: 16rem; /* 256px */
}
.roam-p-72 {
  padding: 18rem; /* 288px */
}
.roam-px-72 {
  padding-left: 18rem; /* 288px */
  padding-right: 18rem; /* 288px */
}
.roam-py-72 {
  padding-top: 18rem; /* 288px */
  padding-bottom: 18rem; /* 288px */
}
.roam-ps-72 {
  padding-inline-start: 18rem; /* 288px */
}
.roam-pe-72 {
  padding-inline-end: 18rem; /* 288px */
}
.roam-pt-72 {
  padding-top: 18rem; /* 288px */
}
.roam-pr-72 {
  padding-right: 18rem; /* 288px */
}
.roam-pb-72 {
  padding-bottom: 18rem; /* 288px */
}
.roam-pl-72 {
  padding-left: 18rem; /* 288px */
}
.roam-p-80 {
  padding: 20rem; /* 320px */
}
.roam-px-80 {
  padding-left: 20rem; /* 320px */
  padding-right: 20rem; /* 320px */
}
.roam-py-80 {
  padding-top: 20rem; /* 320px */
  padding-bottom: 20rem; /* 320px */
}
.roam-ps-80 {
  padding-inline-start: 20rem; /* 320px */
}
.roam-pe-80 {
  padding-inline-end: 20rem; /* 320px */
}
.roam-pt-80 {
  padding-top: 20rem; /* 320px */
}
.roam-pr-80 {
  padding-right: 20rem; /* 320px */
}
.roam-pb-80 {
  padding-bottom: 20rem; /* 320px */
}
.roam-pl-80 {
  padding-left: 20rem; /* 320px */
}
.roam-p-96 {
  padding: 24rem; /* 384px */
}
.roam-px-96 {
  padding-left: 24rem; /* 384px */
  padding-right: 24rem; /* 384px */
}
.roam-py-96 {
  padding-top: 24rem; /* 384px */
  padding-bottom: 24rem; /* 384px */
}
.roam-ps-96 {
  padding-inline-start: 24rem; /* 384px */
}
.roam-pe-96 {
  padding-inline-end: 24rem; /* 384px */
}
.roam-pt-96 {
  padding-top: 24rem; /* 384px */
}
.roam-pr-96 {
  padding-right: 24rem; /* 384px */
}
.roam-pb-96 {
  padding-bottom: 24rem; /* 384px */
}
.roam-pl-96 {
  padding-left: 24rem; /* 384px */
}

//https://tailwindcss.com/docs/margin
.roam-m-0 {
  margin: 0px;
}
.roam-mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.roam-my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.roam-ms-0 {
  margin-inline-start: 0px;
}
.roam-me-0 {
  margin-inline-end: 0px;
}
.roam-mt-0 {
  margin-top: 0px;
}
.roam-mr-0 {
  margin-right: 0px;
}
.roam-mb-0 {
  margin-bottom: 0px;
}
.roam-ml-0 {
  margin-left: 0px;
}
.roam-m-px {
  margin: 1px;
}
.roam-mx-px {
  margin-left: 1px;
  margin-right: 1px;
}
.roam-my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}
.roam-ms-px {
  margin-inline-start: 1px;
}
.roam-me-px {
  margin-inline-end: 1px;
}
.roam-mt-px {
  margin-top: 1px;
}
.roam-mr-px {
  margin-right: 1px;
}
.roam-mb-px {
  margin-bottom: 1px;
}
.roam-ml-px {
  margin-left: 1px;
}
.roam-m-0\.5 {
  margin: 0.125rem; /* 2px */
}
.roam-mx-0\.5 {
  margin-left: 0.125rem; /* 2px */
  margin-right: 0.125rem; /* 2px */
}
.roam-my-0\.5 {
  margin-top: 0.125rem; /* 2px */
  margin-bottom: 0.125rem; /* 2px */
}
.roam-ms-0\.5 {
  margin-inline-start: 0.125rem; /* 2px */
}
.roam-me-0\.5 {
  margin-inline-end: 0.125rem; /* 2px */
}
.roam-mt-0\.5 {
  margin-top: 0.125rem; /* 2px */
}
.roam-mr-0\.5 {
  margin-right: 0.125rem; /* 2px */
}
.roam-mb-0\.5 {
  margin-bottom: 0.125rem; /* 2px */
}
.roam-ml-0\.5 {
  margin-left: 0.125rem;
} /* 2px */
.roam-m-1 {
  margin: 0.25rem; /* 4px */
}
.roam-mx-1 {
  margin-left: 0.25rem; /* 4px */
  margin-right: 0.25rem; /* 4px */
}
.roam-my-1 {
  margin-top: 0.25rem; /* 4px */
  margin-bottom: 0.25rem; /* 4px */
}
.roam-ms-1 {
  margin-inline-start: 0.25rem; /* 4px */
}
.roam-me-1 {
  margin-inline-end: 0.25rem; /* 4px */
}
.roam-mt-1 {
  margin-top: 0.25rem; /* 4px */
}
.roam-mr-1 {
  margin-right: 0.25rem; /* 4px */
}
.roam-mb-1 {
  margin-bottom: 0.25rem; /* 4px */
}
.roam-ml-1 {
  margin-left: 0.25rem; /* 4px */
}
.roam-m-1\.5 {
  margin: 0.375rem; /* 6px */
}
.roam-mx-1\.5 {
  margin-left: 0.375rem; /* 6px */
  margin-right: 0.375rem; /* 6px */
}
.roam-my-1\.5 {
  margin-top: 0.375rem; /* 6px */
  margin-bottom: 0.375rem; /* 6px */
}
.roam-ms-1\.5 {
  margin-inline-start: 0.375rem; /* 6px */
}
.roam-me-1\.5 {
  margin-inline-end: 0.375rem; /* 6px */
}
.roam-mt-1\.5 {
  margin-top: 0.375rem; /* 6px */
}
.roam-mr-1\.5 {
  margin-right: 0.375rem; /* 6px */
}
.roam-mb-1\.5 {
  margin-bottom: 0.375rem; /* 6px */
}
.roam-ml-1\.5 {
  margin-left: 0.375rem; /* 6px */
}
.roam-m-2 {
  margin: 0.5rem; /* 8px */
}
.roam-mx-2 {
  margin-left: 0.5rem; /* 8px */
  margin-right: 0.5rem; /* 8px */
}
.roam-my-2 {
  margin-top: 0.5rem; /* 8px */
  margin-bottom: 0.5rem; /* 8px */
}
.roam-ms-2 {
  margin-inline-start: 0.5rem; /* 8px */
}
.roam-me-2 {
  margin-inline-end: 0.5rem; /* 8px */
}
.roam-mt-2 {
  margin-top: 0.5rem; /* 8px */
}
.roam-mr-2 {
  margin-right: 0.5rem; /* 8px */
}
.roam-mb-2 {
  margin-bottom: 0.5rem; /* 8px */
}
.roam-ml-2 {
  margin-left: 0.5rem; /* 8px */
}
.roam-m-2\.5 {
  margin: 0.625rem; /* 10px */
}
.roam-mx-2\.5 {
  margin-left: 0.625rem; /* 10px */
  margin-right: 0.625rem; /* 10px */
}
.roam-my-2\.5 {
  margin-top: 0.625rem; /* 10px */
  margin-bottom: 0.625rem; /* 10px */
}
.roam-ms-2\.5 {
  margin-inline-start: 0.625rem; /* 10px */
}
.roam-me-2\.5 {
  margin-inline-end: 0.625rem; /* 10px */
}
.roam-mt-2\.5 {
  margin-top: 0.625rem; /* 10px */
}
.roam-mr-2\.5 {
  margin-right: 0.625rem; /* 10px */
}
.roam-mb-2\.5 {
  margin-bottom: 0.625rem; /* 10px */
}
.roam-ml-2\.5 {
  margin-left: 0.625rem; /* 10px */
}
.roam-m-3 {
  margin: 0.75rem; /* 12px */
}
.roam-mx-3 {
  margin-left: 0.75rem; /* 12px */
  margin-right: 0.75rem; /* 12px */
}
.roam-my-3 {
  margin-top: 0.75rem; /* 12px */
  margin-bottom: 0.75rem; /* 12px */
}
.roam-ms-3 {
  margin-inline-start: 0.75rem; /* 12px */
}
.roam-me-3 {
  margin-inline-end: 0.75rem; /* 12px */
}
.roam-mt-3 {
  margin-top: 0.75rem; /* 12px */
}
.roam-mr-3 {
  margin-right: 0.75rem; /* 12px */
}
.roam-mb-3 {
  margin-bottom: 0.75rem; /* 12px */
}
.roam-ml-3 {
  margin-left: 0.75rem; /* 12px */
}
.roam-m-3\.5 {
  margin: 0.875rem; /* 14px */
}
.roam-mx-3\.5 {
  margin-left: 0.875rem; /* 14px */
  margin-right: 0.875rem; /* 14px */
}
.roam-my-3\.5 {
  margin-top: 0.875rem; /* 14px */
  margin-bottom: 0.875rem; /* 14px */
}
.roam-ms-3\.5 {
  margin-inline-start: 0.875rem; /* 14px */
}
.roam-me-3\.5 {
  margin-inline-end: 0.875rem; /* 14px */
}
.roam-mt-3\.5 {
  margin-top: 0.875rem; /* 14px */
}
.roam-mr-3\.5 {
  margin-right: 0.875rem; /* 14px */
}
.roam-mb-3\.5 {
  margin-bottom: 0.875rem; /* 14px */
}
.roam-ml-3\.5 {
  margin-left: 0.875rem; /* 14px */
}
.roam-m-4 {
  margin: 1rem; /* 16px */
}
.roam-mx-4 {
  margin-left: 1rem; /* 16px */
  margin-right: 1rem; /* 16px */
}
.roam-my-4 {
  margin-top: 1rem; /* 16px */
  margin-bottom: 1rem; /* 16px */
}
.roam-ms-4 {
  margin-inline-start: 1rem; /* 16px */
}
.roam-me-4 {
  margin-inline-end: 1rem; /* 16px */
}
.roam-mt-4 {
  margin-top: 1rem; /* 16px */
}
.roam-mr-4 {
  margin-right: 1rem; /* 16px */
}
.roam-mb-4 {
  margin-bottom: 1rem; /* 16px */
}
.roam-ml-4 {
  margin-left: 1rem; /* 16px */
}
.roam-m-5 {
  margin: 1.25rem; /* 20px */
}
.roam-mx-5 {
  margin-left: 1.25rem; /* 20px */
  margin-right: 1.25rem; /* 20px */
}
.roam-my-5 {
  margin-top: 1.25rem; /* 20px */
  margin-bottom: 1.25rem; /* 20px */
}
.roam-ms-5 {
  margin-inline-start: 1.25rem; /* 20px */
}
.roam-me-5 {
  margin-inline-end: 1.25rem; /* 20px */
}
.roam-mt-5 {
  margin-top: 1.25rem; /* 20px */
}
.roam-mr-5 {
  margin-right: 1.25rem; /* 20px */
}
.roam-mb-5 {
  margin-bottom: 1.25rem; /* 20px */
}
.roam-ml-5 {
  margin-left: 1.25rem; /* 20px */
}
.roam-m-6 {
  margin: 1.5rem; /* 24px */
}
.roam-mx-6 {
  margin-left: 1.5rem; /* 24px */
  margin-right: 1.5rem; /* 24px */
}
.roam-my-6 {
  margin-top: 1.5rem; /* 24px */
  margin-bottom: 1.5rem; /* 24px */
}
.roam-ms-6 {
  margin-inline-start: 1.5rem; /* 24px */
}
.roam-me-6 {
  margin-inline-end: 1.5rem; /* 24px */
}
.roam-mt-6 {
  margin-top: 1.5rem; /* 24px */
}
.roam-mr-6 {
  margin-right: 1.5rem; /* 24px */
}
.roam-mb-6 {
  margin-bottom: 1.5rem; /* 24px */
}
.roam-ml-6 {
  margin-left: 1.5rem; /* 24px */
}
.roam-m-7 {
  margin: 1.75rem; /* 28px */
}
.roam-mx-7 {
  margin-left: 1.75rem; /* 28px */
  margin-right: 1.75rem; /* 28px */
}
.roam-my-7 {
  margin-top: 1.75rem; /* 28px */
  margin-bottom: 1.75rem; /* 28px */
}
.roam-ms-7 {
  margin-inline-start: 1.75rem; /* 28px */
}
.roam-me-7 {
  margin-inline-end: 1.75rem; /* 28px */
}
.roam-mt-7 {
  margin-top: 1.75rem; /* 28px */
}
.roam-mr-7 {
  margin-right: 1.75rem; /* 28px */
}
.roam-mb-7 {
  margin-bottom: 1.75rem; /* 28px */
}
.roam-ml-7 {
  margin-left: 1.75rem; /* 28px */
}
.roam-m-8 {
  margin: 2rem; /* 32px */
}
.roam-mx-8 {
  margin-left: 2rem; /* 32px */
  margin-right: 2rem; /* 32px */
}
.roam-my-8 {
  margin-top: 2rem; /* 32px */
  margin-bottom: 2rem; /* 32px */
}
.roam-ms-8 {
  margin-inline-start: 2rem; /* 32px */
}
.roam-me-8 {
  margin-inline-end: 2rem; /* 32px */
}
.roam-mt-8 {
  margin-top: 2rem; /* 32px */
}
.roam-mr-8 {
  margin-right: 2rem; /* 32px */
}
.roam-mb-8 {
  margin-bottom: 2rem; /* 32px */
}
.roam-ml-8 {
  margin-left: 2rem; /* 32px */
}
.roam-m-9 {
  margin: 2.25rem; /* 36px */
}
.roam-mx-9 {
  margin-left: 2.25rem; /* 36px */
  margin-right: 2.25rem; /* 36px */
}
.roam-my-9 {
  margin-top: 2.25rem; /* 36px */
  margin-bottom: 2.25rem; /* 36px */
}
.roam-ms-9 {
  margin-inline-start: 2.25rem; /* 36px */
}
.roam-me-9 {
  margin-inline-end: 2.25rem; /* 36px */
}
.roam-mt-9 {
  margin-top: 2.25rem; /* 36px */
}
.roam-mr-9 {
  margin-right: 2.25rem; /* 36px */
}
.roam-mb-9 {
  margin-bottom: 2.25rem; /* 36px */
}
.roam-ml-9 {
  margin-left: 2.25rem; /* 36px */
}
.roam-m-10 {
  margin: 2.5rem; /* 40px */
}
.roam-mx-10 {
  margin-left: 2.5rem; /* 40px */
  margin-right: 2.5rem; /* 40px */
}
.roam-my-10 {
  margin-top: 2.5rem; /* 40px */
  margin-bottom: 2.5rem; /* 40px */
}
.roam-ms-10 {
  margin-inline-start: 2.5rem; /* 40px */
}
.roam-me-10 {
  margin-inline-end: 2.5rem; /* 40px */
}
.roam-mt-10 {
  margin-top: 2.5rem; /* 40px */
}
.roam-mr-10 {
  margin-right: 2.5rem; /* 40px */
}
.roam-mb-10 {
  margin-bottom: 2.5rem; /* 40px */
}
.roam-ml-10 {
  margin-left: 2.5rem; /* 40px */
}
.roam-m-11 {
  margin: 2.75rem; /* 44px */
}
.roam-mx-11 {
  margin-left: 2.75rem; /* 44px */
  margin-right: 2.75rem; /* 44px */
}
.roam-my-11 {
  margin-top: 2.75rem; /* 44px */
  margin-bottom: 2.75rem; /* 44px */
}
.roam-ms-11 {
  margin-inline-start: 2.75rem; /* 44px */
}
.roam-me-11 {
  margin-inline-end: 2.75rem; /* 44px */
}
.roam-mt-11 {
  margin-top: 2.75rem; /* 44px */
}
.roam-mr-11 {
  margin-right: 2.75rem; /* 44px */
}
.roam-mb-11 {
  margin-bottom: 2.75rem; /* 44px */
}
.roam-ml-11 {
  margin-left: 2.75rem; /* 44px */
}
.roam-m-12 {
  margin: 3rem; /* 48px */
}
.roam-mx-12 {
  margin-left: 3rem; /* 48px */
  margin-right: 3rem; /* 48px */
}
.roam-my-12 {
  margin-top: 3rem; /* 48px */
  margin-bottom: 3rem; /* 48px */
}
.roam-ms-12 {
  margin-inline-start: 3rem; /* 48px */
}
.roam-me-12 {
  margin-inline-end: 3rem; /* 48px */
}
.roam-mt-12 {
  margin-top: 3rem; /* 48px */
}
.roam-mr-12 {
  margin-right: 3rem; /* 48px */
}
.roam-mb-12 {
  margin-bottom: 3rem; /* 48px */
}
.roam-ml-12 {
  margin-left: 3rem; /* 48px */
}
.roam-m-14 {
  margin: 3.5rem; /* 56px */
}
.roam-mx-14 {
  margin-left: 3.5rem; /* 56px */
  margin-right: 3.5rem; /* 56px */
}
.roam-my-14 {
  margin-top: 3.5rem; /* 56px */
  margin-bottom: 3.5rem; /* 56px */
}
.roam-ms-14 {
  margin-inline-start: 3.5rem; /* 56px */
}
.roam-me-14 {
  margin-inline-end: 3.5rem; /* 56px */
}
.roam-mt-14 {
  margin-top: 3.5rem; /* 56px */
}
.roam-mr-14 {
  margin-right: 3.5rem; /* 56px */
}
.roam-mb-14 {
  margin-bottom: 3.5rem; /* 56px */
}
.roam-ml-14 {
  margin-left: 3.5rem; /* 56px */
}
.roam-m-16 {
  margin: 4rem; /* 64px */
}
.roam-mx-16 {
  margin-left: 4rem; /* 64px */
  margin-right: 4rem; /* 64px */
}
.roam-my-16 {
  margin-top: 4rem; /* 64px */
  margin-bottom: 4rem; /* 64px */
}
.roam-ms-16 {
  margin-inline-start: 4rem; /* 64px */
}
.roam-me-16 {
  margin-inline-end: 4rem; /* 64px */
}
.roam-mt-16 {
  margin-top: 4rem; /* 64px */
}
.roam-mr-16 {
  margin-right: 4rem; /* 64px */
}
.roam-mb-16 {
  margin-bottom: 4rem; /* 64px */
}
.roam-ml-16 {
  margin-left: 4rem; /* 64px */
}
.roam-m-20 {
  margin: 5rem; /* 80px */
}
.roam-mx-20 {
  margin-left: 5rem; /* 80px */
  margin-right: 5rem; /* 80px */
}
.roam-my-20 {
  margin-top: 5rem; /* 80px */
  margin-bottom: 5rem; /* 80px */
}
.roam-ms-20 {
  margin-inline-start: 5rem; /* 80px */
}
.roam-me-20 {
  margin-inline-end: 5rem; /* 80px */
}
.roam-mt-20 {
  margin-top: 5rem; /* 80px */
}
.roam-mr-20 {
  margin-right: 5rem; /* 80px */
}
.roam-mb-20 {
  margin-bottom: 5rem; /* 80px */
}
.roam-ml-20 {
  margin-left: 5rem; /* 80px */
}
.roam-m-24 {
  margin: 6rem; /* 96px */
}
.roam-mx-24 {
  margin-left: 6rem; /* 96px */
  margin-right: 6rem; /* 96px */
}
.roam-my-24 {
  margin-top: 6rem; /* 96px */
  margin-bottom: 6rem; /* 96px */
}
.roam-ms-24 {
  margin-inline-start: 6rem; /* 96px */
}
.roam-me-24 {
  margin-inline-end: 6rem; /* 96px */
}
.roam-mt-24 {
  margin-top: 6rem; /* 96px */
}
.roam-mr-24 {
  margin-right: 6rem; /* 96px */
}
.roam-mb-24 {
  margin-bottom: 6rem; /* 96px */
}
.roam-ml-24 {
  margin-left: 6rem; /* 96px */
}
.roam-m-28 {
  margin: 7rem; /* 112px */
}
.roam-mx-28 {
  margin-left: 7rem; /* 112px */
  margin-right: 7rem; /* 112px */
}
.roam-my-28 {
  margin-top: 7rem; /* 112px */
  margin-bottom: 7rem; /* 112px */
}
.roam-ms-28 {
  margin-inline-start: 7rem; /* 112px */
}
.roam-me-28 {
  margin-inline-end: 7rem; /* 112px */
}
.roam-mt-28 {
  margin-top: 7rem; /* 112px */
}
.roam-mr-28 {
  margin-right: 7rem; /* 112px */
}
.roam-mb-28 {
  margin-bottom: 7rem; /* 112px */
}
.roam-ml-28 {
  margin-left: 7rem; /* 112px */
}
.roam-m-32 {
  margin: 8rem; /* 128px */
}
.roam-mx-32 {
  margin-left: 8rem; /* 128px */
  margin-right: 8rem; /* 128px */
}
.roam-my-32 {
  margin-top: 8rem; /* 128px */
  margin-bottom: 8rem; /* 128px */
}
.roam-ms-32 {
  margin-inline-start: 8rem; /* 128px */
}
.roam-me-32 {
  margin-inline-end: 8rem; /* 128px */
}
.roam-mt-32 {
  margin-top: 8rem; /* 128px */
}
.roam-mr-32 {
  margin-right: 8rem; /* 128px */
}
.roam-mb-32 {
  margin-bottom: 8rem; /* 128px */
}
.roam-ml-32 {
  margin-left: 8rem; /* 128px */
}
.roam-m-36 {
  margin: 9rem; /* 144px */
}
.roam-mx-36 {
  margin-left: 9rem; /* 144px */
  margin-right: 9rem; /* 144px */
}
.roam-my-36 {
  margin-top: 9rem; /* 144px */
  margin-bottom: 9rem; /* 144px */
}
.roam-ms-36 {
  margin-inline-start: 9rem; /* 144px */
}
.roam-me-36 {
  margin-inline-end: 9rem; /* 144px */
}
.roam-mt-36 {
  margin-top: 9rem; /* 144px */
}
.roam-mr-36 {
  margin-right: 9rem; /* 144px */
}
.roam-mb-36 {
  margin-bottom: 9rem; /* 144px */
}
.roam-ml-36 {
  margin-left: 9rem; /* 144px */
}
.roam-m-40 {
  margin: 10rem; /* 160px */
}
.roam-mx-40 {
  margin-left: 10rem; /* 160px */
  margin-right: 10rem; /* 160px */
}
.roam-my-40 {
  margin-top: 10rem; /* 160px */
  margin-bottom: 10rem; /* 160px */
}
.roam-ms-40 {
  margin-inline-start: 10rem; /* 160px */
}
.roam-me-40 {
  margin-inline-end: 10rem; /* 160px */
}
.roam-mt-40 {
  margin-top: 10rem; /* 160px */
}
.roam-mr-40 {
  margin-right: 10rem; /* 160px */
}
.roam-mb-40 {
  margin-bottom: 10rem; /* 160px */
}
.roam-ml-40 {
  margin-left: 10rem; /* 160px */
}
.roam-m-44 {
  margin: 11rem; /* 176px */
}
.roam-mx-44 {
  margin-left: 11rem; /* 176px */
  margin-right: 11rem; /* 176px */
}
.roam-my-44 {
  margin-top: 11rem; /* 176px */
  margin-bottom: 11rem; /* 176px */
}
.roam-ms-44 {
  margin-inline-start: 11rem; /* 176px */
}
.roam-me-44 {
  margin-inline-end: 11rem; /* 176px */
}
.roam-mt-44 {
  margin-top: 11rem; /* 176px */
}
.roam-mr-44 {
  margin-right: 11rem; /* 176px */
}
.roam-mb-44 {
  margin-bottom: 11rem; /* 176px */
}
.roam-ml-44 {
  margin-left: 11rem; /* 176px */
}
.roam-m-48 {
  margin: 12rem; /* 192px */
}
.roam-mx-48 {
  margin-left: 12rem; /* 192px */
  margin-right: 12rem; /* 192px */
}
.roam-my-48 {
  margin-top: 12rem; /* 192px */
  margin-bottom: 12rem; /* 192px */
}
.roam-ms-48 {
  margin-inline-start: 12rem; /* 192px */
}
.roam-me-48 {
  margin-inline-end: 12rem; /* 192px */
}
.roam-mt-48 {
  margin-top: 12rem; /* 192px */
}
.roam-mr-48 {
  margin-right: 12rem; /* 192px */
}
.roam-mb-48 {
  margin-bottom: 12rem; /* 192px */
}
.roam-ml-48 {
  margin-left: 12rem; /* 192px */
}
.roam-m-52 {
  margin: 13rem; /* 208px */
}
.roam-mx-52 {
  margin-left: 13rem; /* 208px */
  margin-right: 13rem; /* 208px */
}
.roam-my-52 {
  margin-top: 13rem; /* 208px */
  margin-bottom: 13rem; /* 208px */
}
.roam-ms-52 {
  margin-inline-start: 13rem; /* 208px */
}
.roam-me-52 {
  margin-inline-end: 13rem; /* 208px */
}
.roam-mt-52 {
  margin-top: 13rem; /* 208px */
}
.roam-mr-52 {
  margin-right: 13rem; /* 208px */
}
.roam-mb-52 {
  margin-bottom: 13rem; /* 208px */
}
.roam-ml-52 {
  margin-left: 13rem; /* 208px */
}
.roam-m-56 {
  margin: 14rem; /* 224px */
}
.roam-mx-56 {
  margin-left: 14rem; /* 224px */
  margin-right: 14rem; /* 224px */
}
.roam-my-56 {
  margin-top: 14rem; /* 224px */
  margin-bottom: 14rem; /* 224px */
}
.roam-ms-56 {
  margin-inline-start: 14rem; /* 224px */
}
.roam-me-56 {
  margin-inline-end: 14rem; /* 224px */
}
.roam-mt-56 {
  margin-top: 14rem; /* 224px */
}
.roam-mr-56 {
  margin-right: 14rem; /* 224px */
}
.roam-mb-56 {
  margin-bottom: 14rem; /* 224px */
}
.roam-ml-56 {
  margin-left: 14rem; /* 224px */
}
.roam-m-60 {
  margin: 15rem; /* 240px */
}
.roam-mx-60 {
  margin-left: 15rem; /* 240px */
  margin-right: 15rem; /* 240px */
}
.roam-my-60 {
  margin-top: 15rem; /* 240px */
  margin-bottom: 15rem; /* 240px */
}
.roam-ms-60 {
  margin-inline-start: 15rem; /* 240px */
}
.roam-me-60 {
  margin-inline-end: 15rem; /* 240px */
}
.roam-mt-60 {
  margin-top: 15rem; /* 240px */
}
.roam-mr-60 {
  margin-right: 15rem; /* 240px */
}
.roam-mb-60 {
  margin-bottom: 15rem; /* 240px */
}
.roam-ml-60 {
  margin-left: 15rem; /* 240px */
}
.roam-m-64 {
  margin: 16rem; /* 256px */
}
.roam-mx-64 {
  margin-left: 16rem; /* 256px */
  margin-right: 16rem; /* 256px */
}
.roam-my-64 {
  margin-top: 16rem; /* 256px */
  margin-bottom: 16rem; /* 256px */
}
.roam-ms-64 {
  margin-inline-start: 16rem; /* 256px */
}
.roam-me-64 {
  margin-inline-end: 16rem; /* 256px */
}
.roam-mt-64 {
  margin-top: 16rem; /* 256px */
}
.roam-mr-64 {
  margin-right: 16rem; /* 256px */
}
.roam-mb-64 {
  margin-bottom: 16rem; /* 256px */
}
.roam-ml-64 {
  margin-left: 16rem; /* 256px */
}
.roam-m-72 {
  margin: 18rem; /* 288px */
}
.roam-mx-72 {
  margin-left: 18rem; /* 288px */
  margin-right: 18rem; /* 288px */
}
.roam-my-72 {
  margin-top: 18rem; /* 288px */
  margin-bottom: 18rem; /* 288px */
}
.roam-ms-72 {
  margin-inline-start: 18rem; /* 288px */
}
.roam-me-72 {
  margin-inline-end: 18rem; /* 288px */
}
.roam-mt-72 {
  margin-top: 18rem; /* 288px */
}
.roam-mr-72 {
  margin-right: 18rem; /* 288px */
}
.roam-mb-72 {
  margin-bottom: 18rem; /* 288px */
}
.roam-ml-72 {
  margin-left: 18rem; /* 288px */
}
.roam-m-80 {
  margin: 20rem; /* 320px */
}
.roam-mx-80 {
  margin-left: 20rem; /* 320px */
  margin-right: 20rem; /* 320px */
}
.roam-my-80 {
  margin-top: 20rem; /* 320px */
  margin-bottom: 20rem; /* 320px */
}
.roam-ms-80 {
  margin-inline-start: 20rem; /* 320px */
}
.roam-me-80 {
  margin-inline-end: 20rem; /* 320px */
}
.roam-mt-80 {
  margin-top: 20rem; /* 320px */
}
.roam-mr-80 {
  margin-right: 20rem; /* 320px */
}
.roam-mb-80 {
  margin-bottom: 20rem; /* 320px */
}
.roam-ml-80 {
  margin-left: 20rem; /* 320px */
}
.roam-m-96 {
  margin: 24rem; /* 384px */
}
.roam-mx-96 {
  margin-left: 24rem; /* 384px */
  margin-right: 24rem; /* 384px */
}
.roam-my-96 {
  margin-top: 24rem; /* 384px */
  margin-bottom: 24rem; /* 384px */
}
.roam-ms-96 {
  margin-inline-start: 24rem; /* 384px */
}
.roam-me-96 {
  margin-inline-end: 24rem; /* 384px */
}
.roam-mt-96 {
  margin-top: 24rem; /* 384px */
}
.roam-mr-96 {
  margin-right: 24rem; /* 384px */
}
.roam-mb-96 {
  margin-bottom: 24rem; /* 384px */
}
.roam-ml-96 {
  margin-left: 24rem; /* 384px */
}
.roam-m-auto {
  margin: auto;
}
.roam-mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.roam-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.roam-ms-auto {
  margin-inline-start: auto;
}
.roam-me-auto {
  margin-inline-end: auto;
}
.roam-mt-auto {
  margin-top: auto;
}
.roam-mr-auto {
  margin-right: auto;
}
.roam-mb-auto {
  margin-bottom: auto;
}
.roam-ml-auto {
  margin-left: auto;
}

//https://tailwindcss.com/docs/space
.roam-space-x-0 > * + * {
  margin-left: 0px;
}
.roam-space-y-0 > * + * {
  margin-top: 0px;
}
.roam-space-x-0\.5 > * + * {
  margin-left: 0.125rem; /* 2px */
}
.roam-space-y-0\.5 > * + * {
  margin-top: 0.125rem; /* 2px */
}
.roam-space-x-1 > * + * {
  margin-left: 0.25rem; /* 4px */
}
.roam-space-y-1 > * + * {
  margin-top: 0.25rem; /* 4px */
}
.roam-space-x-1\.5 > * + * {
  margin-left: 0.375rem; /* 6px */
}
.roam-space-y-1\.5 > * + * {
  margin-top: 0.375rem; /* 6px */
}
.roam-space-x-2 > * + * {
  margin-left: 0.5rem; /* 8px */
}
.roam-space-y-2 > * + * {
  margin-top: 0.5rem; /* 8px */
}
.roam-space-x-2\.5 > * + * {
  margin-left: 0.625rem; /* 10px */
}
.roam-space-y-2\.5 > * + * {
  margin-top: 0.625rem; /* 10px */
}
.roam-space-x-3 > * + * {
  margin-left: 0.75rem; /* 12px */
}
.roam-space-y-3 > * + * {
  margin-top: 0.75rem; /* 12px */
}
.roam-space-x-3\.5 > * + * {
  margin-left: 0.875rem; /* 14px */
}
.roam-space-y-3\.5 > * + * {
  margin-top: 0.875rem; /* 14px */
}
.roam-space-x-4 > * + * {
  margin-left: 1rem; /* 16px */
}
.roam-space-y-4 > * + * {
  margin-top: 1rem; /* 16px */
}
.roam-space-x-5 > * + * {
  margin-left: 1.25rem; /* 20px */
}
.roam-space-y-5 > * + * {
  margin-top: 1.25rem; /* 20px */
}
.roam-space-x-6 > * + * {
  margin-left: 1.5rem; /* 24px */
}
.roam-space-y-6 > * + * {
  margin-top: 1.5rem; /* 24px */
}
.roam-space-x-7 > * + * {
  margin-left: 1.75rem; /* 28px */
}
.roam-space-y-7 > * + * {
  margin-top: 1.75rem; /* 28px */
}
.roam-space-x-8 > * + * {
  margin-left: 2rem; /* 32px */
}
.roam-space-y-8 > * + * {
  margin-top: 2rem; /* 32px */
}
.roam-space-x-9 > * + * {
  margin-left: 2.25rem; /* 36px */
}
.roam-space-y-9 > * + * {
  margin-top: 2.25rem; /* 36px */
}
.roam-space-x-10 > * + * {
  margin-left: 2.5rem; /* 40px */
}
.roam-space-y-10 > * + * {
  margin-top: 2.5rem; /* 40px */
}
.roam-space-x-11 > * + * {
  margin-left: 2.75rem; /* 44px */
}
.roam-space-y-11 > * + * {
  margin-top: 2.75rem; /* 44px */
}
.roam-space-x-12 > * + * {
  margin-left: 3rem; /* 48px */
}
.roam-space-y-12 > * + * {
  margin-top: 3rem; /* 48px */
}
.roam-space-x-14 > * + * {
  margin-left: 3.5rem; /* 56px */
}
.roam-space-y-14 > * + * {
  margin-top: 3.5rem; /* 56px */
}
.roam-space-x-16 > * + * {
  margin-left: 4rem; /* 64px */
}
.roam-space-y-16 > * + * {
  margin-top: 4rem; /* 64px */
}
.roam-space-x-20 > * + * {
  margin-left: 5rem; /* 80px */
}
.roam-space-y-20 > * + * {
  margin-top: 5rem; /* 80px */
}
.roam-space-x-24 > * + * {
  margin-left: 6rem; /* 96px */
}
.roam-space-y-24 > * + * {
  margin-top: 6rem; /* 96px */
}
.roam-space-x-28 > * + * {
  margin-left: 7rem; /* 112px */
}
.roam-space-y-28 > * + * {
  margin-top: 7rem; /* 112px */
}
.roam-space-x-32 > * + * {
  margin-left: 8rem; /* 128px */
}
.roam-space-y-32 > * + * {
  margin-top: 8rem; /* 128px */
}
.roam-space-x-36 > * + * {
  margin-left: 9rem; /* 144px */
}
.roam-space-y-36 > * + * {
  margin-top: 9rem; /* 144px */
}
.roam-space-x-40 > * + * {
  margin-left: 10rem; /* 160px */
}
.roam-space-y-40 > * + * {
  margin-top: 10rem; /* 160px */
}
.roam-space-x-44 > * + * {
  margin-left: 11rem; /* 176px */
}
.roam-space-y-44 > * + * {
  margin-top: 11rem; /* 176px */
}
.roam-space-x-48 > * + * {
  margin-left: 12rem; /* 192px */
}
.roam-space-y-48 > * + * {
  margin-top: 12rem; /* 192px */
}
.roam-space-x-52 > * + * {
  margin-left: 13rem; /* 208px */
}
.roam-space-y-52 > * + * {
  margin-top: 13rem; /* 208px */
}
.roam-space-x-56 > * + * {
  margin-left: 14rem; /* 224px */
}
.roam-space-y-56 > * + * {
  margin-top: 14rem; /* 224px */
}
.roam-space-x-60 > * + * {
  margin-left: 15rem; /* 240px */
}
.roam-space-y-60 > * + * {
  margin-top: 15rem; /* 240px */
}
.roam-space-x-64 > * + * {
  margin-left: 16rem; /* 256px */
}
.roam-space-y-64 > * + * {
  margin-top: 16rem; /* 256px */
}
.roam-space-x-72 > * + * {
  margin-left: 18rem; /* 288px */
}
.roam-space-y-72 > * + * {
  margin-top: 18rem; /* 288px */
}
.roam-space-x-80 > * + * {
  margin-left: 20rem; /* 320px */
}
.roam-space-y-80 > * + * {
  margin-top: 20rem; /* 320px */
}
.roam-space-x-96 > * + * {
  margin-left: 24rem; /* 384px */
}
.roam-space-y-96 > * + * {
  margin-top: 24rem; /* 384px */
}
.roam-space-x-px > * + * {
  margin-left: 1px;
}
.roam-space-y-px > * + * {
  margin-top: 1px;
}

/*
* ================= SIZING ==============================================
*/

// https://tailwindcss.com/docs/width
.roam-w-0 {
  width: 0px;
}
.roam-w-px {
  width: 1px;
}
.roam-w-0\.5 {
  width: 0.125rem; /* 2px */
}
.roam-w-1 {
  width: 0.25rem; /* 4px */
}
.roam-w-1\.5 {
  width: 0.375rem; /* 6px */
}
.roam-w-2 {
  width: 0.5rem; /* 8px */
}
.roam-w-2\.5 {
  width: 0.625rem; /* 10px */
}
.roam-w-3 {
  width: 0.75rem; /* 12px */
}
.roam-w-3\.5 {
  width: 0.875rem; /* 14px */
}
.roam-w-4 {
  width: 1rem; /* 16px */
}
.roam-w-5 {
  width: 1.25rem; /* 20px */
}
.roam-w-6 {
  width: 1.5rem; /* 24px */
}
.roam-w-7 {
  width: 1.75rem; /* 28px */
}
.roam-w-8 {
  width: 2rem; /* 32px */
}
.roam-w-9 {
  width: 2.25rem; /* 36px */
}
.roam-w-10 {
  width: 2.5rem; /* 40px */
}
.roam-w-11 {
  width: 2.75rem; /* 44px */
}
.roam-w-12 {
  width: 3rem; /* 48px */
}
.roam-w-14 {
  width: 3.5rem; /* 56px */
}
.roam-w-16 {
  width: 4rem; /* 64px */
}
.roam-w-20 {
  width: 5rem; /* 80px */
}
.roam-w-24 {
  width: 6rem; /* 96px */
}
.roam-w-28 {
  width: 7rem; /* 112px */
}
.roam-w-32 {
  width: 8rem; /* 128px */
}
.roam-w-36 {
  width: 9rem; /* 144px */
}
.roam-w-40 {
  width: 10rem; /* 160px */
}
.roam-w-44 {
  width: 11rem; /* 176px */
}
.roam-w-48 {
  width: 12rem; /* 192px */
}
.roam-w-52 {
  width: 13rem; /* 208px */
}
.roam-w-56 {
  width: 14rem; /* 224px */
}
.roam-w-60 {
  width: 15rem; /* 240px */
}
.roam-w-64 {
  width: 16rem; /* 256px */
}
.roam-w-72 {
  width: 18rem; /* 288px */
}
.roam-w-80 {
  width: 20rem; /* 320px */
}
.roam-w-96 {
  width: 24rem; /* 384px */
}
.roam-w-auto {
  width: auto;
}
.roam-w-1\/2 {
  width: 50%;
}
.roam-w-1\/3 {
  width: 33.333333%;
}
.roam-w-2\/3 {
  width: 66.666667%;
}
.roam-w-1\/4 {
  width: 25%;
}
.roam-w-2\/4 {
  width: 50%;
}
.roam-w-3\/4 {
  width: 75%;
}
.roam-w-1\/5 {
  width: 20%;
}
.roam-w-2\/5 {
  width: 40%;
}
.roam-w-3\/5 {
  width: 60%;
}
.roam-w-4\/5 {
  width: 80%;
}
.roam-w-1\/6 {
  width: 16.666667%;
}
.roam-w-2\/6 {
  width: 33.333333%;
}
.roam-w-3\/6 {
  width: 50%;
}
.roam-w-4\/6 {
  width: 66.666667%;
}
.roam-w-5\/6 {
  width: 83.333333%;
}
.roam-w-1\/12 {
  width: 8.333333%;
}
.roam-w-2\/12 {
  width: 16.666667%;
}
.roam-w-3\/12 {
  width: 25%;
}
.roam-w-4\/12 {
  width: 33.333333%;
}
.roam-w-5\/12 {
  width: 41.666667%;
}
.roam-w-6\/12 {
  width: 50%;
}
.roam-w-7\/12 {
  width: 58.333333%;
}
.roam-w-8\/12 {
  width: 66.666667%;
}
.roam-w-9\/12 {
  width: 75%;
}
.roam-w-10\/12 {
  width: 83.333333%;
}
.roam-w-11\/12 {
  width: 91.666667%;
}
.roam-w-full {
  width: 100%;
}
.roam-w-screen {
  width: 100vw;
}
.roam-w-svw {
  width: 100svw;
}
.roam-w-lvw {
  width: 100lvw;
}
.roam-w-dvw {
  width: 100dvw;
}
.roam-w-min {
  width: min-content;
}
.roam-w-max {
  width: max-content;
}
.roam-w-fit {
  width: fit-content;
}

// https://tailwindcss.com/docs/min-width
.roam-min-w-0 {
  min-width: 0px;
}
.roam-min-w-1 {
  min-width: 0.25rem; /* 4px */
}
.roam-min-w-2 {
  min-width: 0.5rem; /* 8px */
}
.roam-min-w-3 {
  min-width: 0.75rem; /* 12px */
}
.roam-min-w-4 {
  min-width: 1rem; /* 16px */
}
.roam-min-w-5 {
  min-width: 1.25rem; /* 20px */
}
.roam-min-w-6 {
  min-width: 1.5rem; /* 24px */
}
.roam-min-w-7 {
  min-width: 1.75rem; /* 28px */
}
.roam-min-w-8 {
  min-width: 2rem; /* 32px */
}
.roam-min-w-9 {
  min-width: 2.25rem; /* 36px */
}
.roam-min-w-10 {
  min-width: 2.5rem; /* 40px */
}
.roam-min-w-11 {
  min-width: 2.75rem; /* 44px */
}
.roam-min-w-12 {
  min-width: 3rem; /* 48px */
}
.roam-min-w-14 {
  min-width: 3.5rem; /* 56px */
}
.roam-min-w-16 {
  min-width: 4rem; /* 64px */
}
.roam-min-w-20 {
  min-width: 5rem; /* 80px */
}
.roam-min-w-24 {
  min-width: 6rem; /* 96px */
}
.roam-min-w-28 {
  min-width: 7rem; /* 112px */
}
.roam-min-w-32 {
  min-width: 8rem; /* 128px */
}
.roam-min-w-36 {
  min-width: 9rem; /* 144px */
}
.roam-min-w-40 {
  min-width: 10rem; /* 160px */
}
.roam-min-w-44 {
  min-width: 11rem; /* 176px */
}
.roam-min-w-48 {
  min-width: 12rem; /* 192px */
}
.roam-min-w-52 {
  min-width: 13rem; /* 208px */
}
.roam-min-w-56 {
  min-width: 14rem; /* 224px */
}
.roam-min-w-60 {
  min-width: 15rem; /* 240px */
}
.roam-min-w-64 {
  min-width: 16rem; /* 256px */
}
.roam-min-w-72 {
  min-width: 18rem; /* 288px */
}
.roam-min-w-80 {
  min-width: 20rem; /* 320px */
}
.roam-min-w-96 {
  min-width: 24rem; /* 384px */
}
.roam-min-w-px {
  min-width: 1px;
}
.roam-min-w-0\.5 {
  min-width: 0.125rem; /* 2px */
}
.roam-min-w-1\.5 {
  min-width: 0.375rem; /* 6px */
}
.roam-min-w-2\.5 {
  min-width: 0.625rem; /* 10px */
}
.roam-min-w-3\.5 {
  min-width: 0.875rem; /* 14px */
}
.roam-min-w-full {
  min-width: 100%;
}
.roam-min-w-min {
  min-width: min-content;
}
.roam-min-w-max {
  min-width: max-content;
}
.roam-min-w-fit {
  min-width: fit-content;
}

// https://tailwindcss.com/docs/max-width
.roam-max-w-0 {
  max-width: 0px;
}
.roam-max-w-px {
  max-width: 1px;
}
.roam-max-w-0\.5 {
  max-width: 0.125rem; /* 2px */
}
.roam-max-w-1 {
  max-width: 0.25rem; /* 4px */
}
.roam-max-w-1\.5 {
  max-width: 0.375rem; /* 6px */
}
.roam-max-w-2 {
  max-width: 0.5rem; /* 8px */
}
.roam-max-w-2\.5 {
  max-width: 0.625rem; /* 10px */
}
.roam-max-w-3 {
  max-width: 0.75rem; /* 12px */
}
.roam-max-w-3\.5 {
  max-width: 0.875rem; /* 14px */
}
.roam-max-w-4 {
  max-width: 1rem; /* 16px */
}
.roam-max-w-5 {
  max-width: 1.25rem; /* 20px */
}
.roam-max-w-6 {
  max-width: 1.5rem; /* 24px */
}
.roam-max-w-7 {
  max-width: 1.75rem; /* 28px */
}
.roam-max-w-8 {
  max-width: 2rem; /* 32px */
}
.roam-max-w-9 {
  max-width: 2.25rem; /* 36px */
}
.roam-max-w-10 {
  max-width: 2.5rem; /* 40px */
}
.roam-max-w-11 {
  max-width: 2.75rem; /* 44px */
}
.roam-max-w-12 {
  max-width: 3rem; /* 48px */
}
.roam-max-w-14 {
  max-width: 3.5rem; /* 56px */
}
.roam-max-w-16 {
  max-width: 4rem; /* 64px */
}
.roam-max-w-20 {
  max-width: 5rem; /* 80px */
}
.roam-max-w-24 {
  max-width: 6rem; /* 96px */
}
.roam-max-w-28 {
  max-width: 7rem; /* 112px */
}
.roam-max-w-32 {
  max-width: 8rem; /* 128px */
}
.roam-max-w-36 {
  max-width: 9rem; /* 144px */
}
.roam-max-w-40 {
  max-width: 10rem; /* 160px */
}
.roam-max-w-44 {
  max-width: 11rem; /* 176px */
}
.roam-max-w-48 {
  max-width: 12rem; /* 192px */
}
.roam-max-w-52 {
  max-width: 13rem; /* 208px */
}
.roam-max-w-56 {
  max-width: 14rem; /* 224px */
}
.roam-max-w-60 {
  max-width: 15rem; /* 240px */
}
.roam-max-w-64 {
  max-width: 16rem; /* 256px */
}
.roam-max-w-72 {
  max-width: 18rem; /* 288px */
}
.roam-max-w-80 {
  max-width: 20rem; /* 320px */
}
.roam-max-w-96 {
  max-width: 24rem; /* 384px */
}
.roam-max-w-none {
  max-width: none;
}
.roam-max-w-xs {
  max-width: 20rem; /* 320px */
}
.roam-max-w-sm {
  max-width: 24rem; /* 384px */
}
.roam-max-w-md {
  max-width: 28rem; /* 448px */
}
.roam-max-w-lg {
  max-width: 32rem; /* 512px */
}
.roam-max-w-xl {
  max-width: 36rem; /* 576px */
}
.roam-max-w-2xl {
  max-width: 42rem; /* 672px */
}
.roam-max-w-3xl {
  max-width: 48rem; /* 768px */
}
.roam-max-w-4xl {
  max-width: 56rem; /* 896px */
}
.roam-max-w-5xl {
  max-width: 64rem; /* 1024px */
}
.roam-max-w-6xl {
  max-width: 72rem; /* 1152px */
}
.roam-max-w-7xl {
  max-width: 80rem; /* 1280px */
}
.roam-max-w-full {
  max-width: 100%;
}
.roam-max-w-min {
  max-width: min-content;
}
.roam-max-w-max {
  max-width: max-content;
}
.roam-max-w-fit {
  max-width: fit-content;
}
.roam-max-w-prose {
  max-width: 65ch;
}
.roam-max-w-screen-sm {
  max-width: 640px;
}
.roam-max-w-screen-md {
  max-width: 768px;
}
.roam-max-w-screen-lg {
  max-width: 1024px;
}
.roam-max-w-screen-xl {
  max-width: 1280px;
}
.roam-max-w-screen-2xl {
  max-width: 1536px;
}

// https://tailwindcss.com/docs/height
.roam-h-0 {
  height: 0px;
}
.roam-h-px {
  height: 1px;
}
.roam-h-0\.5 {
  height: 0.125rem; /* 2px */
}
.roam-h-1 {
  height: 0.25rem; /* 4px */
}
.roam-h-1\.5 {
  height: 0.375rem; /* 6px */
}
.roam-h-2 {
  height: 0.5rem; /* 8px */
}
.roam-h-2\.5 {
  height: 0.625rem; /* 10px */
}
.roam-h-3 {
  height: 0.75rem; /* 12px */
}
.roam-h-3\.5 {
  height: 0.875rem; /* 14px */
}
.roam-h-4 {
  height: 1rem; /* 16px */
}
.roam-h-5 {
  height: 1.25rem; /* 20px */
}
.roam-h-6 {
  height: 1.5rem; /* 24px */
}
.roam-h-7 {
  height: 1.75rem; /* 28px */
}
.roam-h-8 {
  height: 2rem; /* 32px */
}
.roam-h-9 {
  height: 2.25rem; /* 36px */
}
.roam-h-10 {
  height: 2.5rem; /* 40px */
}
.roam-h-11 {
  height: 2.75rem; /* 44px */
}
.roam-h-12 {
  height: 3rem; /* 48px */
}
.roam-h-14 {
  height: 3.5rem; /* 56px */
}
.roam-h-16 {
  height: 4rem; /* 64px */
}
.roam-h-20 {
  height: 5rem; /* 80px */
}
.roam-h-24 {
  height: 6rem; /* 96px */
}
.roam-h-28 {
  height: 7rem; /* 112px */
}
.roam-h-32 {
  height: 8rem; /* 128px */
}
.roam-h-36 {
  height: 9rem; /* 144px */
}
.roam-h-40 {
  height: 10rem; /* 160px */
}
.roam-h-44 {
  height: 11rem; /* 176px */
}
.roam-h-48 {
  height: 12rem; /* 192px */
}
.roam-h-52 {
  height: 13rem; /* 208px */
}
.roam-h-56 {
  height: 14rem; /* 224px */
}
.roam-h-60 {
  height: 15rem; /* 240px */
}
.roam-h-64 {
  height: 16rem; /* 256px */
}
.roam-h-72 {
  height: 18rem; /* 288px */
}
.roam-h-80 {
  height: 20rem; /* 320px */
}
.roam-h-96 {
  height: 24rem; /* 384px */
}
.roam-h-auto {
  height: auto;
}
.roam-h-1\/2 {
  height: 50%;
}
.roam-h-1\/3 {
  height: 33.333333%;
}
.roam-h-2\/3 {
  height: 66.666667%;
}
.roam-h-1\/4 {
  height: 25%;
}
.roam-h-2\/4 {
  height: 50%;
}
.roam-h-3\/4 {
  height: 75%;
}
.roam-h-1\/5 {
  height: 20%;
}
.roam-h-2\/5 {
  height: 40%;
}
.roam-h-3\/5 {
  height: 60%;
}
.roam-h-4\/5 {
  height: 80%;
}
.roam-h-1\/6 {
  height: 16.666667%;
}
.roam-h-2\/6 {
  height: 33.333333%;
}
.roam-h-3\/6 {
  height: 50%;
}
.roam-h-4\/6 {
  height: 66.666667%;
}
.roam-h-5\/6 {
  height: 83.333333%;
}
.roam-h-full {
  height: 100%;
}
.roam-h-screen {
  height: 100vh;
}
.roam-h-svh {
  height: 100svh;
}
.roam-h-lvh {
  height: 100lvh;
}
.roam-h-dvh {
  height: 100dvh;
}
.roam-h-min {
  height: min-content;
}
.roam-h-max {
  height: max-content;
}
.roam-h-fit {
  height: fit-content;
}

// https://tailwindcss.com/docs/min-height
.roam-min-h-0 {
  min-height: 0px;
}
.roam-min-h-1 {
  min-height: 0.25rem; /* 4px */
}
.roam-min-h-2 {
  min-height: 0.5rem; /* 8px */
}
.roam-min-h-3 {
  min-height: 0.75rem; /* 12px */
}
.roam-min-h-4 {
  min-height: 1rem; /* 16px */
}
.roam-min-h-5 {
  min-height: 1.25rem; /* 20px */
}
.roam-min-h-6 {
  min-height: 1.5rem; /* 24px */
}
.roam-min-h-7 {
  min-height: 1.75rem; /* 28px */
}
.roam-min-h-8 {
  min-height: 2rem; /* 32px */
}
.roam-min-h-9 {
  min-height: 2.25rem; /* 36px */
}
.roam-min-h-10 {
  min-height: 2.5rem; /* 40px */
}
.roam-min-h-11 {
  min-height: 2.75rem; /* 44px */
}
.roam-min-h-12 {
  min-height: 3rem; /* 48px */
}
.roam-min-h-14 {
  min-height: 3.5rem; /* 56px */
}
.roam-min-h-16 {
  min-height: 4rem; /* 64px */
}
.roam-min-h-20 {
  min-height: 5rem; /* 80px */
}
.roam-min-h-24 {
  min-height: 6rem; /* 96px */
}
.roam-min-h-28 {
  min-height: 7rem; /* 112px */
}
.roam-min-h-32 {
  min-height: 8rem; /* 128px */
}
.roam-min-h-36 {
  min-height: 9rem; /* 144px */
}
.roam-min-h-40 {
  min-height: 10rem; /* 160px */
}
.roam-min-h-44 {
  min-height: 11rem; /* 176px */
}
.roam-min-h-48 {
  min-height: 12rem; /* 192px */
}
.roam-min-h-52 {
  min-height: 13rem; /* 208px */
}
.roam-min-h-56 {
  min-height: 14rem; /* 224px */
}
.roam-min-h-60 {
  min-height: 15rem; /* 240px */
}
.roam-min-h-64 {
  min-height: 16rem; /* 256px */
}
.roam-min-h-72 {
  min-height: 18rem; /* 288px */
}
.roam-min-h-80 {
  min-height: 20rem; /* 320px */
}
.roam-min-h-96 {
  min-height: 24rem; /* 384px */
}
.roam-min-h-px {
  min-height: 1px;
}
.roam-min-h-0\.5 {
  min-height: 0.125rem; /* 2px */
}
.roam-min-h-1\.5 {
  min-height: 0.375rem; /* 6px */
}
.roam-min-h-2\.5 {
  min-height: 0.625rem; /* 10px */
}
.roam-min-h-3\.5 {
  min-height: 0.875rem; /* 14px */
}
.roam-min-h-full {
  min-height: 100%;
}
.roam-min-h-screen {
  min-height: 100vh;
}
.roam-min-h-svh {
  min-height: 100svh;
}
.roam-min-h-lvh {
  min-height: 100lvh;
}
.roam-min-h-dvh {
  min-height: 100dvh;
}
.roam-min-h-min {
  min-height: min-content;
}
.roam-min-h-max {
  min-height: max-content;
}
.roam-min-h-fit {
  min-height: fit-content;
}

// https://tailwindcss.com/docs/max-height
.roam-max-h-0 {
  max-height: 0px;
}
.roam-max-h-px {
  max-height: 1px;
}
.roam-max-h-0\.5 {
  max-height: 0.125rem; /* 2px */
}
.roam-max-h-1 {
  max-height: 0.25rem; /* 4px */
}
.roam-max-h-1\.5 {
  max-height: 0.375rem; /* 6px */
}
.roam-max-h-2 {
  max-height: 0.5rem; /* 8px */
}
.roam-max-h-2\.5 {
  max-height: 0.625rem; /* 10px */
}
.roam-max-h-3 {
  max-height: 0.75rem; /* 12px */
}
.roam-max-h-3\.5 {
  max-height: 0.875rem; /* 14px */
}
.roam-max-h-4 {
  max-height: 1rem; /* 16px */
}
.roam-max-h-5 {
  max-height: 1.25rem; /* 20px */
}
.roam-max-h-6 {
  max-height: 1.5rem; /* 24px */
}
.roam-max-h-7 {
  max-height: 1.75rem; /* 28px */
}
.roam-max-h-8 {
  max-height: 2rem; /* 32px */
}
.roam-max-h-9 {
  max-height: 2.25rem; /* 36px */
}
.roam-max-h-10 {
  max-height: 2.5rem; /* 40px */
}
.roam-max-h-11 {
  max-height: 2.75rem; /* 44px */
}
.roam-max-h-12 {
  max-height: 3rem; /* 48px */
}
.roam-max-h-14 {
  max-height: 3.5rem; /* 56px */
}
.roam-max-h-16 {
  max-height: 4rem; /* 64px */
}
.roam-max-h-20 {
  max-height: 5rem; /* 80px */
}
.roam-max-h-24 {
  max-height: 6rem; /* 96px */
}
.roam-max-h-28 {
  max-height: 7rem; /* 112px */
}
.roam-max-h-32 {
  max-height: 8rem; /* 128px */
}
.roam-max-h-36 {
  max-height: 9rem; /* 144px */
}
.roam-max-h-40 {
  max-height: 10rem; /* 160px */
}
.roam-max-h-44 {
  max-height: 11rem; /* 176px */
}
.roam-max-h-48 {
  max-height: 12rem; /* 192px */
}
.roam-max-h-52 {
  max-height: 13rem; /* 208px */
}
.roam-max-h-56 {
  max-height: 14rem; /* 224px */
}
.roam-max-h-60 {
  max-height: 15rem; /* 240px */
}
.roam-max-h-64 {
  max-height: 16rem; /* 256px */
}
.roam-max-h-72 {
  max-height: 18rem; /* 288px */
}
.roam-max-h-80 {
  max-height: 20rem; /* 320px */
}
.roam-max-h-96 {
  max-height: 24rem; /* 384px */
}
.roam-max-h-none {
  max-height: none;
}
.roam-max-h-full {
  max-height: 100%;
}
.roam-max-h-screen {
  max-height: 100vh;
}
.roam-max-h-svh {
  max-height: 100svh;
}
.roam-max-h-lvh {
  max-height: 100lvh;
}
.roam-max-h-dvh {
  max-height: 100dvh;
}
.roam-max-h-min {
  max-height: min-content;
}
.roam-max-h-max {
  max-height: max-content;
}
.roam-max-h-fit {
  max-height: fit-content;
}

// https://tailwindcss.com/docs/size
.roam-size-0 {
  width: 0px;
  height: 0px;
}
.roam-size-px {
  width: 1px;
  height: 1px;
}
.roam-size-0\.5 {
  width: 0.125rem; /* 2px */
  height: 0.125rem; /* 2px */
}
.roam-size-1 {
  width: 0.25rem; /* 4px */
  height: 0.25rem; /* 4px */
}
.roam-size-1\.5 {
  width: 0.375rem; /* 6px */
  height: 0.375rem; /* 6px */
}
.roam-size-2 {
  width: 0.5rem; /* 8px */
  height: 0.5rem; /* 8px */
}
.roam-size-2\.5 {
  width: 0.625rem; /* 10px */
  height: 0.625rem; /* 10px */
}
.roam-size-3 {
  width: 0.75rem; /* 12px */
  height: 0.75rem; /* 12px */
}
.roam-size-3\.5 {
  width: 0.875rem; /* 14px */
  height: 0.875rem; /* 14px */
}
.roam-size-4 {
  width: 1rem; /* 16px */
  height: 1rem; /* 16px */
}
.roam-size-5 {
  width: 1.25rem; /* 20px */
  height: 1.25rem; /* 20px */
}
.roam-size-6 {
  width: 1.5rem; /* 24px */
  height: 1.5rem; /* 24px */
}
.roam-size-7 {
  width: 1.75rem; /* 28px */
  height: 1.75rem; /* 28px */
}
.roam-size-8 {
  width: 2rem; /* 32px */
  height: 2rem; /* 32px */
}
.roam-size-9 {
  width: 2.25rem; /* 36px */
  height: 2.25rem; /* 36px */
}
.roam-size-10 {
  width: 2.5rem; /* 40px */
  height: 2.5rem; /* 40px */
}
.roam-size-11 {
  width: 2.75rem; /* 44px */
  height: 2.75rem; /* 44px */
}
.roam-size-12 {
  width: 3rem; /* 48px */
  height: 3rem; /* 48px */
}
.roam-size-14 {
  width: 3.5rem; /* 56px */
  height: 3.5rem; /* 56px */
}
.roam-size-16 {
  width: 4rem; /* 64px */
  height: 4rem; /* 64px */
}
.roam-size-20 {
  width: 5rem; /* 80px */
  height: 5rem; /* 80px */
}
.roam-size-24 {
  width: 6rem; /* 96px */
  height: 6rem; /* 96px */
}
.roam-size-28 {
  width: 7rem; /* 112px */
  height: 7rem; /* 112px */
}
.roam-size-32 {
  width: 8rem; /* 128px */
  height: 8rem; /* 128px */
}
.roam-size-36 {
  width: 9rem; /* 144px */
  height: 9rem; /* 144px */
}
.roam-size-40 {
  width: 10rem; /* 160px */
  height: 10rem; /* 160px */
}
.roam-size-44 {
  width: 11rem; /* 176px */
  height: 11rem; /* 176px */
}
.roam-size-48 {
  width: 12rem; /* 192px */
  height: 12rem; /* 192px */
}
.roam-size-52 {
  width: 13rem; /* 208px */
  height: 13rem; /* 208px */
}
.roam-size-56 {
  width: 14rem; /* 224px */
  height: 14rem; /* 224px */
}
.roam-size-60 {
  width: 15rem; /* 240px */
  height: 15rem; /* 240px */
}
.roam-size-64 {
  width: 16rem; /* 256px */
  height: 16rem; /* 256px */
}
.roam-size-72 {
  width: 18rem; /* 288px */
  height: 18rem; /* 288px */
}
.roam-size-80 {
  width: 20rem; /* 320px */
  height: 20rem; /* 320px */
}
.roam-size-96 {
  width: 24rem; /* 384px */
  height: 24rem; /* 384px */
}
.roam-size-auto {
  width: auto;
  height: auto;
}
.roam-size-1\/2 {
  width: 50%;
  height: 50%;
}
.roam-size-1\/3 {
  width: 33.333333%;
  height: 33.333333%;
}
.roam-size-2\/3 {
  width: 66.666667%;
  height: 66.666667%;
}
.roam-size-1\/4 {
  width: 25%;
  height: 25%;
}
.roam-size-2\/4 {
  width: 50%;
  height: 50%;
}
.roam-size-3\/4 {
  width: 75%;
  height: 75%;
}
.roam-size-1\/5 {
  width: 20%;
  height: 20%;
}
.roam-size-2\/5 {
  width: 40%;
  height: 40%;
}
.roam-size-3\/5 {
  width: 60%;
  height: 60%;
}
.roam-size-4\/5 {
  width: 80%;
  height: 80%;
}
.roam-size-1\/6 {
  width: 16.666667%;
  height: 16.666667%;
}
.roam-size-2\/6 {
  width: 33.333333%;
  height: 33.333333%;
}
.roam-size-3\/6 {
  width: 50%;
  height: 50%;
}
.roam-size-4\/6 {
  width: 66.666667%;
  height: 66.666667%;
}
.roam-size-5\/6 {
  width: 83.333333%;
  height: 83.333333%;
}
.roam-size-1\/12 {
  width: 8.333333%;
  height: 8.333333%;
}
.roam-size-2\/12 {
  width: 16.666667%;
  height: 16.666667%;
}
.roam-size-3\/12 {
  width: 25%;
  height: 25%;
}
.roam-size-4\/12 {
  width: 33.333333%;
  height: 33.333333%;
}
.roam-size-5\/12 {
  width: 41.666667%;
  height: 41.666667%;
}
.roam-size-6\/12 {
  width: 50%;
  height: 50%;
}
.roam-size-7\/12 {
  width: 58.333333%;
  height: 58.333333%;
}
.roam-size-8\/12 {
  width: 66.666667%;
  height: 66.666667%;
}
.roam-size-9\/12 {
  width: 75%;
  height: 75%;
}
.roam-size-10\/12 {
  width: 83.333333%;
  height: 83.333333%;
}
.roam-size-11\/12 {
  width: 91.666667%;
  height: 91.666667%;
}
.roam-size-full {
  width: 100%;
  height: 100%;
}
.roam-size-min {
  width: min-content;
  height: min-content;
}
.roam-size-max {
  width: max-content;
  height: max-content;
}
.roam-size-fit {
  width: fit-content;
  height: fit-content;
}

/*
* ================= TYPOGRAPHY ===============================================
*/

// https://tailwindcss.com/docs/font-family
.roam-font-sans {
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.roam-font-serif {
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
}
.roam-font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

// https://tailwindcss.com/docs/font-weight
.roam-font-thin {
  font-weight: 100 !important;
}
.roam-font-extralight {
  font-weight: 200 !important;
}
.roam-font-light {
  font-weight: 300 !important;
}
.roam-font-normal {
  font-weight: 400 !important;
}
.roam-font-medium {
  font-weight: 500 !important;
}
.roam-font-semibold {
  font-weight: 600 !important;
}
.roam-font-bold {
  font-weight: 700 !important;
}
.roam-font-extrabold {
  font-weight: 800 !important;
}
.roam-font-black {
  font-weight: 900 !important;
}

// https://tailwindcss.com/docs/letter-spacing
.roam-tracking-tighter {
  letter-spacing: -0.05em;
}
.roam-tracking-tight {
  letter-spacing: -0.025em;
}
.roam-tracking-normal {
  letter-spacing: 0em;
}
.roam-tracking-wide {
  letter-spacing: 0.025em;
}
.roam-tracking-wider {
  letter-spacing: 0.05em;
}
.roam-tracking-widest {
  letter-spacing: 0.1em;
}

// https://tailwindcss.com/docs/text-align
.roam-text-left {
  text-align: left;
}
.roam-text-center {
  text-align: center;
}
.roam-text-right {
  text-align: right;
}
.roam-text-justify {
  text-align: justify;
}
.roam-text-start {
  text-align: start;
}
.roam-text-end {
  text-align: end;
}

// https://tailwindcss.com/docs/text-decoration
.roam-underline {
  text-decoration-line: underline;
}
.roam-overline {
  text-decoration-line: overline;
}
.roam-line-through {
  text-decoration-line: line-through;
}
.roam-no-underline {
  text-decoration-line: none;
}

// https://tailwindcss.com/docs/text-transform
.roam-uppercase {
  text-transform: uppercase;
}
.roam-lowercase {
  text-transform: lowercase;
}
.roam-capitalize {
  text-transform: capitalize;
}
.roam-normal-case {
  text-transform: none;
}

// https://tailwindcss.com/docs/font-size
.roam-text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}
.roam-text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}
.roam-text-base {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}
.roam-text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}
.roam-text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}
.roam-text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}
.roam-text-3xl {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}
.roam-text-4xl {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}
.roam-text-5xl {
  font-size: 3rem; /* 48px */
  line-height: 1;
}
.roam-text-6xl {
  font-size: 3.75rem; /* 60px */
  line-height: 1;
}
.roam-text-7xl {
  font-size: 4.5rem; /* 72px */
  line-height: 1;
}
.roam-text-8xl {
  font-size: 6rem; /* 96px */
  line-height: 1;
}
.roam-text-9xl {
  font-size: 8rem; /* 128px */
  line-height: 1;
}

// https://tailwindcss.com/docs/line-height
.roam-leading-3 {
  line-height: 0.75rem; /* 12px */
}
.roam-leading-4 {
  line-height: 1rem; /* 16px */
}
.roam-leading-5 {
  line-height: 1.25rem; /* 20px */
}
.roam-leading-6 {
  line-height: 1.5rem; /* 24px */
}
.roam-leading-7 {
  line-height: 1.75rem; /* 28px */
}
.roam-leading-8 {
  line-height: 2rem; /* 32px */
}
.roam-leading-9 {
  line-height: 2.25rem; /* 36px */
}
.roam-leading-10 {
  line-height: 2.5rem; /* 40px */
}
.roam-leading-none {
  line-height: 1;
}
.roam-leading-tight {
  line-height: 1.25;
}
.roam-leading-snug {
  line-height: 1.375;
}
.roam-leading-normal {
  line-height: 1.5;
}
.roam-leading-relaxed {
  line-height: 1.625;
}
.roam-leading-loose {
  line-height: 2;
}

// https://tailwindcss.com/docs/whitespace
.roam-whitespace-normal {
  white-space: normal;
}
.roam-whitespace-nowrap {
  white-space: nowrap;
}
.roam-whitespace-pre {
  white-space: pre;
}
.roam-whitespace-pre-line {
  white-space: pre-line;
}
.roam-whitespace-pre-wrap {
  white-space: pre-wrap;
}
.roam-whitespace-break-spaces {
  white-space: break-spaces;
}

// https://tailwindcss.com/docs/line-clamp
.roam-line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}
.roam-line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.roam-line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
.roam-line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
}
.roam-line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-clamp: 5;
}
.roam-line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  line-clamp: 6;
}
.roam-line-clamp-none {
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none;
  line-clamp: none;
}

/*
* ================= EFFECTS ==============================================
*/

// https://tailwindcss.com/docs/box-shadow
.roam-shadow-sm {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}
.roam-shadow {
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.roam-shadow-md {
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.roam-shadow-lg {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.roam-shadow-xl {
  box-shadow:
    0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}
.roam-shadow-2xl {
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}
.roam-shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}
.roam-shadow-none {
  box-shadow: 0 0 #0000;
}

// https://tailwindcss.com/docs/opacity
.roam-opacity-0 {
  opacity: 0;
}
.roam-opacity-5 {
  opacity: 0.05;
}
.roam-opacity-10 {
  opacity: 0.1;
}
.roam-opacity-15 {
  opacity: 0.15;
}
.roam-opacity-20 {
  opacity: 0.2;
}
.roam-opacity-25 {
  opacity: 0.25;
}
.roam-opacity-30 {
  opacity: 0.3;
}
.roam-opacity-35 {
  opacity: 0.35;
}
.roam-opacity-40 {
  opacity: 0.4;
}
.roam-opacity-45 {
  opacity: 0.45;
}
.roam-opacity-50 {
  opacity: 0.5;
}
.roam-opacity-55 {
  opacity: 0.55;
}
.roam-opacity-60 {
  opacity: 0.6;
}
.roam-opacity-65 {
  opacity: 0.65;
}
.roam-opacity-70 {
  opacity: 0.7;
}
.roam-opacity-75 {
  opacity: 0.75;
}
.roam-opacity-80 {
  opacity: 0.8;
}
.roam-opacity-85 {
  opacity: 0.85;
}
.roam-opacity-90 {
  opacity: 0.9;
}
.roam-opacity-95 {
  opacity: 0.95;
}
.roam-opacity-100 {
  opacity: 1;
}

/*
* ================= BORDERS ==============================================
*/

// https://tailwindcss.com/docs/border-radius
.roam-rounded-none {
  border-radius: 0px;
}
.roam-rounded-sm {
  border-radius: 0.125rem; /* 2px */
}
.roam-rounded {
  border-radius: 0.25rem; /* 4px */
}
.roam-rounded-md {
  border-radius: 0.375rem; /* 6px */
}
.roam-rounded-lg {
  border-radius: 0.5rem; /* 8px */
}
.roam-rounded-xl {
  border-radius: 0.75rem; /* 12px */
}
.roam-rounded-2xl {
  border-radius: 1rem; /* 16px */
}
.roam-rounded-3xl {
  border-radius: 1.5rem; /* 24px */
}
.roam-rounded-full {
  border-radius: 9999px;
}
.roam-rounded-s-none {
  border-start-start-radius: 0px;
  border-end-start-radius: 0px;
}
.roam-rounded-s-sm {
  border-start-start-radius: 0.125rem; /* 2px */
  border-end-start-radius: 0.125rem; /* 2px */
}
.roam-rounded-s {
  border-start-start-radius: 0.25rem; /* 4px */
  border-end-start-radius: 0.25rem; /* 4px */
}
.roam-rounded-s-md {
  border-start-start-radius: 0.375rem; /* 6px */
  border-end-start-radius: 0.375rem; /* 6px */
}
.roam-rounded-s-lg {
  border-start-start-radius: 0.5rem; /* 8px */
  border-end-start-radius: 0.5rem; /* 8px */
}
.roam-rounded-s-xl {
  border-start-start-radius: 0.75rem; /* 12px */
  border-end-start-radius: 0.75rem; /* 12px */
}
.roam-rounded-s-2xl {
  border-start-start-radius: 1rem; /* 16px */
  border-end-start-radius: 1rem; /* 16px */
}
.roam-rounded-s-3xl {
  border-start-start-radius: 1.5rem; /* 24px */
  border-end-start-radius: 1.5rem; /* 24px */
}
.roam-rounded-s-full {
  border-start-start-radius: 9999px;
  border-end-start-radius: 9999px;
}
.roam-rounded-e-none {
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
}
.roam-rounded-e-sm {
  border-start-end-radius: 0.125rem; /* 2px */
  border-end-end-radius: 0.125rem; /* 2px */
}
.roam-rounded-e {
  border-start-end-radius: 0.25rem; /* 4px */
  border-end-end-radius: 0.25rem; /* 4px */
}
.roam-rounded-e-md {
  border-start-end-radius: 0.375rem; /* 6px */
  border-end-end-radius: 0.375rem; /* 6px */
}
.roam-rounded-e-lg {
  border-start-end-radius: 0.5rem; /* 8px */
  border-end-end-radius: 0.5rem; /* 8px */
}
.roam-rounded-e-xl {
  border-start-end-radius: 0.75rem; /* 12px */
  border-end-end-radius: 0.75rem; /* 12px */
}
.roam-rounded-e-2xl {
  border-start-end-radius: 1rem; /* 16px */
  border-end-end-radius: 1rem; /* 16px */
}
.roam-rounded-e-3xl {
  border-start-end-radius: 1.5rem; /* 24px */
  border-end-end-radius: 1.5rem; /* 24px */
}
.roam-rounded-e-full {
  border-start-end-radius: 9999px;
  border-end-end-radius: 9999px;
}
.roam-rounded-t-none {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.roam-rounded-t-sm {
  border-top-left-radius: 0.125rem; /* 2px */
  border-top-right-radius: 0.125rem; /* 2px */
}
.roam-rounded-t {
  border-top-left-radius: 0.25rem; /* 4px */
  border-top-right-radius: 0.25rem; /* 4px */
}
.roam-rounded-t-md {
  border-top-left-radius: 0.375rem; /* 6px */
  border-top-right-radius: 0.375rem; /* 6px */
}
.roam-rounded-t-lg {
  border-top-left-radius: 0.5rem; /* 8px */
  border-top-right-radius: 0.5rem; /* 8px */
}
.roam-rounded-t-xl {
  border-top-left-radius: 0.75rem; /* 12px */
  border-top-right-radius: 0.75rem; /* 12px */
}
.roam-rounded-t-2xl {
  border-top-left-radius: 1rem; /* 16px */
  border-top-right-radius: 1rem; /* 16px */
}
.roam-rounded-t-3xl {
  border-top-left-radius: 1.5rem; /* 24px */
  border-top-right-radius: 1.5rem; /* 24px */
}
.roam-rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}
.roam-rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.roam-rounded-r-sm {
  border-top-right-radius: 0.125rem; /* 2px */
  border-bottom-right-radius: 0.125rem; /* 2px */
}
.roam-rounded-r {
  border-top-right-radius: 0.25rem; /* 4px */
  border-bottom-right-radius: 0.25rem; /* 4px */
}
.roam-rounded-r-md {
  border-top-right-radius: 0.375rem; /* 6px */
  border-bottom-right-radius: 0.375rem; /* 6px */
}
.roam-rounded-r-lg {
  border-top-right-radius: 0.5rem; /* 8px */
  border-bottom-right-radius: 0.5rem; /* 8px */
}
.roam-rounded-r-xl {
  border-top-right-radius: 0.75rem; /* 12px */
  border-bottom-right-radius: 0.75rem; /* 12px */
}
.roam-rounded-r-2xl {
  border-top-right-radius: 1rem; /* 16px */
  border-bottom-right-radius: 1rem; /* 16px */
}
.roam-rounded-r-3xl {
  border-top-right-radius: 1.5rem; /* 24px */
  border-bottom-right-radius: 1.5rem; /* 24px */
}
.roam-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}
.roam-rounded-b-none {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.roam-rounded-b-sm {
  border-bottom-right-radius: 0.125rem; /* 2px */
  border-bottom-left-radius: 0.125rem; /* 2px */
}
.roam-rounded-b {
  border-bottom-right-radius: 0.25rem; /* 4px */
  border-bottom-left-radius: 0.25rem; /* 4px */
}
.roam-rounded-b-md {
  border-bottom-right-radius: 0.375rem; /* 6px */
  border-bottom-left-radius: 0.375rem; /* 6px */
}
.roam-rounded-b-lg {
  border-bottom-right-radius: 0.5rem; /* 8px */
  border-bottom-left-radius: 0.5rem; /* 8px */
}
.roam-rounded-b-xl {
  border-bottom-right-radius: 0.75rem; /* 12px */
  border-bottom-left-radius: 0.75rem; /* 12px */
}
.roam-rounded-b-2xl {
  border-bottom-right-radius: 1rem; /* 16px */
  border-bottom-left-radius: 1rem; /* 16px */
}
.roam-rounded-b-3xl {
  border-bottom-right-radius: 1.5rem; /* 24px */
  border-bottom-left-radius: 1.5rem; /* 24px */
}
.roam-rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}
.roam-rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.roam-rounded-l-sm {
  border-top-left-radius: 0.125rem; /* 2px */
  border-bottom-left-radius: 0.125rem; /* 2px */
}
.roam-rounded-l {
  border-top-left-radius: 0.25rem; /* 4px */
  border-bottom-left-radius: 0.25rem; /* 4px */
}
.roam-rounded-l-md {
  border-top-left-radius: 0.375rem; /* 6px */
  border-bottom-left-radius: 0.375rem; /* 6px */
}
.roam-rounded-l-lg {
  border-top-left-radius: 0.5rem; /* 8px */
  border-bottom-left-radius: 0.5rem; /* 8px */
}
.roam-rounded-l-xl {
  border-top-left-radius: 0.75rem; /* 12px */
  border-bottom-left-radius: 0.75rem; /* 12px */
}
.roam-rounded-l-2xl {
  border-top-left-radius: 1rem; /* 16px */
  border-bottom-left-radius: 1rem; /* 16px */
}
.roam-rounded-l-3xl {
  border-top-left-radius: 1.5rem; /* 24px */
  border-bottom-left-radius: 1.5rem; /* 24px */
}
.roam-rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}
.roam-rounded-ss-none {
  border-start-start-radius: 0px;
}
.roam-rounded-ss-sm {
  border-start-start-radius: 0.125rem; /* 2px */
}
.roam-rounded-ss {
  border-start-start-radius: 0.25rem; /* 4px */
}
.roam-rounded-ss-md {
  border-start-start-radius: 0.375rem; /* 6px */
}
.roam-rounded-ss-lg {
  border-start-start-radius: 0.5rem; /* 8px */
}
.roam-rounded-ss-xl {
  border-start-start-radius: 0.75rem; /* 12px */
}
.roam-rounded-ss-2xl {
  border-start-start-radius: 1rem; /* 16px */
}
.roam-rounded-ss-3xl {
  border-start-start-radius: 1.5rem; /* 24px */
}
.roam-rounded-ss-full {
  border-start-start-radius: 9999px;
}
.roam-rounded-se-none {
  border-start-end-radius: 0px;
}
.roam-rounded-se-sm {
  border-start-end-radius: 0.125rem; /* 2px */
}
.roam-rounded-se {
  border-start-end-radius: 0.25rem; /* 4px */
}
.roam-rounded-se-md {
  border-start-end-radius: 0.375rem; /* 6px */
}
.roam-rounded-se-lg {
  border-start-end-radius: 0.5rem; /* 8px */
}
.roam-rounded-se-xl {
  border-start-end-radius: 0.75rem; /* 12px */
}
.roam-rounded-se-2xl {
  border-start-end-radius: 1rem; /* 16px */
}
.roam-rounded-se-3xl {
  border-start-end-radius: 1.5rem; /* 24px */
}
.roam-rounded-se-full {
  border-start-end-radius: 9999px;
}
.roam-rounded-ee-none {
  border-end-end-radius: 0px;
}
.roam-rounded-ee-sm {
  border-end-end-radius: 0.125rem; /* 2px */
}
.roam-rounded-ee {
  border-end-end-radius: 0.25rem; /* 4px */
}
.roam-rounded-ee-md {
  border-end-end-radius: 0.375rem; /* 6px */
}
.roam-rounded-ee-lg {
  border-end-end-radius: 0.5rem; /* 8px */
}
.roam-rounded-ee-xl {
  border-end-end-radius: 0.75rem; /* 12px */
}
.roam-rounded-ee-2xl {
  border-end-end-radius: 1rem; /* 16px */
}
.roam-rounded-ee-3xl {
  border-end-end-radius: 1.5rem; /* 24px */
}
.roam-rounded-ee-full {
  border-end-end-radius: 9999px;
}
.roam-rounded-es-none {
  border-end-start-radius: 0px;
}
.roam-rounded-es-sm {
  border-end-start-radius: 0.125rem; /* 2px */
}
.roam-rounded-es {
  border-end-start-radius: 0.25rem; /* 4px */
}
.roam-rounded-es-md {
  border-end-start-radius: 0.375rem; /* 6px */
}
.roam-rounded-es-lg {
  border-end-start-radius: 0.5rem; /* 8px */
}
.roam-rounded-es-xl {
  border-end-start-radius: 0.75rem; /* 12px */
}
.roam-rounded-es-2xl {
  border-end-start-radius: 1rem; /* 16px */
}
.roam-rounded-es-3xl {
  border-end-start-radius: 1.5rem; /* 24px */
}
.roam-rounded-es-full {
  border-end-start-radius: 9999px;
}
.roam-rounded-tl-none {
  border-top-left-radius: 0px;
}
.roam-rounded-tl-sm {
  border-top-left-radius: 0.125rem; /* 2px */
}
.roam-rounded-tl {
  border-top-left-radius: 0.25rem; /* 4px */
}
.roam-rounded-tl-md {
  border-top-left-radius: 0.375rem; /* 6px */
}
.roam-rounded-tl-lg {
  border-top-left-radius: 0.5rem; /* 8px */
}
.roam-rounded-tl-xl {
  border-top-left-radius: 0.75rem; /* 12px */
}
.roam-rounded-tl-2xl {
  border-top-left-radius: 1rem; /* 16px */
}
.roam-rounded-tl-3xl {
  border-top-left-radius: 1.5rem; /* 24px */
}
.roam-rounded-tl-full {
  border-top-left-radius: 9999px;
}
.roam-rounded-tr-none {
  border-top-right-radius: 0px;
}
.roam-rounded-tr-sm {
  border-top-right-radius: 0.125rem; /* 2px */
}
.roam-rounded-tr {
  border-top-right-radius: 0.25rem; /* 4px */
}
.roam-rounded-tr-md {
  border-top-right-radius: 0.375rem; /* 6px */
}
.roam-rounded-tr-lg {
  border-top-right-radius: 0.5rem; /* 8px */
}
.roam-rounded-tr-xl {
  border-top-right-radius: 0.75rem; /* 12px */
}
.roam-rounded-tr-2xl {
  border-top-right-radius: 1rem; /* 16px */
}
.roam-rounded-tr-3xl {
  border-top-right-radius: 1.5rem; /* 24px */
}
.roam-rounded-tr-full {
  border-top-right-radius: 9999px;
}
.roam-rounded-br-none {
  border-bottom-right-radius: 0px;
}
.roam-rounded-br-sm {
  border-bottom-right-radius: 0.125rem; /* 2px */
}
.roam-rounded-br {
  border-bottom-right-radius: 0.25rem; /* 4px */
}
.roam-rounded-br-md {
  border-bottom-right-radius: 0.375rem; /* 6px */
}
.roam-rounded-br-lg {
  border-bottom-right-radius: 0.5rem; /* 8px */
}
.roam-rounded-br-xl {
  border-bottom-right-radius: 0.75rem; /* 12px */
}
.roam-rounded-br-2xl {
  border-bottom-right-radius: 1rem; /* 16px */
}
.roam-rounded-br-3xl {
  border-bottom-right-radius: 1.5rem; /* 24px */
}
.roam-rounded-br-full {
  border-bottom-right-radius: 9999px;
}
.roam-rounded-bl-none {
  border-bottom-left-radius: 0px;
}
.roam-rounded-bl-sm {
  border-bottom-left-radius: 0.125rem; /* 2px */
}
.roam-rounded-bl {
  border-bottom-left-radius: 0.25rem; /* 4px */
}
.roam-rounded-bl-md {
  border-bottom-left-radius: 0.375rem; /* 6px */
}
.roam-rounded-bl-lg {
  border-bottom-left-radius: 0.5rem; /* 8px */
}
.roam-rounded-bl-xl {
  border-bottom-left-radius: 0.75rem; /* 12px */
}
.roam-rounded-bl-2xl {
  border-bottom-left-radius: 1rem; /* 16px */
}
.roam-rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem; /* 24px */
}
.roam-rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

/*
* ================= CURSORS ==============================================
* https://tailwindcss.com/docs/border-radius
*/

.roam-cursor-auto {
  cursor: auto;
}
.roam-cursor-default {
  cursor: default;
}
.roam-cursor-pointer {
  cursor: pointer;
}
.roam-cursor-wait {
  cursor: wait;
}
.roam-cursor-text {
  cursor: text;
}
.roam-cursor-move {
  cursor: move;
}
.roam-cursor-help {
  cursor: help;
}
.roam-cursor-not-allowed {
  cursor: not-allowed;
}
.roam-cursor-none {
  cursor: none;
}
.roam-cursor-context-menu {
  cursor: context-menu;
}
.roam-cursor-progress {
  cursor: progress;
}
.roam-cursor-cell {
  cursor: cell;
}
.roam-cursor-crosshair {
  cursor: crosshair;
}
.roam-cursor-vertical-text {
  cursor: vertical-text;
}
.roam-cursor-alias {
  cursor: alias;
}
.roam-cursor-copy {
  cursor: copy;
}
.roam-cursor-no-drop {
  cursor: no-drop;
}
.roam-cursor-grab {
  cursor: grab;
}
.roam-cursor-grabbing {
  cursor: grabbing;
}
.roam-cursor-all-scroll {
  cursor: all-scroll;
}
.roam-cursor-col-resize {
  cursor: col-resize;
}
.roam-cursor-row-resize {
  cursor: row-resize;
}
.roam-cursor-n-resize {
  cursor: n-resize;
}
.roam-cursor-e-resize {
  cursor: e-resize;
}
.roam-cursor-s-resize {
  cursor: s-resize;
}
.roam-cursor-w-resize {
  cursor: w-resize;
}
.roam-cursor-ne-resize {
  cursor: ne-resize;
}
.roam-cursor-nw-resize {
  cursor: nw-resize;
}
.roam-cursor-se-resize {
  cursor: se-resize;
}
.roam-cursor-sw-resize {
  cursor: sw-resize;
}
.roam-cursor-ew-resize {
  cursor: ew-resize;
}
.roam-cursor-ns-resize {
  cursor: ns-resize;
}
.roam-cursor-nesw-resize {
  cursor: nesw-resize;
}
.roam-cursor-nwse-resize {
  cursor: nwse-resize;
}
.roam-cursor-zoom-in {
  cursor: zoom-in;
}
.roam-cursor-zoom-out {
  cursor: zoom-out;
}

/*
* ==================== CUSTOM ==============================================
* -- Keep followings for backward compatibilities (at least for now)
*/

.roam-text-nowrap {
  white-space: nowrap;
}

.roam-text-underline {
  text-decoration: underline;
}

.roam-text-center {
  text-align: center;
}

.roam-text-primary {
  color: var(--roam-color-primary);
}

.roam-text-success {
  color: #02cc47;
}

.roam-text-info {
  color: #297ce0;
}

.roam-text-warning {
  color: #b5781e;
}

.roam-text-danger {
  color: #ff0000;
}

.roam-fill-danger {
  fill: #ff0000;
}

.roam-text-basic {
  color: #696969;
}

.roam-text-default {
  color: #272727 !important;
}

.roam-text-alt,
.roam-text-disabled {
  color: #797979 !important;
}

// .roam-text-lg {
//   font-size: 1.2rem;
// }
//
// .roam-text-xl {
//   font-size: 1.5rem;
// }
//
// .roam-text-normal {
//   font-size: 1rem;
// }
//
// .roam-text-sm {
//   font-size: 0.85rem;
// }
//
// .roam-text-xs {
//   font-size: 0.75rem;
// }

.roam-w-full {
  width: 100% !important;
}

.roam-w-fit {
  width: fit-content !important;
}

.roam-b-none {
  border: none !important;
}

.roam-text-left {
  text-align: left;
}

.roam-text-right {
  text-align: right;
}

.roam-text-center {
  text-align: center;
}

.roam-text-justify {
  text-align: center;
}
