$color-theme-white: #ffffff;
$color-theme-primary: #91466A;
$color-theme-soft: #E8E4E9;
$color-neutral-900: #222222;
$color-black-theme: $color-neutral-900;

// Color Base
$color-sidebar-text: $color-neutral-900;

// Accent Color
$color-theme-red: #D40101;
$color-grey-smoke: #DDDDDD;
$color-grey-dust: #767676;
$color-grey-base: #777777;
$color-grey-dark: #5D4E4E;
$color-grey-light: #F5F5F5;
$color-grey-shadow: #F9F9F9;
$color-accent-black: #222222;
$color-accent-light: #D6D3D7;
$color-accent-grey: #767676;
$color-grey-border: #E9E9E9;
$color-grey-brown: #666666;
$color-blue-cloud: #48A7C7;
$color-soft-blue: #F8F9FD;
$color-hover: #F4ECF0;
$color-grey-steel: #636E72;
$color-blue-main: #295AD8;
$color-border-grey: #AAAAAA;
$color-border-light: #D3D3D3;
$color-hover-background: #EFE3E9;
$color-text-dark: #414141;


// Green
$color-theme-green: #2AB51E;
$color-green-light: #E1F1E0;

$color-text-black: $color-accent-black;
$color-text-table: $color-grey-dust;
$color-card-bg: $color-grey-light;
$color-border: $color-grey-dust;
$color-shadow: $color-grey-shadow;
$color-sidebar: $color-grey-shadow;
$color-text-grey: $color-grey-dark;
$color-text-base-grey: $color-grey-base;
$color-grey-text : #949494;
$color-box-shadow: 0px 2px 4px 0px rgba(34, 34, 34, 0.10);


