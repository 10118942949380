.cal-info {
  position: absolute;
  width: 100%;
  max-width: calc(100% - 8px);
  border-radius: 4px;
}

.cal-info-body {
  padding: 4px;
  font-size: 12px;

  .title {
    font-weight: 500;
  }

  .time {
    margin-top: 4px;
  }
}
