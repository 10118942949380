$menu-content-distance: 127px;
.main-wrapper {
  padding-left: 127px;
}

.page-navigation {
  &__wrapper {
    position: relative;
  }

  &__menu-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px;
    height: 25px;
    margin-top: 10px;
    border: solid 1px #886486;
    border-radius: 50%;
    display: none;
    z-index: 99;

    &.left-menu {
      left: 15px;
      background-color: #886486;
      color: #ffffff;
      position: relative;
      margin-top: 10px;
    }

    @media (max-width: 767px) {
      display: block;
    }
  }
  &__nav-list {
    background-color: #ddd6db;
    font-weight: 200;
    &.mat-mdc-list-base {
      padding: 79px 15px 79px 15px;
      @media (max-width: 767px) {
        padding: 20px;
      }
      .mat-mdc-list-item {
        height: 40px;
        /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
        .mat-mdc-list-item-content {
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}

.visible__menu {
  &-container {
    width: 232px;
    height: 100vh;
    padding: 20px;
    margin-top: -1.8rem;
    background-color: #ffffff;
    border-radius: 10px;

    .visible__menu-btn {
      padding: 5px 0 !important;
      display: block;
      color: rgba(145, 70, 106, 1) !important;
      text-align: left;
      white-space: pre-line;
      line-height: 20px;
      text-decoration: underline;
    }

    .c-mat-group {
      max-width: fit-content;
      .mat-mdc-tab-header {
        overflow: unset;
        .mat-tab-header-pagination {
          display: none;
        }
        .mat-tab-label-container {
          overflow: unset;
          .mat-tab-label {
            padding: 0 10px;
            min-width: 85px;
            color: #949494;
            opacity: 1;
            &-active {
              color: #91466a;
            }
          }
          .mat-ink-bar {
            height: 3px;
            background-color: #91466a;
          }
        }
      }
      .mat-tab-body-content {
        padding: 20px 0;
      }
    }

    .mdc-button__label {
      display: flex;
      align-items: center;
      font-weight: 400;
      letter-spacing: normal;
    }
  }
  .circle-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    margin-left: -12px;
    top: 0;
    background-color: #e8e4e9;
    color: #91466a;
  }
  .my-12px {
    margin: 12px 0;
  }
}

.border-card {
  padding: 16px;
  border: 1px solid #d6d3d7;
}

.quick-action-title {
  margin-top: 30px !important;
  margin-bottom: 16px !important;
  color: #666666;
  font-weight: 600;
}

.set-icon-qaction {
  margin-left: -24px !important;
}

@media (max-width: 768px) {
  .visible__menu {
    &-container {
      width: 100%;
    }
    &-btn {
      display: block;
    }
  }
}

@media (min-width: 768px) {
  .page-navigation {
    &__nav-list {
      &.mat-mdc-list-base {
        .mat-mdc-list-item {
          &.active {
            color: #91466a;
            /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
            .mat-list-item-content {
              background: rgba(143, 134, 147, 0.3);
              border-radius: 9px;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                right: -15px;
                height: 40px;
                width: 4px;
                background: #904769;
              }
            }
          }
        }
      }
    }
  }
}

.page-content {
  &__padding {
    padding: 85px 80px;
  }
  &__py,
  &__py.left-bar-navigation {
    padding-top: 85px;
    padding-bottom: 85px;
  }
}

.py {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-1rem {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8px {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pl-8px {
  padding-left: 8px;
}

.pb-1rem {
  padding-bottom: 1rem;
}
.pb-5px {
  padding-bottom: 5px;
}

.pb-10px {
  padding-bottom: 10px;
}

.pb-20px {
  padding-bottom: 20px;
}

.pb-30px {
  padding-bottom: 30px;
}

.pt-1rem {
  padding-top: 1rem;
}
.pt-16px {
  padding-top: 16px;
}

.mt-20px {
  margin-top: 20px !important;
}

.mt-30px {
  margin-top: 30px !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-8px {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1rem {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.ml-8px {
  margin-left: 8px;
}

.px {
  padding-right: 30px;
  padding-left: 30px;
}

.px-1rem {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px-8px {
  padding-right: 8px;
  padding-left: 8px;
}

.px-10px {
  padding-right: 10px;
  padding-left: 10px;
}

.pl-5px {
  padding-left: 5px;
}

.mt-m {
  margin-top: -1rem !important;
}

.page-space {
  padding: 35px 35px 150px;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.w-min-95px {
  min-width: 95px !important;
}

app-roam-layout-wrapper mat-tab-group .roam-wrapper h1.title {
  margin-top: 24px;
}

.mat-mdc-tab-body.mat-mdc-tab-body-active {
  animation: slideDown 0.5s ease-out;
}