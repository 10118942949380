.collapse {
	position: relative;
	display: grid;
	overflow: hidden;
	grid-template-rows: auto 0fr;
	&:not(td):not(tr):not(colgroup) {
		visibility: visible;
	}
	&:focus {
		&:not(.collapse-open):not(.collapse-close):not(.collapse[open]) {
			& > .collapse-title {
				cursor: unset;
			}
		}
	}
}
.collapse-title,
.collapse > input[type="checkbox"],
.collapse > input[type="radio"],
.collapse-content {
	grid-column-start: 1;
	grid-row-start: 1;
}
.collapse > input[type="checkbox"],
.collapse > input[type="radio"] {
	appearance: none;
	opacity: 0;
}
.collapse-content {
	visibility: hidden;
	grid-column-start: 1;
	grid-row-start: 2;
	min-height: 0;
	cursor: unset;
}
.collapse[open],
.collapse-open,
.collapse:focus:not(.collapse-close) {
	grid-template-rows: auto 1fr;
}
.collapse:not(.collapse-close):has(> input[type="checkbox"]:checked),
.collapse:not(.collapse-close):has(> input[type="radio"]:checked) {
	grid-template-rows: auto 1fr;
}
.collapse[open] > .collapse-content,
.collapse-open > .collapse-content,
.collapse:focus:not(.collapse-close) > .collapse-content,
.collapse:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-content,
.collapse:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-content {
	visibility: visible;
	min-height: fit-content;
}
.collapse:has(.collapse-title:focus-visible),
.collapse:has(> input[type="checkbox"]:focus-visible),
.collapse:has(> input[type="radio"]:focus-visible) {
	outline: 2px solid;
}
.collapse:not(.collapse-open):not(.collapse-close) > input[type="checkbox"],
.collapse:not(.collapse-open):not(.collapse-close) > input[type="radio"]:not(:checked),
.collapse:not(.collapse-open):not(.collapse-close) > .collapse-title {
	cursor: pointer;
}
.collapse-title {
	position: relative;
}
:where(.collapse > input[type="checkbox"]),
:where(.collapse > input[type="radio"]) {
	z-index: 1;
}
.collapse-title,
:where(.collapse > input[type="checkbox"]),
:where(.collapse > input[type="radio"]) {
	padding: 8px;
	width: 100%;
}
.collapse[open] > :where(.collapse-content),
.collapse-open > :where(.collapse-content),
.collapse:focus:not(.collapse-close) > :where(.collapse-content),
.collapse:not(.collapse-close) > :where(input[type="checkbox"]:checked ~ .collapse-content),
.collapse:not(.collapse-close) > :where(input[type="radio"]:checked ~ .collapse-content) {
	padding-bottom: 8px;
}
.collapse[open].collapse-arrow > .collapse-title:after,
.collapse-open.collapse-arrow > .collapse-title:after,
.collapse-arrow:focus:not(.collapse-close) > .collapse-title:after,
.collapse-arrow:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-title:after,
.collapse-arrow:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-title:after {
	transform: translateY(-50%) rotate(225deg);
}
.collapse[open].collapse-plus > .collapse-title:after,
.collapse-open.collapse-plus > .collapse-title:after,
.collapse-plus:focus:not(.collapse-close) > .collapse-title:after,
.collapse-plus:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-title:after,
.collapse-plus:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-title:after {
	content: "−";
}
