@import "variables";

@mixin mat-tab-custom-theme() {

  .default-mat-group {
    .mat-tab-label {
      min-width: auto;
      padding: 0 1rem;
    }
    .mat-tab-label-active {
      color: $color-theme-primary!important;
      background-color: transparent!important;
      opacity: 1 !important;
    }

    .mat-ink-bar{
      background-color: $color-theme-primary !important;
    }
  }

  .mat-mdc-tab {

    height: 48px;

    .std {
      height: 48px!important;
    }
  }

  .mat-mdc-tab-label-container {
    position: relative;

    &:after {
      content: '';
      border-bottom: 1px solid #ccc;
      width: 100%;
      position: absolute;
      bottom: 6px;
    }
  }

  .mat-mdc-tab .mdc-tab__text-label {
    color: #222222!important;
    font-weight: 400!important;
  }

  .mdc-tab--active .mdc-tab__text-label {
    color: #91466A!important;
    font-weight: 500!important;
  }

  .mat-mdc-tab-labels {
    display: inline-flex !important;
  }

  .mdc-tab-indicator--active .mdc-tab-indicator__content {
    opacity: 1;
    height: 3px;
    background-color: #91466a;
    border-color: transparent !important;
  }

  .mat-mdc-tab-group.main-tab-chip {

    .mdc-tab__text-label {
      color: #5d4e4e;
      transition: none;
    }

    &> .mat-mdc-tab-header .mat-mdc-tab {
      flex-grow: 1;
      height: 2rem;
      min-width: auto;
      padding: 0 1.25rem;
      border: 0.5px solid rgb(214, 211, 215);
      border-radius: 25px;
      opacity: 1;
      display: flex;
      align-items: center;

      &.mat-mdc-tab.mdc-tab--active {
        background-color: rgb(145, 70, 106);

        .mdc-tab__text-label {
          color: #ffffff;
        }
      }
    }

    .mdc-tab-indicator__content--underline {
      display: none;
    }

    .mdc-tab__ripple {
      display: none;
    }

  }

  .mat-mdc-tab-group.mat-tab-chip > .mat-mdc-tab-header .mat-mdc-tab-labels {
    gap: 15px;
    padding-bottom: 20px;
  }

  .mat-mdc-tab-group:not(.mat-tab-chip) > .mat-mdc-tab-header .mat-mdc-tab-labels {
    padding-bottom: 0;
  }
  
  .mat-mdc-tab-body-content {
    padding: 0px 5px 10px 0px;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent!important;
  }

}
