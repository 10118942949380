:root {
  --roam-color-primary: #91466a;
  --roam-color-primary-alt: #866385;
  --roam-color-white: #ffffff;
  --roam-color-black: #000000;

  --roam-opacity-5: 0.05;
  --roam-opacity-10: 0.1;
  --roam-opacity-20: 0.2;
  --roam-opacity-30: 0.3;
  --roam-opacity-40: 0.4;
  --roam-opacity-50: 0.5;
  --roam-opacity-60: 0.6;
  --roam-opacity-70: 0.7;
  --roam-opacity-80: 0.8;
  --roam-opacity-90: 0.9;

  --roam-text-xs: 0.5rem;
  --roam-text-sm: 0.85rem;
  --roam-text-base: 1rem;
  --roam-text-md: 1.25rem;
  --roam-text-lg: 1.5rem;
  --roam-text-xl: 1.75rem;
  --roam-text-2xl: 2rem;

  --roam-font-base: 500;

  --roam-gap-none: 0;
  --roam-gap-xs: 0.5rem;
  --roam-gap-sm: 0.75rem;
  --roam-gap: 1rem;
  --roam-gap-md: 1.25rem;
  --roam-gap-lg: 1.5rem;
  --roam-gap-xl: 1.75rem;
  --roam-gap-2xl: 2rem;

  --roam-rounded-xs: 0.5rem;
  --roam-rounded-sm: 0.75rem;
  --roam-rounded: 1rem;
  --roam-rounded-md: 1.25rem;
  --roam-rounded-lg: 1.5rem;

  --roam-color-backdrop: rgba(0, 0, 0, var(--roam-opacity-50));

  --roam-shadow: 0px 2px 1px -1px rgba(0, 0, 0, var(--roam-opacity-5)),
    0px 1px 1px 0px rgba(0, 0, 0, var(--roam-opacity-10)),
    0px 1px 3px 0px rgba(0, 0, 0, var(--roam-opacity-5));
}
