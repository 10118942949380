$screen-lg-desktop: 1440px;
$screen-md-desktop: 1366px;
$screen-sm-desktop: 1280px;
$screen-std-desktop: 1024px;

@mixin large {
  @media (max-width: #{$screen-lg-desktop + 1}) {
    @content;
  }
}

@mixin lg-medium {
  @media (max-width: #{$screen-md-desktop + 1}) {
    @content;
  }
}

@mixin medium {
  @media (max-width: #{$screen-md-desktop - 1}) {
    @content;
  }
}

@mixin medium-sm {
  @media (max-width: #{$screen-md-desktop + 5}) {
    @content;
  }
}

@mixin medium-lg {
  @media (min-width: #{$screen-md-desktop + 1}) {
    @content;
  }
}

@mixin screen1280 {
  @media only screen and (max-width: $screen-sm-desktop + 1) {
    @content
  }
}

// Mixin for mobile devices
@mixin small {
  @media only screen and (max-width: 320px) {
    @content
  }
}

@mixin small-md {
  @media only screen and (max-width: 450px) {
    @content
  }
}

@mixin mobile-sm{
  @media (max-width: 590px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}


@mixin md {
  @media only screen and (max-width: 835px) {
    @content
  }
}

@mixin surface {
  @media (max-width: 1180px) {
    @content;
  }
}

@mixin mini {
  @media only screen and (min-width: 478px) and (max-width: 768px) {
    @content
  }
}

// Mixin for iPad devices
@mixin ipad {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

// Mixin for desktop in general
@mixin desktop {
  @media (min-width: 1185px) {
    @content;
  }
}

@mixin ipad-lg {
  @media (min-width: 1180px) {
    @content;
  }
}

// Mixin for large screens
@mixin large-screen {
  @media (min-width: 1200px) {
    @content;
  }
}

// Mixin for large screens
@mixin large-common {
  @media (min-width: 1500px) {
    @content;
  }
}

// Mixin > 1440 Screen
@mixin xxl {
  @media only screen and (min-width: $screen-lg-desktop - 5) and (max-width: 1500px ){
    @content;
  }
}

@mixin standard {
  @media (max-width: $screen-std-desktop + 5) {
    @content;
  }
}

/* Styles for devices or iPhone screens lower than 736 pixels */
@mixin height {
  @media only screen and (max-height: 735px) {
    @content;
  }
}

@mixin touched {
  @media (pointer: coarse) and (hover: none) {
    @content
  }
}