@import "variables";

@mixin mat-checkbox-custom-theme() {
  /*_________MDC STYLING__________*/

  .mdc-checkbox {
    --mdc-checkbox-state-layer-size: 16px;

    .mat-mdc-checkbox-touch-target {
      display: none;
    }

    .mdc-checkbox__native-control:enabled
      ~ .mdc-checkbox__background
      .mdc-checkbox__checkmark {
      color: $color-theme-white !important;
    }

    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
      border-color: $color-theme-primary !important;
      background-color: $color-theme-primary !important;
    }

    .mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background {
      border-color: $color-grey-dust !important;
      background-color: $color-grey-dust !important;
      
      .mdc-checkbox__mixedmark {
        opacity: 0;
      }
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background {
      .mdc-checkbox__mixedmark {
        border-color: transparent;
      }
    }

    .mdc-checkbox__ripple {
      display: none;
    }
  }
  /*_________/MDC STYLING__________*/

  .mat-std {
    .sm {
      .mdc-checkbox {
        display: flex;
        padding: 0 !important;
        margin: 6px 0 0 0 !important;
      }
  
      .mdc-checkbox__native-control,
      .mat-mdc-checkbox-touch-target {
        width: 35px !important;
        height: 35px !important;
      }
  
      .mdc-checkbox__background {
        width: 35px;
        height: 35px;
        top: 0 !important;
        left: 0 !important;
      }
  
      .mdc-form-field > label {
        margin-top: 4px;
        margin-left: 0!important;
        padding-left: 0!important;
      }
    }
  }

  .filter-list {
    .mat-mdc-checkbox {
      .mat-checkbox-inner-container {
        margin-right: 11px;
        width: 19px;
        height: 19px;
        .mat-checkbox-frame {
          border: 0.5px solid #d9d9d9;
          border-radius: 4px;
          background: #ffffff;
        }
      }
      .mat-checkbox-label {
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
      }
      &.mat-checkbox-checked {
        .mat-checkbox-background {
          background: #91466a;
          border-radius: 4px;
          width: 19px;
          height: 19px;
          top: unset;
          left: unset;
          .mat-checkbox-checkmark {
            opacity: 1;
          }
        }
      }
    }
  }

  .radio-checkbox {
    .mat-mdc-checkbox {
      .mat-checkbox-inner-container {
        width: 24px;
        height: 24px;
        .mat-checkbox-frame {
          border: 1px solid #cccccc;
          border-radius: 4px;
        }
      }
      &.mat-checkbox-checked {
        .mat-checkbox-background {
          width: 12px;
          height: 12px;
          top: 6px;
          left: 6px;
        }
      }
    }
  }

  .checkbox-small {
    .mdc-checkbox {
      padding: 7px !important;
    }

    .mdc-checkbox__background {
      width: 1rem !important;
      height: 1rem !important;
    }

    .mat-mdc-checkbox-touch-target {
      width: 1.5rem !important;
      height: 1.8rem !important;
      top: 18px;
      left: 18px;
    }

    .mdc-checkbox__native-control {
      top: 8px !important;
      left: 8px !important;
      width: 20px !important;
      height: 20px !important;
    }
    .mdc-form-field > label {
      padding-left: 0;
    }

    .mdc-checkbox__checkmark {
      top: 1px !important;
      left: 1px !important;
    }
  }

  .round-checkbox {
    .mdc-checkbox__background {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
    }

    &.std {
      .mdc-checkbox__checkmark {
        top: 4px !important;
        left: 4px !important;
      }
    }

    &.sm {
      &.mdc-checkbox {
        padding-left: 0 !important;
      }

      .mdc-checkbox__background {
        width: 1rem;
        height: 1rem;
      }

      .mdc-form-field > label {
        padding-left: 0;
      }

      .mdc-checkbox__checkmark {
        top: 1px !important;
        left: 1px !important;
      }
    }

    &.md {
      .mdc-checkbox {
        display: flex;
      }

      .mdc-checkbox__background {
        width: 20px;
        height: 20px;
      }

      .mdc-form-field > label {
        margin-top: 4px;
      }
    }
  }

  .md {
    .mdc-checkbox {
      display: flex;
    }

    .mdc-checkbox__background {
      width: 20px;
      height: 20px;
      margin-left: -5px !important;
    }

    .mdc-form-field > label {
      margin-top: 4px;
    }
  }

  .sm {
    .mdc-checkbox {
      display: flex;
      padding: 0 !important;
      margin: 6px 0 0 0 !important;
    }

    .mdc-checkbox__native-control,
    .mat-mdc-checkbox-touch-target {
      width: 16px !important;
      height: 16px !important;
    }

    .mdc-checkbox__background {
      width: 16px;
      height: 16px;
      top: 0 !important;
      left: 0 !important;
    }

    .mdc-form-field > label {
      margin-top: 4px;
      margin-left: 0!important;
      padding-left: 0!important;
    }
  }

  .reverse {
    width: 100%;

    .mat-mdc-option {
      display: flex;
      flex-direction: row-reverse !important;
    }

    .mdc-form-field {
      width: 100%;
      padding: 3px 0;
      flex-direction: row-reverse !important;
    }

    .mat-mdc-checkbox {
      width: 100%;
      display: flex;
    }

    .mdc-label {
      cursor: pointer;
      width: 100%;
    }
  }

  .hovered {

    .mdc-checkbox {
      padding-right: 0!important;
    }

    &.flat {
      .mdc-form-field {
        padding: 0!important
      }
    }
    .mdc-form-field {
      cursor: pointer;
      padding: 4px 16px;

      &:hover {
        background-color: #F9F6F8;
      }
    }

    .mat-mdc-checkbox-checked {
      background-color: #F9F6F8!important;
    }

    .mdc-checkbox__background {
      top: 9px!important;
    }

    .mat-name {
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 500;
    }

    .mdc-label {
      padding: 12px 0;
    }

    &.label-md .mdc-label{
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .checkbox-flex-start {
    .mdc-form-field {
      align-items: flex-start !important;
    }
  }

  .mat-checkbox-white {
    .mdc-checkbox__background {
      border-width: 1px;
      width: 1rem;
      height: 1rem;
    }

    .mdc-checkbox:hover
      .mdc-checkbox__native-control:enabled:not(:checked):not(
        :indeterminate
      ):not([data-indeterminate="true"])
      ~ .mdc-checkbox__background,
    .mdc-checkbox
      .mdc-checkbox__native-control:enabled:not(:checked):not(
        :indeterminate
      ):not([data-indeterminate="true"])
      ~ .mdc-checkbox__background {
      background-color: white;
    }
  }

  .mdc-checkbox {
    z-index: 999;
    &__background {
      background-color: #ffffff !important;
      border: 1px solid #D6D3D7 !important;
      border-radius: 4px !important;
    }

    &__checkmark {
      top: 1px !important;
      left: 2px !important;
      width: 80% !important;
    }
  }

  .checkbox-green {
    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
      background-color: #397d49 !important;
      border-color: #397d49 !important;
    }
  }

  .radio-type {
    .mat-mdc-option:before {
      content: "";
      position: absolute;
      right: 8px;
      width: 20px;
      height: 20px;
      border: 1.5px solid #bebebe;
      border-radius: 100%;
    }

    .mat-mdc-option-active.mat-mdc-option:before {
      border-color: transparent;
    }

    .mat-mdc-option.mat-loading:before {
      border-color: transparent !important;
    }

    .mat-mdc-option-active.mat-mdc-option:not(.mdc-list-item--selected):before {
      border-color: #bebebe;
    }

    &.mat-mdc-select-panel
      .mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
      border-radius: 50%;
    }

    &.mat-mdc-select-panel
      .mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked::before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #87576e;
      display: block;
      border-radius: 100%;
    }

    &.mat-mdc-select-panel
      .mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked::after {
      width: 12px !important;
      height: 12px !important;
      transform-origin: unset;
      top: 50% !important;
      bottom: unset !important;
      left: 50% !important;
      right: 0 !important;
      transform: translate(-50%, -50%) !important;
      background: #87576e;
      content: "";
      border: 2px solid #fff;
      border-radius: 100%;
    }
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark {
    border-color: transparent!important;
  }
}