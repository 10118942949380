.radio-template {

  label {
    position: relative;
    flex: 1;
    background-color: #ffffff;;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  input:checked + .radio-button{
    border: 1.5px solid #91466A;
    background: #F9F6F8;
  }
}
