@function hexToRgba($color, $alpha: 1) {
  @return rgba(red($color), green($color), blue($color), $alpha);
}

$task-styles: (
  administrative: #3E9BDF,
  announcement: #397D49,
  meeting: #A68AE7,
  social: #295AD8,
  maintenance: #B5781E
);

@each $task, $styles in $task-styles {
  .category-#{$task} {
    color: $styles !important;
    background:hexToRgba($styles, 0.15) !important;

    &:hover {
      background: hexToRgba($styles, 0.30)!important;
    }
  }
}
