.form-section {
  margin-top: 1rem;

  &.f-col-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem 1rem;

    & > * {
      width: calc((100%/2) - 1rem);
    }
  }
}

.field-form {
  .field{
    display: flex;
    gap: 1rem;

    &.col-7-1 {
      & > *:first-child {width: 100%}
    }

    &.col-2-7 {
      & > *:first-child { width: calc(25% - 1rem)}
      & > *:nth-child(2) { width: calc(75% - 1rem)}
    }
  }
}

.field-label {
  margin-bottom: 4px;
  display: block;
  color: #222222;
  font-size: 14px;
  font-weight: 500;
}
