.set-grid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  &.std {
    justify-content: initial;
    gap: 24px;
  }

  &-1 > * {
    width: calc(50% - 0.5rem);
  }

  &-2 > * {
    width: calc((100% / 2) - 1rem);
  }

  &-3 > * {
    width: calc(100% / 3 - 1rem);

  }

  &-4 > * {
    width: calc((100% / 4) - 1rem);
  }

  &.gap-24 {
    gap: 24px;

    & > * {
      width: calc((100% / 2) - 12px);
    }
  }

  &.gap-16 {
    &.set-grid-3 > * {
      width: calc((100% / 3) - 11px);
    }
  }

  &.stretch > * {
    flex: 1;
  }

}

.gap-col-16 {
  gap: 0 1rem!important;
}

.gap-col-20 {
  gap: 0 1.25rem!important;
}

.d-flex-1 {
  display: flex;

  & > * {
    flex: 1
  }
}

.br-8 {
  border-radius: 8px!important;
}

.h-24 {
  height: 24px;
}

.h-18 {
  height: 18px;
}

.h-36 {
  height: 36px!important;
}

.h-45 {
  height: 45px!important;
}

.h-200 {
  height: 200px!important;
}

.h-inherit {
  height: inherit!important;
}

.mx-w-0 {
  max-width: 0!important;
}

.flex-0 {
  flex: 0!important;
}

.flex-1 {
  flex: 1;
}

.flex-50 {
  flex: 50% 0 0;
}

.lh-30px {
  line-height: 30px;
}

.lh-normal {
  line-height: normal;
}

.lh-24 {
  line-height: 1.5rem;
}

.table-invoice-height {
  max-height: 244px;
  overflow-y: auto;
}

.w-115px {
  min-width: 115px!important;
  max-width: 115px!important;
}

.w-150px {
  min-width: 150px!important;
  max-width: 150px!important;
}

.w-180 {
  min-width: 180px !important;
}

.search-width {
  min-width: 320px;
  max-width: 350px;
}

.card-space-24 {
  margin-bottom: 24px;
}

.mn-24 {
  margin-top: -24px;
}

.mn-12 {
  margin-top: -12px;
}

.mnl-8 {
  margin-left: -8px;
}

.mnl-6 {
  margin-left: -6px;
}

.p-05 {
  padding: 0.5rem;
}

.min-h-dialog {
  min-height: 250px;
}

.wh-28 > svg{
  height: 28px;
  width: 28px;
}

.mnt-2 {
  margin-top: -2px;
}
