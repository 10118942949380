@mixin mat-tooltip-custom-theme() {
  .mat-mdc-tooltip.custom-tooltip {
    margin-bottom: -7px;
  }

  .tooltip-caret {
    max-width: unset !important;

    &.mdc-tooltip::after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #222222;
    }

    .mdc-tooltip__surface {
      background-color: #222222 !important;
    }

    .mdc-tooltip__surface {
      max-width: 300px;
    }
  }
}
