@import "responsive";

@mixin mat-dialog-custom-theme() {
  .mat-mdc-dialog-container {
    padding: 51px 72px 61px 67px !important;
    position: relative;
    border-radius: 0;

    .close-dialog {
      position: absolute;
      top: 15px;
      right: 20px;
      cursor: pointer;
    }

    .mat-mdc-dialog-title {
      padding-bottom: 17px;
      padding-right: 16px;
      border-bottom: 1px solid #efefef;
      margin-bottom: 0;
    }

    .mat-mdc-dialog-content {
      padding-top: 33px;
      padding-bottom: 25px;
    }
  }

  .financial-panel {
    .mat-mdc-dialog-container {
      padding: 0 !important;

      .mat-mdc-dialog-content {
        margin: 0;
        padding: 0;
        max-height: 100%;
        height: 100%;
      }

      .mdc-dialog__surface {
        border-radius: 9px;
      }
    }
  }

  .fullpage {
    max-width: initial!important;

    .mat-mdc-dialog-container {
      padding: 0!important;
    }
  }

  .no-padding-container,
  .no-padding-container-responsive{

    .mat-mdc-dialog-container {
      padding: 0 !important;
      overflow: unset;

      .mdc-dialog__surface {
        border-radius: 12px;
      }

      .mat-mdc-dialog-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 0.875rem 1.25rem;
        background: #F5F5F5;

        &__block {
          display: block;
        }
      }

      .mat-mdc-dialog-content {
        margin: 0;
        max-height: 95vh;
        padding: 16px 30px;
      }
    }

    &.fullscreen {
      min-width: 100vw;
      max-width: 100vw;
      max-height: 100vh!important;
      min-height: 100vh!important;

      .mat-mdc-dialog-content {
        max-height: 100vh !important;
      }

      .send-email {
        // height: calc(46vh - 45px) !important;

        .ql-container {
          height: 25vh!important;
        }
      }
    }
  }

  .no-padding-container-responsive {
    @include desktop {
      max-width: 80vw!important;
      max-height: 90vh!important;
    }

    @include ipad-lg {
      height: 90vh!important;
    }

  }

  .no-padding {
    .mat-mdc-dialog-container {
      padding: 0 !important;
      border-radius: 8px;
    }
  }

  .default,
  .default-borderless{
    .mat-mdc-dialog-container {
      padding: 0 24px 24px 24px !important;
      border-radius: 8px;
    }

    .mdc-dialog__title::before {
      display: flex;
      height: 18px;
    }

    .mdc-dialog__surface {
      border-radius: 8px!important;
      max-height: 100%!important;
      min-height: 100%!important;
      overflow-x: hidden;
    }

    &-mdc {

      .mat-mdc-dialog-container {
        border-radius: 9px!important;
        padding: 0!important;
      }

      .mat-mdc-dialog-title,
      .mdc-dialog__content{
        padding: 0!important;
        border: none;
      }

      .mdc-dialog__title::before {
        content: none;
      }
    }

    &-mdc-flat {

      .mat-mdc-dialog-container {
        padding: 0!important;
      }

      .mdc-dialog__surface {
        border-radius: 8px!important;
      }

      .mat-mdc-dialog-title {
        padding-top: 20px;

        h2 {
          font-size: 20px;
          font-weight: 600;
        }
      }

      .mdc-dialog__title::before {
        content: none;
      }
    }
  }

  .default {
    .mat-mdc-dialog-content.mdc-dialog__content {
      padding-top: 1rem!important;
      max-height: calc(100vh - 156px);
    }
  }

  .default-borderless {
    .mat-mdc-dialog-title {
      border: none
    }

    .mat-mdc-dialog-content {
      max-height: calc(100vh - 156px);
    }
  }

  .auth-popup {

    .mat-mdc-dialog-container .mdc-dialog__surface {
      border-radius: 14px;
      padding: 2rem;
      width: unset;
      height: unset;
    }
  }

  @media (max-width: 768px) {
    .auth-popup {
      width: 100%;
      max-width: 100% !important;
    }

    .no-padding-container {
      .mat-mdc-dialog-content {
        padding: 15px;
      }
    }
  }

  @include mobile-sm {
    .auth-popup {
      .mat-mdc-dialog-container {
        padding: 20px!important;

        .mdc-dialog__surface {
          width: 100%;
        }
      }
    }
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F5F5F5;
    margin: 0 -2rem;
    padding: 1rem 2rem;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.menu-action {

  &.w-2 {
    width: 200px!important;
  }

  &.mat-mdc-menu-panel {
    padding: 0.5rem 0!important;
    border-radius: 0.75rem;
  }

  .mat-mdc-menu-content {
    padding: 0!important;
  }

  .menu-action-item {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.875rem;
    cursor: pointer;

    &:hover {
      background-color: #F5F5F5;
    }

    &.sm {
      padding: 0.25rem 1rem;
    }
  }

}

.mat-dialog-roam-action {
  display: flex;
  padding: 0.875rem 1.5rem!important;
  justify-content: space-between!important;
  background-color: #F5F5F5;

  &.end {
    justify-content: flex-end!important;
  }
}
.mat-mdc-autocomplete-panel {
  border-radius: 8px!important;
  margin: 4px!important;
}
