@import "variables";

$numbers: 100;

@for $number from 0 through $numbers {
  .radius-#{$number} {
    border-radius: $number * 1px !important;
  }

  .p-#{$number} {
    padding: $number * 1px !important;
  }

  .m-#{$number} {
    margin: $number * 1px !important;
  }

  .ml-#{$number} {
    margin-left: $number * 1px !important;
  }

  .mr-#{$number} {
    margin-right: $number * 1px !important;
  }

  .mt-#{$number} {
    margin-top: $number * 1px !important;
  }

  .mb-#{$number} {
    margin-bottom: $number * 1px !important;
  }

  .mtb-#{$number} {
    margin-bottom: $number * 1px !important;
    margin-top: $number * 1px !important;
  }

  .mtn-#{$number} {
    margin-top: $number * -1px !important;
  }

  .pl-#{$number} {
    padding-left: $number * 1px !important;
  }

  .pr-#{$number} {
    padding-right: $number * 1px !important;
  }

  .pt-#{$number} {
    padding-top: $number * 1px !important;
  }

  .pb-#{$number} {
    padding-bottom: $number * 1px !important;
  }

  .ptb-#{$number} {
    padding-top: $number * 1px !important;
    padding-bottom: $number * 1px !important;
  }

  .plr-#{$number} {
    padding-left: $number * 1px !important;
    padding-right: $number * 1px !important;
  }

  .fb-#{$number} {
    flex-basis: $number * 1% !important;
  }

  .svg-#{$number} > svg {
    width: $number * 1px;
    height: $number * 1px;
  }

  .svg-h#{$numbers} svg {
    height: $numbers * 1px;
  }

  .svg-wh#{$number} > svg {
    width: $number * 1px;
    height: $number * 1px;
  }
}

.gap-24 {
  gap: 24px;
}

.card-gutter {
  margin-left: -2rem;
  margin-right: -2rem;
  margin-bottom: -1.25rem;
}

.no-data {
  padding: 1rem 0;
}

.mr-m-6 {
  margin-right: -6px;
}

.mt-m-8 {
  margin-top: -8px;
}

.set-icon {
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: rgba(145, 70, 106, 0.1);

  img {
    height: 30px;
  }
}

.chip {
  padding: 4px 8px;
  border-radius: 12px;
  border: 1px solid $color-accent-light;
  display: inline-flex;
  min-width: 100px;
  justify-content: center;
  color: $color-text-grey;
  font-weight: 400;
  font-size: 14px;

  &.active {
    color: $color-theme-white;
    border-color: transparent;
    background-color: $color-theme-primary;
  }
}

.card-group {
  margin-bottom: 1.5rem;
  background-color: $color-grey-light;
  padding: 1rem 1rem 1.5rem 1rem;
  border-radius: 0.5rem;
}

.icon-bg {
  width: 40px;
  height: 40px;
  background-color: rgba(135, 100, 134, 0.15);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chip-status {
  width: fit-content;
  border-radius: 4px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 4px;
  text-align: center;
  font-weight: 500;
}

.page-content {
  padding: 54px 90px;
}

.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container-dialog-set {
  overflow: hidden;
  position: relative;
  height: calc(90vh - 210px);
  overflow-y: auto;
  padding-right: 2rem;
  margin-right: -2rem;
  padding-bottom: 1rem;

  &::-webkit-scrollbar-thumb {
    background: #876486;
    border-radius: 10px;
  }
}

.mobile-view {
  display: none;

  &.flex {
    @include surface {
      display: flex;
    }
  }

  @include surface {
    display: block;
  }
}

.desktop-view {
  display: block;

  @include surface {
    display: none;
  }
}

*:focus-within {
  outline: none;
}

.read-more {
  cursor: pointer;
  color: #91466a;
  font-weight: 500;
}

.show-less {
  display: block;
  padding-top: 1rem;
}

/*------- Email Wrapper -------*/
.email-container {
  padding: 0 1rem 1rem 0;
  box-sizing: border-box;

  &-wrapper {
    max-height: 45vh;
    overflow-y: auto;
    padding-right: 1rem;
  }

  .message-streamline {
    position: relative;

    .msg-item {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 1.25rem;
        left: 1.25rem;
        height: 100%;
        border-left: 2px solid #c3cfe9;
      }

      &:last-child:before {
        display: none;
      }
    }

    &.collapse {
      .email-divider {
        &:before {
          border-left-width: 2px;
          border-left-style: dotted;
        }
      }
    }
  }
}
/*------- Email Wrapper -------*/

.empty-state-full {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-input {
  display: none;
}

/*----memo-----*/
.add-memo {
  textarea {
    background: #f7f7f7;
    border-radius: 9px;
    resize: none;
    border: none;
    outline: none;
    width: 428px;
    height: 100px;
    padding: 15px;
    font-family: "Poppins", sans-serif;

    &::placeholder {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #949494;
      position: relative;
    }
  }

  &.area-white textarea {
    background: #ffffff;
  }

  .counter {
    width: 428px;
    color: #666666;
    font-size: 12px;
  }
}

.swiper-card {
  height: auto !important;

  .card-wrapper {
    height: 100%;
    border-radius: 10px;
    background: transparent;

    & > * {
      display: flex;
      height: 100%;
    }
  }
}

.swiper-nav {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);

  &.left {
    left: -1.5rem;
  }

  &.next {
    right: -1rem;
  }
}

.hr-line {
  margin: 1rem 0;
  border-top: 1px solid #e8e4e9;
}

.border-none {
  border: none;
  background-color: transparent;
}

.border-vertical {
  border-left: 1px solid #cccccc;
}

.bdr-left {
  padding-left: 1rem;
  border-left: 1px solid #cccccc;
}

.bdr-right {
  padding-right: 1rem;
  border-right: 1px solid #d6d3d7;
}

.bdr-top {
  border-top: 1px solid #D6D3D7;
}

// DRAG AND DROP ANIMATION
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.roam-accordion.cdk-drop-list-dragging,
  .roam-accordion-list:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.info-bg-blue {
  padding: 12px 16px;
  background: $color-soft-blue;
  border-radius: 12px;
}

.list-item-border {
  border-bottom: 1px solid #d6d3d7;
  margin-bottom: 1rem;

  &:last-child {
    border-bottom-color: transparent;
  }
}

.tag-label {
  padding: 2px 4px;
  border-radius: 4px;
  background-color: rgb(102, 102, 102);
  color: #ffffff;
  font-size: 14px;
}

.nav-show {
  position: absolute;
  top: 1.75rem;
  left: -0.75rem;
  width: 24px;
  height: 24px;
  background: $color-theme-soft;
  border-radius: 100%;
  color: $color-theme-primary;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}

.bg-primary {
  & > svg {
    fill: $color-theme-primary;
  }
}

.set-loader-relative {
  position: relative;
  top: 10vh;
}

ul {
  &.disc {
    list-style: disc;
  }
}

.input-border {
  padding-left: 1rem;
  border-left: 1px solid #cccccc;
}

.container-calendar {
  position: relative;
  top: 1rem;
  min-width: 956px;
}

.line-height-1 {
  line-height: 1;
}

.line-height-1-2 {
  line-height: 1.2;
}

ul.list-comma {
  display: flex;
  flex-wrap: wrap;

  li {
    position: relative;
  }

  li:not(:last-child):after {
    content: ", ";
    padding: 0 2px;
    margin-left: 0;
    margin-right: 5px;
    display: inline-flex;
  }
}

.dropdown {
  width: 100%;
  padding-bottom: 10px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.control-input {
  padding: 6px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.32);
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  color: #6F6F6F;
  height: 37px;
}

.header-list {
  height: 68px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  font-weight: 500;
}

.filter-content {
  overflow-x: auto;

  .filter-group{
    width: 1118px;
  }
}

.line-list > * {
  display: block;
  border-bottom: 1px solid $color-accent-light;
  padding: 12px 0;

  &:first-child {
    padding-top: 0;
  }

  &:only-child,
  &:last-child {
    border-bottom-color: transparent;
  }
}

.dialog-container {
  background: #ffffff;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 0.5rem 1rem;
}

