.filter-title {
  color: #414141;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}

.row-group {
  display: grid;
  gap: 20px;
}

.column-group {
  display: grid;
  column-gap: 16px;
  row-gap: 20px;
  grid-template-columns: 1fr 1fr;
}
