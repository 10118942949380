.toggle-switch {
  color: #fff;
  display: inline-flex;
  align-items: center;
  user-select: none;
  position: relative;
  vertical-align: middle;
  margin-bottom: 0;
  box-sizing: border-box;
  gap: 16px;
  cursor: pointer;

  .label {
    color: #000;
    font-size: 14px;
    flex-grow: 1;
  }

  > input {
    position: absolute;
    opacity: 0;

    + .toggle {
      border-radius: 999em;
      align-items: center;
      position: relative;
      overflow: hidden;
      position: relative;
      flex-shrink: 0;
      background: #91466A;
      width: 36px;
      height: 24px;
      cursor: pointer;

      > .switch {
        background: #fff;
        border-radius: 999em;
        display: block;
        height: 20px;
        width: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        box-sizing: border-box;
        transition: all 150ms ease-out;
      }
      &:before,
      &:after {
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 2;
        height: 100%;
        white-space: nowrap;
      }
      /* TRANSITION */
      transition: background 150ms linear;
      &:before {
        transition: all 150ms linear;
      }
      &:after {
        transition: all 150ms linear;
      }
    }
    &:not(:checked) {
      + .toggle {
        background: #cccccc;
        &:before {
          opacity: 1;
        }
        &:after {
          opacity: 0;
        }
        .switch {
          transform: translate(2px, -50%);
        }
      }
    }
    &:checked {
      + .toggle {
        &:before {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
        .switch {
          transform: translate(70%, -50%);
        }
      }
    }
    &:disabled {
      + .toggle {
        background: #ccc;
        cursor: not-allowed;
      }
    }
  }
}
