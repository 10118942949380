@import "responsive";

.doc-action {
  .doc-action--show {
    opacity: 0;
    visibility: hidden;
  }

  &.show .doc-action--show {
    opacity: 1;
    visibility: visible;
  }
}

.documents-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.document-list {
  flex: 0 0 25%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 20px;

  .action {
    display: none;
  }

  &:hover {
    .action {
      display: block;
    }
  }

  @include md {
    flex: 0 0 calc(100% / 3);
  }
}
