@import "responsive";

.roam-std-page {
  height: 100%;
  background-color: #F8F9FD;
  display: flex;
  flex-direction: column;

  &-body {
    width: 1341px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex: 1;

    @include medium {
      width: 1200px;
    }
  }

  .big-title {
    padding-left: 4.375rem;
    font-size: 48px;
    font-weight: 500;
  }

  .caption-list {
    display: flex;
    align-items: center;
    gap:24px;
    padding: 24px;
    border-bottom: 1px solid #D6D3D7;

    &:first-child {
      padding-top: 0;
    }
  }

  footer {
    padding: 1rem 2rem;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-end;

    @include medium {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
