@import 'variables';
@import 'responsive';

$color-contrast: 50%;

@mixin button(
  $padding: 0.7rem,
  $border-radius: $radius,
  $bg-color: $color-theme-primary,
  $font-color: $color-theme-white,
  $font-size: 14px,

) {
  min-width: 80px;
  height: 40px;
  padding: 0 1.25rem;
  border-radius: 12px;
  color: $color-theme-white;
  border: none;
  font-size: $font-size;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;

  &:only-child {
    margin-right: 0;
  }


  @include ipad {
    font-size: 12px !important;
  }
}

@mixin button-bg($bg, $bgHover, $color: "initial") {
  background: $bg;

  &:hover {
    background: darken($bg, 7%);
    transition: all 0.3s ease;
  }

  @if ($color != "initial") {
    color: $color;

    a {
      color: $color;
    }
  }
}

@mixin button-size($height, $fontSize) {
  height: $height;
  font-size: $fontSize;
  min-width: unset;
}

.mat-mdc-button {
  --mat-mdc-button-persistent-ripple-color: transparent;
  --mat-mdc-button-ripple-color: transaprent;
}
