.available-users {
  position: relative;
  right: 1.5rem;
  width: 400px;
  display: flex;
  background-color: #ffffff;
  padding: 12px 16px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #E9E9E9;
  box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.10);
  z-index: 9998;
  flex-direction: column;
  height: calc(100vh - 200px);
  flex: 0 0 400px;
  min-width: 0;
  transition-duration: 167ms;
  animation: fade-in 167ms cubic-bezier(.4,0,1,1);
  transform: translateY(100%) translateY(-56px);

  &.user {
    width: 360px;
    flex: 0 0 360px;
  }

  &.user:not(.open-message) {
    height: 532px;
    transform: translateY(0);
    max-height: calc(100vh - 100px);
  }

  &.open-message {
    transform: translateY(0);
  }

  .chat-wrapper {
    position: sticky;
    width: 100%;
    cursor: pointer;
  }

  &.chat-window--expand {
    width: 500px;
    height: 690px!important;
    max-height: calc(100vh - 100px);
  }

  &.chat-window--collapse {
    transform: translateY(100%) translateY(-56px)!important;
  }
}


.channel-list-group > * {
  margin-right: -4px;
  display: block;
}

.channel {
  height: 28px;
  padding: 0 10px 0 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon-hash {
  fill: #666666;
}

.channel:hover {
  background: #F3F3F3;
  border-radius: 8px;
}

.channel:hover .icon-hash {
  fill: #222222;
}

.channel-name {
  color: #666666;
  font-size: 14px;
}

.active .icon-hash {
  fill: #222222;
}

.active .channel-name {
  color: #222222;
  font-weight: 500;
}

