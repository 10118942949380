@mixin mat-form-field-custom-theme() {

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      --roam-mdc-floating-label-top-offset: 19px;
      background-color: #ffffff;
      border-radius: 8px;
    }
  }

  .mdc-text-field--outlined {
    --mdc-outlined-text-field-focus-outline-width: 1px !important;
    --mdc-outlined-text-field-container-shape: 8px !important;
  }

  .radius-10 .mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: 10px !important;
  }

  .mat-mdc-floating-label {
    color: #949494;
    font-size: 14px;

    &--float-above {
      font-weight: 300!important;
    }
  }

  .mdc-text-field--focused .mdc-notched-outline > * {
    border-color: #949494!important;
  }

  .mdc-text-field--focused:hover .mdc-notched-outline > * {
    border-color: #949494!important;
  }

  .mat-mdc-form-field-infix {
    min-height: 40px!important;
  }

  .mat-datepicker-toggle {
    top: 0!important;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: auto!important;
    display: inherit!important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
    .mat-mdc-floating-label {
      top: var(--roam-mdc-floating-label-top-offset) !important;
      color: #9E9E9E !important;
      font-weight: 400;
      font-size: 14px;
      line-height: unset!important;
    }
  }

  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    color: #222222!important;
  }

  .mat-mdc-select-placeholder {
    font-weight: 400;
  }

  .mat-mdc-form-field-flex {
    display: flex !important;
    align-items: center !important;
  }

  .mat-mdc-form-field-error {
    display: inline !important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px!important;
    padding: 8px!important;
  }

  .mat-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      width: auto;
    }

    &.roam-field {
      //height: 40px;

      &.full-width {
        width: 100%;
        .mat-mdc-form-field-outline {
          width: 100%;
        }
      }

      &.small-width {
        width: 120px;
        .mat-mdc-form-field-outline {
          width: 120px;
        }
      }

      .mat-mdc-form-field-outline-end {
        border-radius: 20px !important;
        border: 1px solid #cccccc !important;
      }

      .mat-mdc-form-field-flex {
        margin: 0;
        //padding: 0 !important;
        height: 40px;
        display: flex;
        align-items: center;
      }

      &.height-50 {
        height: 50px;
        .mat-mdc-form-field-flex {
          height: 50px;
        }
      }

      &.height-30 {
        height: 30px;
        .mat-form-field-flex {
          height: 30px;
        }
      }

      .mat-mdc-form-field-outline-start {
        display: none;
      }

      .mat-mdc-form-field-outline {
        top: 0 !important;
      }

      .mat-mdc-form-field-wrapper {
        margin: 0 !important;
        padding: 0;
        position: relative;
        top: 2px;
        .mat-mdc-form-field-underline {
          display: none;
        }
      }

      .mat-mdc-form-field-infix {
        --roam-mdc-form-field-infix-width: 137px;

        border-top: none;
        width: var(--roam-mdc-form-field-infix-width);

        .mat-mdc-input-element {
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;

          &::placeholder {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            color: #949494;
          }

          &.mat-mdc-datepicker-input {
            padding-left: 20px;
          }
        }

        .mat-mdc-form-field-label-wrapper {
          display: none;
        }

        .mat-mdc-select {
          .mat-mdc-select-value {

            .mat-mdc-select-placeholder,
            .mat-mdc-select-min-line {
              font-size: 14px;
              line-height: 21px;
              color: inherit;
            }

            .mat-mdc-select-placeholder {
              color: #949494;
              font-weight: 400;
            }
          }

          .mat-mdc-select-arrow-wrapper {
            .mat-mdc-select-arrow {
              display: none;
            }
          }
        }
      }

      .mat-mdc-form-field-prefix {
        top: 0.1em !important;

        .search-icon {
          min-width: 0;
          padding-right: 10px;
        }
      }

      .mat-mdc-form-field-suffix {
        top: 2px !important;

        .mat-mdc-icon-button {
          height: auto !important;
          width: 56px !important;
        }
      }

      &.radius-10 {
        .mat-mdc-form-field-outline-end {
          border-radius: 10px !important;
        }

        .mat-mdc-form-field-infix {
          .mat-mdc-input-element {
            &:not(input[type="date"], input[type="time"]) {
              padding-left: 20px;
            }
          }

          input[type="date"].mat-mdc-input-element,
          &input[type="time"].mat-mdc-input-element {
            max-width: 112px;
            background: rgba(202, 192, 206, 0.3);
            color: #949494;
            border-radius: 4px;
            padding: 6px 8px;
          }
        }
      }

      &.mat-mdc-input-wrapper {
        .mat-mdc-form-field-infix {
          padding: 0 20px;
        }
      }

      &.mat-form-field-autofilled {
        .mat-mdc-form-field-outline-end {
          border-radius: 20px !important;
          border: 1px solid #cccccc !important;
        }

        .mat-mdc-input-element {
          box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0),
          inset 0 0 0 100px rgba(255, 255, 255, 1);
        }
      }
    }

    &.btn-dropdown {

      .mat-mdc-form-field-flex {
        height: 45px;
      }

      .mat-mdc-form-field-icon-suffix {
        padding-right: 10px;
      }

      .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
      .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch,
      .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
        border-color: #D6D3D7!important;
      }

      .mat-mdc-select-value-text {
        font-size: 14px;
        font-weight: 500;
      }

      .mdc-text-field--outlined .mdc-notched-outline {
        .mdc-notched-outline__leading {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        .mdc-notched-outline__trailing {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }

      .mat-mdc-form-field-infix:has(mat-select[aria-expanded="true"]) ~ .mat-mdc-form-field-icon-suffix .mat-icon{
          transition: all 0.5s;
          transform: rotate(180deg);
      }

      .mat-mdc-form-field-infix:has(mat-select[aria-expanded="false"]) ~ .mat-mdc-form-field-icon-suffix .mat-icon{
        transition: all 0.5s;
        transform: rotate(0deg);
      }

    }

    /* Change border color hover form*/
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color:  red;
      }
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline {
      .mdc-notched-outline__notch {
        border-left-color: transparent!important;
        border-bottom-color: #949494
      }
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline {
      .mdc-notched-outline__notch {
        border-left-width: 0 !important;
        border-left-color: transparent!important;
      }
    }

    .mdc-notched-outline__notch {
      border-left-width: 0!important;
    }

    &.label-field {
      position: relative;
      height: 40px;
      .mat-mdc-form-field-wrapper {
        margin-top: 0;
        padding-bottom: 2px;
        .mat-mdc-form-field-flex {
          height: 40px;
          .mat-mdc-form-field-outline {
            color: #cccccc;
            height: 40px;
            .mat-mdc-form-field-outline-start {
              width: 17px !important;
              border-radius: 10px 0 0 10px;
            }

            .mat-mdc-form-field-outline-end {
              border-radius: 0 10px 10px 0;
            }
          }
        }
      }

      .mat-mdc-form-field-infix {
        padding-top: 8px;
        height: 40px;

        .mat-mdc-input-element {
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          color: #000000;

          &::placeholder {
            font-size: 14px;
            line-height: 21px;
            font-weight: 300;
          }
        }

        .mat-mdc-form-field-label-wrapper {
          display: block;
          top: -22px;
          height: 40px;
          .mat-mdc-form-field-label {
            top: 27px;
          }
        }
      }

      &.mat-mdc-form-field-should-float {
        .mat-mdc-form-field-infix {
          .mat-mdc-form-field-label-wrapper {
            top: -17px;
            left: 11px;
          }
        }
      }

      .mat-mdc-form-field-label {
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
        color: #949494;
      }

      &.radius-20 {
        .mat-mdc-form-field-wrapper {
          .mat-mdc-form-field-flex {
            .mat-mdc-form-field-outline {
              .mat-mdc-form-field-outline-start {
                border-radius: 20px 0 0 20px;
              }

              .mat-mdc-form-field-outline-end {
                border-radius: 0 20px 20px 0;
              }
            }
          }
        }
      }
    }

    &.white-bg {
      .mat-mdc-form-field-wrapper {
        border-radius: 12px;
        background-color: #ffffff;
        color: #949494;
      }
    }

    &.grey-bg {
      .mat-mdc-form-field-wrapper {
        .mat-mdc-form-field-outline-start,
        .mat-mdc-form-field-outline-gap,
        .mat-mdc-form-field-outline-end {
          background: #efecf0;
          border: none;
        }
      }
    }
  }

  .contact-sidenav-menu-field {
    .mat-mdc-form-field-outline-end {
      border: none !important;
    }

    .mat-mdc-form-field-suffix {
      top: -9px !important;
      transform: rotate(-90deg);
    }
  }

  .tasks-searchbox {
    .mat-mdc-form-field-outline-end {
      border: 0.5px solid #ffffff !important;
    }
  }

  .filter-menu-search {
    width: 212px;
    height: 34px;
    margin-top: 7px;
    .mat-mdc-form-field-outline-end {
      border-radius: 9px !important;
    }

    .mat-mdc-input-element {
      width: 185px;
      padding-left: 11px;
      color: #172b4d;
    }
  }

  .required-field-red {
    color: rgba(226, 39, 39, 1);
  }

  .mat-mdc-card {
    padding: 1rem;
  }

  .input-transparent {

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }
  }
  .form-set {
    app-roam-input,
    app-roam-select {
      mat-form-field {
        margin-bottom: 24px;
      }
    }
  }

  .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    top: 23px!important;
  }

  // Invalid Form CSS Configuration
  .invalid.ng-touched {
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
      border-color: #D40101!important;
    }

    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch .mat-mdc-floating-label {
      color: #D40101!important;
    }
  }

  .mat-error {
    display: none;
    font-size: 12px;
    margin-top: -2px;
  }

  .invalid.ng-touched + .mat-error {
    display: inline-flex!important;
  }

  .disabled .mat-mdc-text-field-wrapper{
    pointer-events: none;
    background-color: #EAEAEA;

    .mat-mdc-form-field-icon-suffix {
      pointer-events: none; 
    }

    .mat-mdc-select-value,
    .mat-mdc-input-element {
      color: #949494!important;
    }
  }

  .mat-mdc-form-field-infix:has(.mat-mdc-select[aria-expanded="true"]) ~ .mat-mdc-form-field-icon-suffix .arrow-down  {
    transform: rotate(180deg);
  }

  .mat-mdc-form-field-infix:has(.mat-mdc-select[aria-expanded="false"]) ~ .mat-mdc-form-field-icon-suffix .arrow-down  {
    transform: rotate(0deg);
  }

  .panel-dropdown {
    &.mat-mdc-select-panel {
      padding: 0 !important;
    }

    .mat-mdc-option {
      min-height: 45px !important;
    }

  }


}







