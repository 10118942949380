.lds-ring {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 2rem;
  height: 2rem;
  border: 5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.4s cubic-bezier(.5,0,.5,1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.dark .lds-ring div {
  border-color: #91466A transparent transparent transparent;
}

.lds-ring.md  div{
  width: 4rem;
  height: 4rem;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
