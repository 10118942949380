@import "../mixins/button.mixin";

$button-types: (
  "primary": $color-theme-primary,
  "black": $color-accent-black,
  "base": $color-grey-base,
  "glight": $color-accent-light,
  "grey": $color-border-grey,
);

.btn {
  @include button();
  cursor: pointer;
  margin-right: 1rem;

  label {
    cursor: pointer;
  }

  a,
  a:focus {
    color: inherit;
  }

  &:focus-visible {
    outline: none;
  }

  .icon {
    margin-right: 6px;
  }

  &.group {
    display: flex;
    align-items: center;
  }

  &:only-child {
    margin-right: 0 !important;
  }

  &:disabled {
    background-color: #cccccc;
    pointer-events: none;
    border-color: #cccccc;
    color: #ffffff !important;
    pointer-events: none !important;
  }

  &.flat {
    height: 40px;
    border-radius: 8px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .std {
    border-radius: 6px !important;
    min-height: 36px !important;
    max-height: 36px !important;
  }

  &.bold {
    span {
      font-weight: 600 !important;
    }
  }

  &.md {
    height: 45px;
  }

  &.disable-light {
    background-color: #ffffff;

    > * {
      color: #d6d3d7 !important;
    }
  }
}

[class^="btn-"] {
  &:disabled {
    background: #bebebe;
    color: #ffffff !important;
    border-color: initial;
    font-weight: 500;
  }
}

// Generate Button
@each $name, $bg, $bgHover, $color in $button-types {
  .btn-#{$name} {
    @include button-bg($bg, $bgHover, $color);
  }
}

//// Outline
@each $name, $bg in $button-types {
  .btn-o-#{$name} {
    cursor: pointer;
    background-color: $color-theme-white;
    color: $bg;
    border: 1px solid $bg;
  }
}

.btn-outline {
  height: 40px !important;

  .mdc-button__label {
    line-height: 10px !important;
  }
}

.btn-swiper-nav {
  border: none;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - 1rem);
  z-index: 1;
  cursor: pointer;

  span {
    margin-top: 2px;
  }

  &.prev {
    left: 0.5rem;
    transform: rotate(180deg);
  }

  &.next {
    right: 0rem;
  }

  &.deg-180 {
    transform: rotate(180deg);
  }

  &.gutter-swiper-nav {
    &.prev {
      left: -10px;
    }

    &.next {
      right: -10px;
    }
  }
}

.btn-r-std {
  border-radius: 6px !important;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}

.btn-o-round {
  border-radius: 29px;
  border: 1px solid rgba(143, 134, 147, 0.3);
  background: #f9f9f9;
  padding: 10px 20px;
  line-height: 16px;
  font-size: 14px;
}

.btn-send {
  width: 32px;
  height: 32px;
  background: #aaaaaa;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 10px;
  pointer-events: none;
  cursor: none;

  &.active {
    background-color: #876486;
    pointer-events: auto;
    cursor: pointer;
  }

  .svg {
    width: 1rem;
    height: 1rem;
  }
}

.btn.bdr {
  border-radius: 12px !important;
  gap: 8px;
}

.btn.underline {
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  color: #222;
  text-decoration: underline;
}

.btn-reset {
  height: 39px;
  border: 0;
  border-radius: 999px;
  padding: 9px 12px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.btn-base {
  border: none;
  background: none;
  padding: 0;
  color: $color-theme-primary;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: unset;
  }

  &.text {
    margin: 0;
    padding: 0;
  }

  &:disabled {
    color: $color-grey-text !important;
    background: transparent;
    pointer-events: none;

    .svg-{
      fill: #949494;
    }
  }
}

// ::ng-deep .btn-base {
//   &.arrow:disabled {
//     .svg- {
//       fill: #949494!important
//     }
//   }
// }

@mixin button-base {
  height: 39px !important;

  background: #fff !important;
  border-radius: 12px !important;

  color: #222 !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  &.mat-primary {
    background: #91466a !important;
    color: #fff !important;
    border: none;
  }
}

.button-flat-base,
.button-stroked-base {
  @include button-base();
}

.button-flat-rounded,
.button-stroked-rounded {
  @include button-base();

  border-radius: 100px !important;
}

.button-action {
  position: absolute;
  top: 20px;
  right: 32px;
  z-index: 5;
}

.btn-send-act {
  border-radius: 6px;
  border: none;
  padding: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
