@use "@angular/material" as mat;

@import "./mixins/mat-slide-toggle-custom-theme.scss";
@import "./mixins/mat-select-custom-theme.scss";
@import "./mixins/mat-menu-custom-theme.scss";
@import "./mixins/mat-form-field-custom-theme.scss";
@import "./mixins/mat-button-custom-theme.scss";
@import "./mixins/mat-table-custom-theme.scss";
@import "./mixins/mat-dialog-custom-theme.scss";
@import "./mixins/mat-checkbox-custom-theme.scss";
@import "./mixins/mat-calendar-custom-theme.scss";
@import "./mixins/mat-tooltip-custom-theme.scss";
@import "./mixins/mat-expansion-panel-custom-theme.scss";
@import "./mixins/mat-progress-bar-custom-theme.scss";
@import "./mixins/mat-radio-custom-theme.scss";
@import "./mixins/mat-sidenav-custom-theme.scss";
@import "./mixins/mat-tab-custom-theme";
@import "./mixins/calendar-color-config.mixin";
@import "./mixins/mat-card-custom-theme.mixin";

@include mat.core();
@include mat.legacy-core();

$custom-typography: mat.define-typography-config(
  $font-family: "Poppins",
);

$mat-primary-scheme: (
  100: #ffffff,
  500: #000000,
  600: #303030,
  700: #1362b3,
  800: #91466a,
  contrast: (
    100: rgba(0, 0, 0, 0.87),
    500: #ffffff,
    700: #ffffff,
  ),
);

$mat-accent-scheme: (
  100: #6ff8b1,
  500: #32c481,
  700: #009254,
  contrast: (
    100: rgba(0, 0, 0, 0.87),
    500: #fff,
    700: #ffffff,
  ),
);

$mat-warn-scheme: (
  100: #ffcdd2,
  500: #ff0000,
  700: #d32f2f,
  contrast: (
    100: rgba(0, 0, 0, 0.87),
    500: #fff,
    700: #ffffff,
  ),
);

$mat-primary-palette: mat.define-palette($mat-primary-scheme);
$mat-accent-palette: mat.define-palette($mat-accent-scheme);
$mat-warn-palette: mat.define-palette($mat-warn-scheme);

$app-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $mat-primary-palette,
      accent: $mat-accent-palette,
      warn: $mat-warn-palette,
    ),
    typography: mat.define-typography-config(
      $font-family: "Poppins",
    ),
    density: -1,
  )
);

$app-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $mat-primary-palette,
      accent: $mat-accent-palette,
      warn: $mat-warn-palette,
    ),
  )
);

@include mat.all-component-typographies($custom-typography);
@include mat.all-legacy-component-themes($app-light-theme);
@include mat.all-component-themes($app-light-theme);
// @include mat.typography-hierarchy($custom-typography);

@include mat-slide-toggle-custom-theme();
@include mat-select-custom-theme();
@include mat-menu-custom-theme();
@include mat-form-field-custom-theme();
@include mat-button-custom-theme();
@include mat-table-custom-theme();
@include mat-dialog-custom-theme();
@include mat-checkbox-custom-theme();
@include mat-calendar-custom-theme();
@include mat-tooltip-custom-theme();
@include mat-expansion-panel-custom-theme();
@include mat-progress-bar-custom-theme($app-light-theme);
@include mat-radio-custom-theme();
@include mat-sidenav-custom-theme();
@include mat-tab-custom-theme();
@include mat-card-custom-theme();

.mat-drawer-container {
  background: mat.get-color-from-palette($mat-primary-palette, 100);
}

.primary {
  color: mat.get-color-from-palette($mat-primary-palette, 800);
}

.primary-black {
  background-color: mat.get-color-from-palette($mat-primary-palette, 500);
  color: mat.get-contrast-color-from-palette($mat-primary-palette, 500);
}

.primary-white {
  background: mat.get-color-from-palette($mat-primary-palette, 100);
}

.darkMode {
  @include mat.all-component-colors($app-dark-theme);

  .primary-white {
    background: mat.get-color-from-palette($mat-primary-palette, 600);
  }

  .primary {
    color: mat.get-color-from-palette($mat-primary-palette, 800);
  }

  .primary-black {
    background-color: mat.get-contrast-color-from-palette(
      $mat-primary-palette,
      500
    );
    color: mat.get-color-from-palette($mat-primary-palette, 500);
  }
}
