
.roam-scrollbar {
  &:hover {
    overflow-y: auto;
  }
  
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #876486;
    border-radius: 10px;
  }

  &.scr-transparent {
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color:transparent;
    }
  }
  
}

.scroll-container {
  max-height: 316px;
  overflow: hidden;
  overflow-y: scroll;

  @extend .roam-scrollbar;
}

.customScrollBar{
  -ms-overflow-style: -ms-autohiding-scrollbar
}

@supports not (selector(::-webkit-scrollbar)) {
  .customScrollBar {
    scrollbar-color: #876486 transparent;
    scrollbar-width: 2px;
  }
}

.customScrollBar::-webkit-scrollbar {
  width: 3px; /* Width of the vertical scrollbar */
  height: 16px; /* Height of the horizontal scrollbar */
}

.customScrollBar::-webkit-scrollbar:disabled {
  display: none;
}

.customScrollBar::-webkit-scrollbar-button,
.customScrollBar::-webkit-scrollbar-corner {
  background: transparent;
  cursor: pointer;
}

.customScrollBar::-webkit-scrollbar-button:vertical:decrement,
.customScrollBar::-webkit-scrollbar-button:vertical:increment{
  background-repeat: no-repeat;
  background-color: #F5F5F5;
  height: 8px;
  width: 2px;
}
